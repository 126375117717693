import React, { Component } from 'react';
import Widget from '../common/Widget';
import { Link, List, ListItem, Typography, Box, Button, Stack, Tooltip, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import PortalTooltip from '../common/PortalTooltip';

class BalanceBox extends Component {
    render() {
        return <span id={this.props.id + "_div_balanceBox_reducer"}>
            <Box id={this.props.id + "_box_balanceCard"} sx={{ height: '100%' }} textAlign={"center"} className="balanceBox" id={this.props.id}>
                <Stack id={this.props.id + "_stack_balanceCard_reducer"} sx={{ p: 1, height: '100%' }} direction={"column"} alignItems="stretch" justifyContent={"center"}>
                    <Stack id={this.props.id + "_stack_balance_reducer"} direction={"row"} alignItems="flex-start" justifyContent="center">
                        <Box id={this.props.id + "_boxImage"} sx={{ height: '40px', width: '40px', mr: 0 }}>
                            <img id={this.props.id + "_Image"} src={this.props.icon} alt={this.props.alt} />
                        </Box>

                        <Stack id={this.props.id +"_stack_balance_wrapper"} direction={"column"} alignItems={"top"} spacing={0} sx={{ width: '100%' }}>
                            <div id={this.props.id +"_div_balance_wrapper"} className="ms-2 text-start">
                                <Typography id={this.props.id + "_text_balanceMainTitle"} component="h3" variant="h6">{this.props.title}</Typography>
                                {this.props.balance == "N/A" ?
                                    <Typography id={this.props.id + "_text_noAccountFound"} component="p" variant="smaller">
                                        No account found.
                                    </Typography>
                                    :
                                    <div id={this.props.id + "_div_balanceInfo_wrapper"}>
                                        <Typography id={this.props.id + "_text_balanceTitle"} component="h4" variant="smaller" className="text-start">Balance</Typography>
                                        <Stack id={this.props.id + "_stack_balanceInfo_wrapper"} direction="row" alignItems="left" justifyContent={"left"}>
                                            <Typography id={this.props.id + "_text_balanceDisplay"} component="p" variant="h5">${this.props.isDisplayBalance ? this.props.balance : ' ---'}</Typography>
                                            <IconButton id={this.props.id + "_iconButton_toggleBalanceIcon"} aria-label="hide balance" onClick={this.props.toggleIsDisplayBalance}>
                                               <FontAwesomeIcon id={`${this.props.id}_toggleBalanceIcon_${this.props.isDisplayBalance ? 'eyeSlash' : 'eye'}`} icon={this.props.isDisplayBalance ? faEyeSlash : faEye} fixedWidth size="xs"/>

                                            </IconButton>
                                        </Stack>
                                    </div>
                                }
                            </div>
                        </Stack>
                    </Stack>
                    <Stack id={this.props.id + "_stack"} sx={{ height: '100%' }} justifyContent="flex-end" alignItems='center' className="align-bottom" >
                        <PortalTooltip id={this.props.id + "_tooltip"}>
                            <Button id={this.props.id + "_button"} target="_blank" className="myOdu__button secondary myBalancesButton" href={this.props.depositLink} variant="outlined" sx={{ mt: 1, width: '100%' }}>{this.props.buttonLabel}</Button>
                        </PortalTooltip>
                    </Stack>
                </Stack>
                {/* <Box sx={{display:'flex', alignItems: 'stretch', border: '1px solid green'}} /> */}

            </Box>
        </span>
    }
}

export default BalanceBox;