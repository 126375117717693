import React, {Component} from 'react';
import {Box, Typography, Paper, Stack} from '@mui/material';
import FeedbackTable from './FeedbackTable';
import getColumns from './columns';
import {feedback as feedbackService} from '../../../DataAccessLayer/services';
import {getData} from '../../../DataAccessLayer';
import FeedbackDetails from './FeedbackDetails';
import {GridToolbar} from '@mui/x-data-grid';
import {BreakpointBoxes } from '../../common/BreakpointBoxes';
import {WithSmallScreen} from '../../common/WithSmallScreen';


class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedbackData: [],
            feedbackMap: {},
            isLoading: false,
            selectedFeedback: null
        };
    }

    componentDidMount() {
        this.loadAllFeedback();
    }

    loadAllFeedback = async () => {
        this.setState({isLoading: true});
        try {
            const data = await getData(feedbackService.feedback);

            // Create a mapping of feedback data for quick lookup
            const feedbackMap = data.reduce((acc, item) => {
                acc[`${item.MIDAS}-${item.TIMESTAMP}`] = item;
                return acc;
            }, {});

            this.setState({feedbackData: data, feedbackMap});
        } catch (err) {
            console.error('Failed to load feedbacks:', err);
        } finally {
            this.setState({isLoading: false});
        }
    };

    getSpecificFeedBack = async ({row}) => {
        const {MIDAS: midas, TIMESTAMP: timestamp} = row;

        const key = `${midas}-${timestamp}`;
        const data = this.state.feedbackMap[key];

        if (data) {
            this.setState({
                selectedFeedback: {
                    midas: data.MIDAS,
                    timestamp: data.TIMESTAMP,
                    bugDetail: data.BUG_DETAIL,
                    changeDetail: data.CHANGE_DETAIL,
                    ideaDetail: data.IDEA_DETAIL,
                    metadata: data.METADATA,
                    otherDetail: data.OTHER_DETAIL,
                    pageUrl: data.PAGE_URL,
                    rating: data.RATING
                }
            });
        }
    };

    render() {
        const {feedbackData, isLoading, selectedFeedback} = this.state;

        return (
                <>
                <Typography component="h2" variant="h4" gutterBottom>
                    Feedback
                </Typography>

                <Stack direction={{lg: "row", xs: "column"}} spacing={2}>
                    <FeedbackTable
                        className="myOdu__table feedbackTable"
                        rows={feedbackData}
                        columns={getColumns()}
                        isLoading={isLoading}
                        onClick={this.getSpecificFeedBack}
                        slots={{toolbar: GridToolbar}}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                csvOptions: {disableToolbarButton: true},
                                printOptions: {disableToolbarButton: true},
                                quickFilterProps: {
                                    sx: {m: 1, width: '100%'},
                                    variant: 'outlined',
                                    size: 'small',
                                    placeholder: 'Search Feedback'
                                }
                            }
                        }}
                        columnVisibilityModel={{
                            PAGE_URL: false,
                            BUG_DETAIL:false,
                            IDEA_DETAIL: false,
                            CHANGE_DETAIL: false,
                            OTHER_DETAIL: false,
                            METADATA: false
                        }}
                        disableColumnFilter
                        disableColumnSelector
                        disableDensitySelector
                    />
                    
                    <Box className="myOdu__box bordered"
                        sx={{
                            width: '100%',
                            overflow: 'hidden',
                            height: '100%',
                            mx:0

                        }}
                    >
                        <FeedbackDetails data={selectedFeedback} />
                    </Box>
            </Stack>
            </>
        );
    }
}

export default WithSmallScreen(Feedback);
