import {Box, Link, Typography, Stack} from '@mui/material';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PortalTooltip from './PortalTooltip';
import {AppConfigs} from '../SetUp/config';
import {AlphabetBar} from '../Landing/AlphabetBar';

class ApplicationsList extends Component {
    state = {
        selectedLetter: ''
    };

    handleSelectLetter = letter => {
        this.setState({selectedLetter: letter});
    };

    filterApps = text => {
        return AppConfigs.Apps.filter(app =>
            app.name.toLowerCase().includes(text.toLowerCase())
        ).reduce((groups, app) => {
            const key = app.name[0].toUpperCase();
            groups[key] = groups[key] || [];
            groups[key].push(app);
            return groups;
        }, {});
    };

    fancyTooltip = app => {
        return (
            //TODO: deal with mobile app links, icons later.

            <PortalTooltip
                title={
                    <Stack
                        direction="row"
                        alignItems={'flex-start'}
                        justifyContent={'center'}
                    >
                        {app.icon && (
                            <Box
                                className="text-center"
                                sx={{
                                    border: '1px solid black',
                                    backgroundColor: 'white',
                                    width: '3rem',
                                    minWidth: '3rem',
                                    maxWidth: '3rem',
                                    padding: '.25rem',
                                    mr: 1
                                }}
                            >
                                <img
                                    src={'/img/logos/' + app.icon}
                                    alt={app.name}
                                />
                            </Box>
                        )}
                        <Box>
                            <Typography
                                variant="h6"
                                component="h4"
                                sx={{mt: 0, pt: 0}}
                            >
                                {app?.name}
                            </Typography>
                            <Typography component="p" variant="smaller">
                                {app?.desc}
                            </Typography>
                            <Typography
                                component="p"
                                variant="smaller"
                                sx={{mt: 2}}
                            >
                                {' '}
                                Link opens in new window.
                            </Typography>
                        </Box>
                    </Stack>
                }
            >
                {app?.link ? (
                    <Link
                        href={app?.link}
                        variant="small"
                        target="_blank"
                        underline={'always'}
                    >
                        {app.name}
                    </Link>
                ) : (
                    <Typography variant="small">app.name</Typography>
                )}
            </PortalTooltip>
        );
    };

    render() {
        const {text} = this.props; // Assuming text is passed as a prop for filtering
        const filtered = this.filterApps(text);
        const {selectedLetter} = this.state;
        const availableLetters = Object.keys(filtered);

        return (
            <Box sx={{pb: 5}}>
                {this.props.isAlphabetBarVisible && (
                    <Box sx={{overflowY: 'auto', mb: 4}}>
                        <AlphabetBar
                            onSelectLetter={this.handleSelectLetter}
                            availableLetters={availableLetters}
                        />
                    </Box>
                )}
                <Box className="myOdu__applicationList">
                    {Object.entries(
                        selectedLetter
                            ? {[selectedLetter]: filtered[selectedLetter] || []}
                            : filtered
                    )
                        .sort()
                        .map(([group, apps]) => {
                            return (
                                <React.Fragment key={group}>
                                    <div className="groupBlock">
                                        <a name={group} />
                                        <Typography
                                            component="h3"
                                            variant="h6"
                                            sx={{mt: 3}}
                                            className="myOdu__applicationListHeader"
                                        >
                                            {group}
                                        </Typography>
                                        {/* TODO://update and clean up the mobile display part  */}
                                        {apps.length > 0 ? (
                                            apps.map(app => (
                                                <div key={app.name}>
                                                    {' '}
                                                    {this.fancyTooltip(
                                                        app
                                                    )}{' '}
                                                </div>
                                            ))
                                        ) : (
                                            <Typography>
                                                No applications to display
                                            </Typography>
                                        )}
                                    </div>
                                </React.Fragment>
                            );
                        })}
                </Box>
            </Box>
        );
    }
}

const mapStateToProps = state => {
    return {
        text: state.applicationsReducer.text
    };
};

const mapDispatchToProps = dispatch => ({
    // saveApplicationsFilter: (text) => dispatch(saveApplicationsFilter(text))
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsList);
