import { Card, Typography } from "@mui/material";
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import Section from "./Section";

class DragDrop extends Component {
    state = {
        Widgets: [
            ['widget__notification', 'widget__myApps', 'widget__myCourses', 'widget__myNews', 'widget__announcements', 'widget__courseTutoring'],
            ['widget__mySchedule', 'widget__myRegistration', 'widget__myBalances', 'widget__myPrograms', 'widget__myAdvisingTeam', 'widget__universityLibraries', 'widget__placesAndHours'],
            ['widget__systemStatus', 'widget__safety', 'widget__myBookmarks', 'widget__myContacts', 'widget__myDining', 'widget__itsHelp', 'widget__resources']
        ],
        selectedSection: 0,
        selectedItem: '',
        isDragging: false
    }



    dragEnd = (result) => {
        // console.log('Drag End',result);
        if(result?.destination?.droppableId) {
            let Widgets = this.state.Widgets
            let sectionId = result.destination.droppableId;

            const [removedWidgetId] = this.state.Widgets[sectionId-1].splice(result.source.index, 1)
            this.state.Widgets[sectionId - 1].splice(result.destination.index, 0, removedWidgetId)
            
            this.setState({
                Widgets,
                selectedSection: 0,
                isDragging: false
            })
        }
    }

    dragStart = (result) => {
        // console.log('Drag Starrt',result);
        this.setState({
            selectedSection: result.source.droppableId,
            isDragging: true
        })
    }


    beforeCapture = (result) => {
        // console.log('Drag Before',result);
        this.setState({
            selectedItem: result.draggableId
        })
    }

    render() {
        return <React.Fragment>
            <Card sx={{ p:4, height: '100%'}} className={'myOdu__appCard'} elevation={0}>
                <Typography component="h2" variant="h4" className="">Drag and Drop</Typography>
                <div className="myOdu__dashboard_maxWidth">
                    <Row className="pt-3">
                        <Col xl={5} lg={12} md={12} className="px-0 ">
                            <Section 
                                Widgets = {this.state.Widgets[0]} 
                                sectionNumber={1} 
                                selectedSection={this.state.selectedSection} 
                                selectedItem = {this.state.selectedItem} 
                                dragEnd={this.dragEnd} 
                                dragStart={this.dragStart} 
                                beforeCapture={this.beforeCapture}
                                isDragging = {this.state.isDragging}    
                            />
                        </Col>
                        <Col xl={4} lg={8} md={6} className="px-0 pt-3 px-xl-3 pt-xl-0 pe-lg-3 pe-md-3" >
                            <Section 
                                Widgets = {this.state.Widgets[1]} 
                                sectionNumber={2} 
                                selectedSection={this.state.selectedSection} 
                                selectedItem = {this.state.selectedItem} 
                                dragEnd={this.dragEnd} 
                                dragStart={this.dragStart} 
                                beforeCapture={this.beforeCapture}
                                isDragging = {this.state.isDragging}    
                            />
                        </Col>
                        <Col xl={3} lg={4} md={6} className="px-0 pt-3 pt-xl-0">
                            <Section 
                                Widgets = {this.state.Widgets[2]} 
                                sectionNumber={3} 
                                selectedSection={this.state.selectedSection} 
                                selectedItem = {this.state.selectedItem} 
                                dragEnd={this.dragEnd} 
                                dragStart={this.dragStart} 
                                beforeCapture={this.beforeCapture}
                                isDragging = {this.state.isDragging}    
                            />
                        </Col>
                    </Row>
                </div> 
            </Card>
        </React.Fragment>
    }
}

export default DragDrop;