import React, { Component } from 'react';
import { Box, Stack, Typography, IconButton, Portal, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import StartMeeting from './StartMeeting';
import PortalTime from '../../../common/PortalTime';

class Header extends Component {
    state = {  } 
    render() { 
        return <React.Fragment>
           <Stack id="cct__cctHeader" className="cctHeader" direction={{xs: "column", sm: "row", md: "row"}} alignItems={"center"}>
            
            <Stack id="cct__titleCrnsWrapper" className="titleScheduleCrnsWrapper" direction={{xs:"column", sm: "column", md:"row"}}>
                { this.props.subjectCode && this.props.courseNumber && 
                    <Typography id="cct__subjNumber" component="h2" variant="h2">
                        {this.props.subjectCode + ' ' + this.props.courseNumber}
                    </Typography>
                }            
                    <Stack id="cct__titleScheduleCrns" className="titleScheduleCrns">
                        {this.props.courseTitle && 
                            <Typography id="cct__title" component="h3" variant="h3">{this.props.courseTitle}</Typography>
                        }
                        
                        {this.props.schedule?.weekdays && 
                            <Stack direction={'row'} alignItems={'flex-end'}>
                                <Typography id="cct__schedule" variant="smaller" paddingRight={1}>
                                    {this.props.schedule.weekdays + ' '}
                                </Typography>
                                <PortalTime time={this.props.schedule.startTime} className="schedule" title={this.props.schedule.startTime + ' Eastern Daylight Time. Events are always shown in the timezone for ODU.'} />
                                <Typography id="cct__schedule" variant="smaller">
                                    &nbsp;  {' - '} &nbsp;
                                </Typography>
                                <PortalTime time={this.props.schedule.endTime} variant="smaller" title={this.props.schedule.endTime + ' Eastern Daylight Time. Events are always shown in the timezone for ODU.'} />
                            </Stack>
                        }
                        
                        {/* //TODO: send crns as array so can get count so can have label CRN single or CRNs plural  */}
                        {this.props.crns && 
                            <Typography id="cct__crns" className="crns">CRNs - {this.props.crns}</Typography>
                        }
                    </Stack>
                </Stack>
                <Box sx={{flexGrow: 1}} />
                
                {this.props.meetingsToStart.length > 0 &&
                     <StartMeeting 
                        meetingsToStart ={this.props.meetingsToStart}
                        anchor = {this.props.anchor}
                        dropAnchor = {this.props.dropAnchor}
                        pullAnchor = {this.props.pullAnchor}
                        isOpen = {this.props.isOpen}
                        getMeetingStartTitle = {this.props.getMeetingStartTitle}
                        isTaking = {this.props.isTaking}
                        isTeaching = {this.props.isTeaching}
                    />
                }
                <IconButton
                    aria-label="close"
                    onClick={() => this.props.onClose({})}
                    className="myOdu__closeX myOdu__iconButton"
                    id={"cct__button_close"} >
                    <FontAwesomeIcon icon={faXmark} fixedWidth size="xs" />
                </IconButton>
            </Stack>
        </React.Fragment>
    }
}
 
export default Header;