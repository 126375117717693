import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import {store, persistor} from './store';
import {PersistGate} from 'redux-persist/integration/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import interceptUrlParams from './interceptUrlParams';

const root = ReactDOM.createRoot(document.getElementById('root'));
const error = interceptUrlParams();

root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <App error={error} />
        </PersistGate>
    </Provider>
);
