import {Time} from '../../common/MomentTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentCaptions } from '@fortawesome/pro-light-svg-icons';
import PortalTooltip from '../../common/PortalTooltip';

const getColumns = actionFunction => {
    return [
        {
            field: 'Comments?',
            headerName: '',
            width: 25,
            minWidth: 25,
            renderCell: params => {
                    return (
                        ((params.row.BUG_DETAIL || params.row.IDEA_DETAIL || params.row.CHANGE_DETAIL || params.row.OTHER_DETAIL) && 
                        <PortalTooltip title="This item has comments">
                            <FontAwesomeIcon icon={faCommentCaptions} />
                        </PortalTooltip>
                        )
                    );
            }
        },
        {field: 'MIDAS', headerName: 'MIDAS', width: 80},
        {
            field: 'TIMESTAMP',
            headerName: 'Time',
            width: 200,
            valueGetter: params =>
                Time.University(params.row.TIMESTAMP).format(
                    'M/DD/YYYY h:mm:ss A'
                )
        },
        
        {field: 'PAGE_URL', headerName: 'Page Url', flex: 1},
        {field: 'BUG_DETAIL', headerName: 'Bug Detail', flex: 1},
        {field: 'IDEA_DETAIL', headerName: 'Idea Detail', flex: 1},
        {field: 'CHANGE_DETAIL', headerName: 'Change Detail', flex: 1},
        {field: 'OTHER_DETAIL', headerName: 'Other Detail', flex: 1},
        {
            field: 'METADATA',
            headerName: 'Metadata',
            flex: 1,
            valueGetter: params => JSON.stringify(params.row.METADATA)
        },
        {field: 'RATING', headerName: 'Rating', width: 60},
    ];
};

export default getColumns;
