
import React, { Component } from 'react';
import { Menu, MenuItem, IconButton} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-light-svg-icons';

class WidgetMenu extends Component {
  state = {
    anchor: null,
    isOpen: false
  }

  dropAnchor = (e) => {
    this.setState({anchor: e.currentTarget, isOpen: true})
  }

  pullAnchor = () => {
    this.setState({anchor: null, isOpen: false})
  }

  render() { 
    return <React.Fragment>
        <IconButton className="myOdu__widgetMenu_button myOdu__iconButton" id={this.props.data.id + '__button_widgetMenu'} aria-label={"Expand " + this.props.data.title + " menu"} color="inherit" onClick={this.dropAnchor}>
            <FontAwesomeIcon id={this.props.data.id + '__icon_menu'} icon={faEllipsisVertical} size="sm"/>
        </IconButton>
        <Menu anchorEl={this.state.anchor} id={this.props.data.id + "__widgetMenu"} disableScrollLock={true} open={this.state.isOpen} onClose={this.pullAnchor} onClick={this.pullAnchor} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} >
           {this.props.menu.map((menuItem)=> <MenuItem onClick={menuItem.onClick} key={menuItem.id} id={this.props.data.id + "__menuItem_" + menuItem.id}>{menuItem.title}</MenuItem>)}
        </Menu>
    </React.Fragment>
  }
}
 
export default WidgetMenu;
