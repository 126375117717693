import React, {Component} from 'react';

import {savePreferences, saveSetup} from './actions';
import {connect} from 'react-redux';
import {Container, Col, Row} from 'reactstrap';
import {
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    Typography,
    Button,
    Box,
    Stack
} from '@mui/material';
import Questions from './Questions';
import AppGrid from './AppGrid';
import {AppConfigs, DefaultWidgetOrder, WidgetConfigs} from './config';
import {putData} from '../../DataAccessLayer';
import {myPreferences} from '../../DataAccessLayer/services';
import {CAMPUS, HOUSING} from '../common/constants';
import {
    getUser,
    isUserEvhsc,
    isUserEvmc,
    isUserFaculty,
    isUserHealthProfessionsStudent,
    isUserMedicalStudent,
    isUserStaff,
    isUserStudent
} from '../Layout/utils';
import {WithSmallScreen} from '../common/WithSmallScreen';
import { getWidgetOrderBasedOnRole } from './utils';

class SetUp extends Component {
    state = {};

    componentDidMount() {
        this.loadAppsToDisplay();
    }

    //Θ(N) where N is the length of apps to be displayed
    //Updates the answers of the questions of setup
    onAnswersChanged = questionChanged => {
        let setup = {...this.props.setup};
        setup?.appsToDisplay?.forEach(app => {
            switch (questionChanged) {
                case CAMPUS:
                    if (app?.preSelected?.campus) {
                        if (
                            app?.preSelected?.campus?.some(
                                item => setup.campus && item in setup.campus
                            )
                        ) {
                            setup['Apps'] = {
                                ...(setup?.Apps ?? {}),
                                [app.id]: true
                            };
                        } else if (setup?.Apps && app.id in setup?.Apps) {
                            delete setup.Apps[app.id];
                        }
                    }
                    break;
                case HOUSING:
                    if (app?.preSelected?.isHousing) {
                        if (setup?.isHousing) {
                            setup['Apps'] = {
                                ...(setup?.Apps ?? {}),
                                [app.id]: true
                            };
                        } else if (
                            !setup?.isHousing &&
                            setup?.Apps &&
                            app.id in setup?.Apps
                        ) {
                            delete setup?.Apps[app.id];
                        }
                    }
                    break;
                default:
                    break;
            }
        });
        this.props.saveSetup(setup);
    };

    //Θ(1) Creates an array of application to be displayed based on campus and user role
    loadAppsToDisplay = () => {
        let setup = {...this.props.setup};

        setup['appsToDisplay'] = AppConfigs.Apps.sort((app1, app2) => {
            return app1.name.toLowerCase() < app2.name.toLowerCase() ? -1 : 0;
        });
        setup['Version'] = AppConfigs.Version;
        setup = this.selectRoleBasedInitialApps(setup);

        this.props.saveSetup(setup);
    };

    //Θ(N) where N is length of apps to be displayed
    //Pre-selects the applications based on configs and user role
    selectRoleBasedInitialApps = setup => {
        const user = getUser(this.props.user, this.props.impersonation);
        setup?.appsToDisplay?.forEach(app => {
            if (!(app.id in (setup?.Apps ?? {}))) {
                if (
                    app.isDefaultSelected ||
                    app?.preSelected?.role?.some(
                        item =>
                            item in
                            (user.roles || {})
                    )
                ) {
                    setup['Apps'] = {...(setup?.Apps ?? {}), [app.id]: true};
                    return;
                }
            }
        });
        return setup;
    };

    //Θ(1) Saves prefereneces
    onSave = () => {

        const user = getUser(this.props.user, this.props.impersonation);

        let preferences = {
            Apps: {
                ...this.props.setup
            },
            Widgets: {
                Version: WidgetConfigs.Version,
                WidgetsOrder: getWidgetOrderBasedOnRole(DefaultWidgetOrder, user)
            }
        };

        storePreferences(preferences, this.props);
    };

    //Θ(1) Skips the preferences selection
    onSkipOrCancel = () => {
        //onCancel
        if (this.props.setup.reset) {
            //Reset is a copy of preferences created during reset. On cancellation of reset the preferences are restored via this copy
            this.props.savePreferences({...this.props.setup.reset});
            this.props.saveSetup({});
            return;
        }

        //Skip and Customize later
        let preferences = {
            Apps: {
                Apps: {},
                Version: AppConfigs.Version
            },
            Widgets: {
                Version: WidgetConfigs.Version,
                WidgetsOrder: DefaultWidgetOrder
            }
        };

        storePreferences(preferences, this.props);
    };

    //Θ(1) Saves the preferences
    savePreferences = preferences => {
        delete preferences?.Apps?.appsToDisplay;
        delete preferences?.Apps?.reset;

        const user = getUser(this.props.user, this.props.impersonation);

        putData(myPreferences, {
            preferences,
            midas: user.midas
        })
            .then(_ => {
                this.props.savePreferences(preferences);
                this.props.saveSetup({});
            })
            .catch(err => console.log(err));
    };

    makeRoleSentence = () => {
        const user = getUser(this.props.user, this.props.impersonation)
        const isFaculty = isUserFaculty(user);
        const isStaff = isUserStaff(user);
        const isStudent = isUserStudent(user);
        const isMedicalStudent = isUserMedicalStudent(user);
        const isEvhsc = isUserEvhsc(user);
        const isEvmc = isUserEvmc(user);
        const isHealthProfessionsStudent = isUserHealthProfessionsStudent(user);

        let facultyText =
            isFaculty && isStaff
                ? 'faculty/staff'
                : isFaculty
                ? 'faculty'
                : isStaff
                ? 'staff'
                : undefined;
        facultyText = facultyText
            ? facultyText + (isEvhsc || isEvmc ? ' at EVHSC' : '')
            : undefined;

        const oduStudentText = isStudent ? 'an ODU student' : undefined;
        const medicalStudentText = isMedicalStudent
            ? 'a School of Medicine student'
            : undefined;
        const healthProfessionsStudentText = isHealthProfessionsStudent
            ? 'a School of Health Professions student'
            : undefined;
        const isStudentTextAvailable =
            oduStudentText ||
            medicalStudentText ||
            healthProfessionsStudentText;

        const sentence = (
            <Typography variant="small" id="setup__text_wePickedMessage">
                We've picked some apps for you to start
                {(facultyText || isStudentTextAvailable) && (
                    <>
                        <span id="setup__text_because">{' because you are '}</span>
                        {facultyText && (
                            <span className="role" id="setup__text_faculty">{facultyText}</span>
                        )}
                        {facultyText && isStudentTextAvailable && (
                            <span id="setup__text_and">{' and '}</span>
                        )}
                        {oduStudentText && (
                            <span className="role" id="setup__text_oduStudent">{oduStudentText}</span>
                        )}
                        {medicalStudentText && (
                            <span className="role" id="setup__text_medStudent">
                                {oduStudentText
                                    ? ' and ' + medicalStudentText
                                    : medicalStudentText}
                            </span>
                        )}
                        {healthProfessionsStudentText && (
                            <span className="role" id="setup__text_healthProStudent">
                                {oduStudentText || medicalStudentText
                                    ? ' and ' + healthProfessionsStudentText
                                    : healthProfessionsStudentText}
                            </span>
                        )}
                    </>
                )}
                <span id="setup__text_period">.</span>
            </Typography>
        );
        return sentence;
    };

    render() {

        return (
            <React.Fragment>
                <Dialog open fullWidth fullScreen={this.props.isSmall} maxWidth="xl" className="myOdu__wizard" id="setup__dialog_wizard">
                    <DialogTitle
                        sx={{
                            backgroundColor: '#003057',
                            color: 'white',
                            borderBottom: '3px solid #2188A2'
                        }}
                        id="setup__dialogTitle"
                    >
                        <Typography variant="h4" component="p" id="setup__text_welcome">
                            {'Welcome ' +
                                (this.props.isImpersonating
                                    ? this.props.impersonation.firstName
                                    : this.props.user?.firstName) +
                                '!'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent id="setup__dialogContent">
                        <Row className="pt-2" id="setup__row_qAndAWrapper">
                            <Col md={4} sm={5} className="px-0" id="setup__col_questions">
                                <Box className="myOdu__setupRoles" id="setup__box_questions">
                                    <Typography component="h3" variant="h6"  id="setup__header_customize">
                                        Customize the apps you see on myODU!{' '}
                                    </Typography>
                                    {this.makeRoleSentence()}
                                </Box>
                                <Questions
                                    onAnswersChanged={this.onAnswersChanged}
                                    id="setup__questions"
                                />
                            </Col>
                            <Col md={8} sm={7} className="px-0" id="setup__col_appGrid">
                                <Typography component="h3" variant="h5" sx={{mt: 2}} className="d-block d-sm-none" id="setup__title_pickApplications">
                                    Choose Applications
                                </Typography>
                                <AppGrid id="setup__appGrid"/>
                            </Col>
                        </Row>
                    </DialogContent>
                    <DialogActions id="setup__dialogActions">
                        <Stack
                            sx={{width: '100%'}}
                            direction={{xs: 'col', sm: 'row'}}
                            alignItems={'center'}
                            justifyContent={'flex-end'}
                            id="setup__stack_actions"
                        >
                            <Button
                                id="setup__button_next"
                                variant="outlined"
                                className="myOdu__button primary setUpButton"
                                sx={{}}
                                onClick={this.onSave}
                                disabled={ !Object.keys(this.props.setup?.Apps ?? {}) .length }
                            >
                                Next
                            </Button>
                            <Button
                                id="setup__button_skip"
                                variant="outlined"
                                className="myOdu__button secondary setUpButton"
                                onClick={this.onSkipOrCancel}
                            >
                                {this.props.setup?.reset
                                    ? 'Cancel'
                                    : 'Skip and Customize Later'}
                            </Button>
                        </Stack>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

export const storePreferences = (preferences, props) => {
    delete preferences?.Apps?.appsToDisplay;
    delete preferences?.Apps?.reset;
    putData(myPreferences, {
        preferences,
        midas: props.isImpersonating
            ? props.impersonation.midas
            : props.user.midas
    })
        .then(_ => {
            props.savePreferences(preferences);
            props.saveSetup({});
        })
        .catch(err => console.log(err));
};

const mapStateToProps = state => {
    return {
        user: state.AWSReducer.user,
        setup: state.setupReducer.setup,
        isImpersonating:
            state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    };
};

const mapDispatchToProps = dispatch => ({
    savePreferences: preferences => dispatch(savePreferences(preferences)),
    saveSetup: setup => dispatch(saveSetup(setup))
});

export default connect(mapStateToProps, mapDispatchToProps)(WithSmallScreen(SetUp));