import { InputAdornment, TextField, Typography, Breadcrumbs, Link } from '@mui/material';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Col, Row, Label } from 'reactstrap';
import { saveApplicationsFilter } from '../Landing/actions';
import ApplicationsList from '../common/ApplicationsList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { BreakpointBoxes } from '../common/BreakpointBoxes';
import {isProduction} from '../Layout/utils';
class AllApps extends Component {
    state = {  } 

    onTextChange = (e) => {
        this.props.saveApplicationsFilter(e.target.value)
    }

    render() { 
        const isProd = isProduction();
        
        return <React.Fragment>
            {!isProd && <BreakpointBoxes />}
            <Container className="myOdu__container_maxWidth">
                <Row className="justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Container>
                            <Row>
                                <Col>
                                    <Typography sx={{color: 'primary.main'}} component="h2" variant="h3" >
                                        All Applications List
                                    </Typography>

                                    <Breadcrumbs aria-label="breadcrumb" sx={{mb: 2}}>
                                        <Link underline="hover" color="inherit" href="/" sx={{cursor: 'pointer'}} >
                                            Dashboard
                                        </Link>
                                        <Typography color="text.primary">All Applications List</Typography>
                                    </Breadcrumbs>
                                </Col>
                            </Row>
                
                            <Row className="justify-content-center mt-4 mb-4">
                                <Col>
                                    <Typography component="h2" variant="h5" id="allApps__textFieldLabel">Search for Applications</Typography>
                                    
                                    <TextField onChange={this.onTextChange} 
                                        fullWidth 
                                        size='small' 
                                        aria-label='Search by keyword' 
                                        className="myOdu__noShowLabel"
                                        placeholder='Search by keyword'
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end"><FontAwesomeIcon icon={faMagnifyingGlass} size="lg"/></InputAdornment>
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col>
                                    <ApplicationsList />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    }
}
 
const mapStateToProps = (state) => {
    return {
        text: state.applicationsReducer.text
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    saveApplicationsFilter: (text) => dispatch(saveApplicationsFilter(text))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(AllApps);