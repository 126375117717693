const { Time } = require('./MomentTime');

module.exports = {
    getDateTime: date => {
        return Time.University(date).format('MMMM D, YYYY, h:mm A');
    },

    // Redirects the user to old portal
    stopPreview: (href) => {
        // delete the preview cookie
      document.cookie='portal-preview=; Path=/; Domain=.odu.edu; Expires=Thu, 01 Jan 1970 23:59:59 GMT';
      // navigate to old portal
      window.location.href = href;
    },

    //Θ(N) where N is the number of space separated tokens in a string
    //clean up the id string by converting to lower case, remove any special chars except '_' and trim
    cleanUpID: (id) => {

        if(!id)
            return '';

        return id
            ?.toLocaleLowerCase()
            ?.trim()
            ?.replace(/[^a-zA-Z0-9_ ]/g, '')
            ?.split(/\s/g)
            ?.map((token, index) => index ? (token?.[0]?.toLocaleUpperCase() ?? '') + (token?.slice(1) ?? '') : token)
            ?.join('') ?? ''
    }

};
