
import React, { Component } from 'react';
import { Stack, Button, IconButton, Box, Typography, CssBaseline, Theme, useTheme, ThemeProvider } from '@mui/material';
import {Container, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStop } from '@fortawesome/pro-solid-svg-icons';
import { faPortalEnter, faUserSecret } from '@fortawesome/pro-light-svg-icons';
import { connect } from 'react-redux';
import { saveImpersonation, toggleImpersonation } from '../Impersonation/actions';

class ImpersonationBanner extends Component {
	state = {  }

	resetImpersonation = () => {
		this.props.saveImpersonation({})
		window.location = '/'
	}

	render() { 
																						// title and label for ADA
		return <Container fluid className="myOdu__impersonationBanner" role="banner" aria-label="Impersonation Banner" title="Impersonation Banner">
			<Row className="justify-content-ends">
				<Col xs={12} md={6} className="text-center text-md-start mt-2">
					
						<FontAwesomeIcon icon={faUserSecret} size="lg" style={{paddingRight: '1rem', display:'inline'}}/>
						<Typography sx={{ fontWeight: 'bold', display:'inline' }}>&nbsp;{this.props.user?.midas}&nbsp;</Typography>
							is impersonating 
						<Typography sx={{ fontWeight: 'bold', display:'inline'}}>&nbsp;{this.props.impersonation.midas}</Typography>
							
				</Col>
				<Col className="text-md-end text-center" xs={12} md={6}>
					<Button variant="text" startIcon={<FontAwesomeIcon icon={faStop} />} onClick={this.resetImpersonation}>Revert to Self</Button>
					<Button onClick={this.props.toggleImpersonation} variant="text" startIcon={<FontAwesomeIcon icon={faPortalEnter} />}>Switch User</Button>
				</Col>
			</Row>
		</Container>
	}
}
 
const mapStateToProps = (state) => {
    return {
      user: state.AWSReducer.user,
	  impersonation: state.impersonationReducer.impersonation
    }
  }
  
  const mapDispatchToProps = (dispatch) => ({
    toggleImpersonation: () => dispatch(toggleImpersonation()),
    saveImpersonation: (impersonation) => dispatch(saveImpersonation(impersonation)),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(ImpersonationBanner);