// FormSelect.jsx
import React from 'react';
import {
    Select,
    MenuItem,
    Typography,
    Grid,
    InputLabel,
    useMediaQuery,
    ListItemText,
    ListItemIcon
} from '@mui/material';
import {Controller} from 'react-hook-form';
import _ from 'lodash';
import {FieldRequiredMark} from './FieldRequiredMark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons';

export const FormControlledDropdown = ({
    name,
    control,
    label,
    rules,
    options,
    ...props
}) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({field, formState: {errors}}) => {
                return (
                    <FormDropDown
                        id={name}
                        {...field}
                        label={label}
                        options={options}
                        error={_.get(errors, name)}
                        errorText={_.get(errors, name)?.message}
                        required={rules?.required}
                        {...props}
                    />
                );
            }}
        />
    );
};

export const FormDropDown = ({
    id,
    label,
    error,
    helperText,
    errorText,
    required,
    options,
    labelGridProps = {
        xs: 0,
        md: 3,
        lg: 4,
        xl: 3
    },
    inputGridProps = {xs: 12, md: 9, lg: 8, xl: 9},
    showChecks,
    ...props
}) => {
    const isSm = useMediaQuery(theme => theme.breakpoints.only('sm'));

    return (
        <Grid container spacing={{xs:0, sm:1}} alignItems="center">
            <FormDropDownUnwrapped
                id={id}
                label={label}
                error={error}
                helperText={helperText}
                errorText={errorText}
                required={required}
                options={options}
                labelGridProps={labelGridProps}
                inputGridProps={inputGridProps}
                showChecks={showChecks}
                {...props}            
            />
        </Grid>
    );
};

//TODO: remove guts of FormDropDown with exception of 
//outer grid then call this
export const FormDropDownUnwrapped = ({
    id,
    label,
    error,
    helperText,
    errorText,
    required,
    options,
    labelGridProps = {
        xs: 0,
        md: 3,
        lg: 4,
        xl: 3
    },
    inputGridProps = {xs: 12, md: 9, lg: 8, xl: 9},
    showChecks,
    ...props
}) => {
    const isSm = useMediaQuery(theme => theme.breakpoints.only('sm'));

    return (
            <>
            {label && (
                <Grid
                    item
                    textAlign={{xs: 'start', md: 'end', lg: 'end'}}
                    {...labelGridProps}
                >
                    <Typography
                        id="label"
                        className="myOdu__label"
                        component="label"
                    >
                        {label}{' '}
                        {required && <FieldRequiredMark label={label} />}
                    </Typography>
                    {!isSm && error && <p></p>}
                </Grid>
            )}
            <Grid item {...inputGridProps}>
                <InputLabel aria-label={label}>
                    <Select
                        id={id}
                        labelId="label"
                        key={props.value}
                        {...props}
                        size="small"
                        fullWidth
                        inputProps={{'aria-label': props.ariaLabel}}
                    >
                        {
                            options.map(option => (
                                <MenuItem key={option.value} value={option.value} {...option}>
                                    {option.label}
                                    {
                                        showChecks && option.isSelected &&
                                        <ListItemIcon sx={{paddingLeft: 1}}><FontAwesomeIcon icon={faCircleCheck} className='myOdu__success' /></ListItemIcon>
                                    }
                                </MenuItem>
                            ))
                        }
                    </Select>
                </InputLabel>

                {error ? (
                    <Typography
                        display="flex"
                        justifyContent="flex-start"
                        variant="caption"
                        className="myOdu__error"
                    >
                        {errorText}
                    </Typography>
                ) : (
                    <Typography
                        display="flex"
                        justifyContent="flex-start"
                        variant="caption"
                        className="myOdu__helper"
                    >
                        {helperText}
                    </Typography>
                )}
            </Grid>
        </>
    );
};
