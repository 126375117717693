import { SAVE_ZOOM_MEETINGS, TOGGLE_CCT } from "./actions";

const initialStateCCT = {
    isCCT: false,
    course: {},
    meetings: {
        currentMeetings: [],
        pastMeetings: [],
        isClassMeetingsScheduled: false
    }
}
//needs to persist across pages
export const CCTReducer = (state = initialStateCCT, action) => {
    const { type, payload } = action;
    switch (type) {
        case TOGGLE_CCT: {
            return {...state, isCCT: (payload?.course?.SUBJ_DESC || payload?.course?.SUBJECT_DESC_5) ? true : false, course: payload?.course ?? {}}
        }
        case SAVE_ZOOM_MEETINGS: {
            return {...state, meetings: payload.meetings}
        }
        default:
            return state;
    }
}