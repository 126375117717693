import * as React from 'react';

import {OutlinedInput, IconButton, InputAdornment, Card} from '@mui/material';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/pro-light-svg-icons';

export const SearchBar = ({
  placeholder = 'Search',
  value,
  onChange,
  onSearch,
  ...props
}) => {
  return (
    <Card elevation={0}>
      <OutlinedInput
        className="myOdu__hideFloatLabel"
        fullWidth
        size="small"
        label={placeholder}
        inputProps={{'aria-label': placeholder}}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label={placeholder} edge="end" onClick={onSearch}>
              <FontAwesomeIcon icon={faSearch} size="xs" />
            </IconButton>
          </InputAdornment>
        }
        {...props}
      />
    </Card>
  );
};
