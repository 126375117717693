import {faFaceAnxiousSweat} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Typography, Button, Stack, Link} from '@mui/material';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {postDataPublic} from '../../../DataAccessLayer';
import {errors} from '../../../DataAccessLayer/services';

class GenericError extends Component {
    state = {};

    componentDidMount() {
        this.logError(this.props.error);
    }

    logError = error => {
        const user = this.props.isImpersonating
            ? this.props.impersonation
            : this.props.user;
        postDataPublic(errors, {error, midas: user?.midas});
    };

    render() {
        return (
            <React.Fragment>
                <Stack alignItems={'center'}>
                    <FontAwesomeIcon
                        icon={faFaceAnxiousSweat}
                        fixedWidth
                        size="6x"
                    />
                    <Typography component="h2" className="myOdu__errorCode">
                        Oh No!
                    </Typography>
                    <Typography component="h3" className="myOdu__errorCode">
                        Something Went Wrong
                    </Typography>
                    <Typography component="p" className="myOdu__errorCode">
                        Please try again later, or{' '}
                        <a
                            href="https://oduprod.service-now.com/now/nav/ui/classic/params/target/incident.do"
                            target="_blank"
                        >
                            contact the ITS Help Desk{' '}
                        </a>
                        if the issue persists.
                    </Typography>
                    {!this.props.isHideHomePage && (
                        <Button
                            className="myOdu__errorCodeButton"
                            href="/"
                            sx={{mt: 3}}
                            variant="outlined"
                        >
                            Go to the home page
                        </Button>
                    )}
                </Stack>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        isImpersonating:
            state.impersonationReducer.impersonation?.isImpersonating,
        user: state.AWSReducer.user,
        impersonation: state.impersonationReducer.impersonation
    };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GenericError);
