import React, {Component} from 'react';
import {
    FormLabel,
    FormControl,
    FormGroup,
    Checkbox,
    RadioGroup,
    Radio,
    FormControlLabel,
    Box,
    Stack
} from '@mui/material';
import {connect} from 'react-redux';
import {saveSetup} from './actions';
import theme from '../../theme';
import {CAMPUS, HOUSING, constants} from '../common/constants';
import {campus} from '../common/constants';
import {isUserEvhsc, isUserStaff, isUserStudent} from '../Layout/utils';

class Questions extends Component {
    state = {};

    //Θ(1) handle campus check change
    onCampusChange = (campusName, isChecked) => {
        let setup = this.props.setup;
        if (isChecked)
            setup['campus'] = {...(setup?.campus ?? {}), [campusName]: true};
        else {
            delete setup.campus[campusName];
            if (!Object.keys(setup.campus).length) delete setup['campus'];
        }
        this.props.saveSetup(setup);
        this.props.onAnswersChanged(CAMPUS);
    };

    //Θ(1) handle housing change
    onHousingChange = isHousing => {
        let setup = this.props.setup;
        setup['isHousing'] = isHousing;
        this.props.saveSetup(setup);
        this.props.onAnswersChanged(HOUSING);
    };

    //Θ(1) handle meal change
    onMealChange = isMeal => {
        let setup = this.props.setup;
        setup['isMeal'] = isMeal;
        this.props.saveSetup(setup);
        //TODO: Call onAnswersChanged
    };

    render() {

        const isImpersonating = this.props.impersonation?.isImpersonating;

        const user = isImpersonating ? this.props.impersonation : this.props.user;

        const isStudent = isUserStudent(user);
        const isStaff = isUserStaff(user);
        const isEvhsc = isUserEvhsc(user);

        return (
            <Stack sx={{ml:2}} spacing={2} id={this.props.id + '__stack'}>
                <Stack id={this.props.id + '__stack_questionLive'}>
                    <FormLabel id={this.props.id + '__label_questionLive'}>
                        1. Where do you live?
                    </FormLabel>
                    <FormControl
                        sx={{pl: 2}}
                        id={this.props.id + '__formControl_questionLive'}
                    >
                        <RadioGroup
                            row
                            aria-labelledby="doesLiveOduLabel"
                            name="doesLiveOdu"
                            id={this.props.id + '__radioGroup_questionLive'}
                        >
                            <Stack id={this.props.id + '__stack_wrapperQuestionLive'}>
                                <FormControlLabel
                                    id={this.props.id + '__label_questionLive_offCampus'}
                                    value={true}
                                    control={ <Radio id={this.props.id + '__radio_questionLive_offCampus'} size="small" color="secondary" onChange={e => this.onHousingChange(false) } /> }
                                    label="Off-campus (neither ODU nor VHS Campus)"
                                />
                                <FormControlLabel
                                    id={this.props.id + '__label_questionLive_oduCampus'}
                                    value={'odu'}
                                    control={ <Radio id={this.props.id + '__radio_questionLive_oduCampus'} size="small" color="secondary" onChange={e => this.onHousingChange(true) } /> }
                                    label="ODU - Univeristy Housing"
                                />
                                <FormControlLabel
                                    id={this.props.id + '__label_questionLive_hague'}
                                    value={'evhsc'}
                                    control={ <Radio id={this.props.id + '__radio_questionLive_hague'} size="small" color="secondary" onChange={e => this.onHousingChange(false) } /> }
                                    label="VHS - Hague Club Apartments"
                                />
                            </Stack>
                        </RadioGroup>
                    </FormControl>
                </Stack>

                {!isEvhsc && (
                    <Stack id={this.props.id + '__stack_questionMeal'}>
                        <FormLabel id={this.props.id + '__label_questionMeal'}>
                            2. Do you have a meal plan?
                        </FormLabel>
                        <FormControl
                            sx={{pl: 2}}
                            id={this.props.id + '__formControl_questionMeal'}
                        >
                            <RadioGroup
                                row
                                aria-labelledby="hasMealPlanLabel"
                                name="hasMealPlan"
                                id={this.props.id + '__radioGroup_questionMeal'}
                            >
                                <FormControlLabel
                                    id={this.props.id + '__label_questionMeal_yes'}
                                    value={true}
                                    control={ <Radio id={ this.props.id + '__radio_questionMeal_yes' } size="small" color="secondary" onChange={e => this.onMealChange(true) } /> }
                                    label="Yes"
                                />
                                <FormControlLabel
                                    id={this.props.id + '__label_questionMeal_no'}
                                    value={false}
                                    control={ <Radio id={ this.props.id + '__radio_questionMeal_no' } size="small" color="secondary" onChange={e => this.onMealChange(false) } /> }
                                    label="No"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                )}

                <Stack id={this.props.id + '__stack_questionAssociatedCampus'}>
                    <FormLabel id={this.props.id + '__label_questionAssociatedCampus'}>
                        {isEvhsc ? '2.' : '3.'} Which campus(es) are you
                        associated with?
                    </FormLabel>
                    <FormGroup
                        sx={{pl: 2}}
                        id={this.props.id + '__formGroup_questionAssociatedCampus'}
                    >
                        <FormControlLabel
                            id={this.props.id + '__label_questionAssociatedCampus_norfolkMain'}
                            sx={{alignItems: 'flex-start'}}
                            control={ <Checkbox id={this.props.id + '__checkbox_questionAssociatedCampus_norfolkMain'} size="small" color="secondary" onChange={e => this.onCampusChange( 'main', e.target.checked ) } /> }
                            size="small"
                            label="Norfolk Main Campus"
                        />
                        <FormControlLabel
                            id={this.props.id + '__label_questionAssociatedCampus_norfolkMain'}
                            sx={{alignItems: 'flex-start'}}
                            control={
                                <Checkbox id={this.props.id + '__checkbox_questionAssociatedCampus_norfolkMain'} size="small" color="secondary" onChange={e => this.onCampusChange( 'evms', e.target.checked ) } /> }
                            label="VHS Campus"
                        />
                        <FormControlLabel
                            id={this.props.id + '__label_questionAssociatedCampus_triCities'}
                            sx={{alignItems: 'flex-start'}}
                            control={ <Checkbox id={this.props.id + '__checkbox_questionAssociatedCampus_triCities'} size="small" color="secondary" onChange={e => this.onCampusChange( 'triCities', e.target.checked ) } /> }
                            label="ODU Tri-Cities Center"
                        />
                        <FormControlLabel
                            id={this.props.id + '__label_questionAssociatedCampus_vbCenter'}
                            sx={{alignItems: 'flex-start'}}
                            control={ <Checkbox id={this.props.id + '__checkbox_questionAssociatedCampus_vbCenter'} size="small" color="secondary" onChange={e => this.onCampusChange( 'virginiaBeach', e.target.checked ) } /> }
                            label="ODU Virginia Beach Center"
                        />
                        <FormControlLabel
                            id={this.props.id + '__label_questionAssociatedCampus_peninsula'}
                            sx={{alignItems: 'flex-start'}}
                            control={ <Checkbox id={this.props.id + '__checkbox_questionAssociatedCampus_peninsula'} size="small" color="secondary" onChange={e => this.onCampusChange( 'peninsula', e.target.checked ) } /> }
                            label="ODU Peninsula Center"
                        />
                    </FormGroup>
                </Stack>
            </Stack>
        );
    }
}

const mapStateToProps = state => {
    return {
        setup: state.setupReducer.setup,
        user: state.AWSReducer.user,
        impersonation: state.impersonationReducer.impersonation
    };
};

const mapDispatchToProps = dispatch => ({
    saveSetup: setup => dispatch(saveSetup(setup))
});

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
