import React, {Component} from 'react';
import Widget from '../common/Widget';

import {Box, Typography, Button, Skeleton, Grid} from '@mui/material';
import {getData} from '../../DataAccessLayer/index.js';
import {mySchedule} from '../../DataAccessLayer/services.js';
import MyScheduleItem from './MyScheduleItem.js';
import ApiErrorMessage from '../common/ApiErrorMessage';

class MySchedule extends Component {
    state = {
        isExpand: true,
        data: [],
        itemsToShow: 5, // Initially show 5 items
        isLoading: false,
        isError: false
        //TODO: enable in v1.1
        // menu:[
        //     {id: "mySchedule_hide", title: "Hide"},
        // ],
    };

    componentDidMount() {
        this.loadSchedule();
    }

    loadSchedule = async () => {
        this.setState({isLoading: true, isError: false});
        try {
            const data = await getData(mySchedule, true);
            this.setState({data});
        } catch (err) {
            this.setState({isError: true});
        } finally {
            this.setState({isLoading: false});
        }
    };

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    showMore = () => {
        this.setState({
            itemsToShow: this.state.itemsToShow + 5
        });
    };

    render() {
        const {data, itemsToShow, isLoading, isError} = this.state;
        const itemsToDisplay = data.slice(0, itemsToShow);

        return (
            <>
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title,
                        isTitleStylized: this.props.isTitleStylized,
                        menu: this.state.menu
                    }}
                    className="myOdu__mySchedule"
                >
                    <div
                        className="myOdu__mySchedule_wrapper"
                        id={this.props.widget.id + '__wrapper'}
                    >
                        <Grid
                            container
                            className="scheduleList"
                            wrap="nowrap"
                            direction="row"
                            rowSpacing={2}
                            id={this.props.widget.id + '__grid'}
                        >
                            {isLoading ? (
                                Array.from({length: 1}, (_, index) => (
                                    <Grid
                                        item
                                        sx={{width: '100%', py: 1, px: 0}}
                                        key={index}
                                        id={
                                            this.props.widget.id +
                                            '__wrapper_skeleton'
                                        }
                                    >
                                        <Box sx={{width: '100%'}}>
                                            <Skeleton
                                                variant="rectangular"
                                                height={80}
                                                id={
                                                    this.props.widget.id +
                                                    '__skeleton'
                                                }
                                            />
                                        </Box>
                                    </Grid>
                                ))
                            ) : itemsToDisplay.length === 0 && !isLoading ? (
                                isError ? (
                                    <ApiErrorMessage
                                        widgetName={this.props.title}
                                        reload={this.loadSchedule}
                                    />
                                ) : (
                                    <Typography
                                        component="p"
                                        variant="small"
                                        id={
                                            this.props.widget.id +
                                            '__type_noScheduleFound'
                                        }
                                    >
                                        No schedule found.
                                    </Typography>
                                )
                            ) : (
                                itemsToDisplay.map((item, index) => {
                                    item.id = index;
                                    return (
                                        <MyScheduleItem
                                            id={
                                                this.props.widget.id +
                                                '__schedule_item_' +
                                                index
                                            }
                                            key={
                                                this.props.widget.id +
                                                '__schedule_item_' +
                                                index
                                            }
                                            item={item}
                                            index={index}
                                        />
                                    );
                                })
                            )}
                            {itemsToDisplay.length < data.length && (
                                <Grid
                                    item
                                    sx={{minWidth: 'fit-content'}}
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{paddingTop: 0}}
                                    id={
                                        this.props.widget.id +
                                        '__wrapper_showMoreButton'
                                    }
                                >
                                    <Button
                                        id={
                                            this.props.widget.id +
                                            '__button_showMore'
                                        }
                                        className="myOdu__button secondary"
                                        variant="outlined"
                                        onClick={this.showMore}
                                    >
                                        Show more
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </Widget>
            </>
        );
    }
}

export default MySchedule;
