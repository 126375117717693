import React, {Component} from 'react';
import Widget from '../common/Widget.js';
import {getDataPublic} from '../../DataAccessLayer';
import {announcements} from '../../DataAccessLayer/services';
import AnnouncementTabs from './AnnouncementTabs.js';
import {connect} from 'react-redux';
import {isUserStudent} from '../Layout/utils';
class Announcements extends Component {
    state = {
        isExpand: true,
        // menu:[
        //     {id: "announcements_postAnnouncement", title: "Post Announcement"},
        //      // TODO: enable in v1.1
        //      // {id: "announcements_hide", title: "Hide"},
        // ],
        selectedTab: 0,
        universityAnnouncements: {},
        studentAnnouncements: {},
        tabIndex: 0
    };

    componentDidMount() {
        this.loadAnnouncements();
        const user = this.props.isImpersonating
            ? this.props.impersonation
            : this.props.user;
        if (isUserStudent(user)) this.setState({tabIndex: 0});
        else this.setState({tabIndex: 1});
    }

    loadAnnouncements = () => {
        getDataPublic(announcements).then(announcements => {
            this.setState({
                studentAnnouncements: announcements.studentAnnouncements,
                universityAnnouncements: announcements.facultyStaffAnnouncements
            });
        });
    };

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    onTabChange = (event, tabIndex) => {
        this.setState({tabIndex});
    };

    render() {
        return (
            <div id={"announcements_div"}>
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title,
                        isTitleStylized: this.props.isTitleStylized
                    }}
                    {...this.props}
                >
                    <AnnouncementTabs
                        id={this.props.widget.id + "__announcementTabs"}
                        tabIndex={this.state.tabIndex}
                        onTabChange={this.onTabChange}
                        universityAnnouncements={ this.state.universityAnnouncements }
                        studentAnnouncements={this.state.studentAnnouncements}
                        isEvms={false}
                        evmsAnnouncements={''}
                    />
                </Widget>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.AWSReducer.user,
        impersonation: state.impersonationReducer.impersonation
    };
};

export default connect(mapStateToProps)(Announcements);
