import React, {Component} from 'react';
import Widget from '../common/Widget';
import {Button, Link, List, ListItem, Typography, Tooltip} from '@mui/material';
import {enqueueSnackbar} from 'notistack';
import {deleteData, getData, postData, putData} from '../../DataAccessLayer';
import {myBookmarks} from '../../DataAccessLayer/services';
import BookmarksManager from './BookmarksManager';
import PortalTooltip from '../common/PortalTooltip';
import {toast} from 'react-toastify';

class MyBookmarks extends Component {
    //Θ(1) toggle the bookmarks manager
    onToggle = () => {
        this.setState({isManager: !this.state.isManager});
    };

    state = {
        //control widget expantion
        isExpand: false,

        //menu for the widget
        menu: [
            {
                id: 'myBookmarks__editBookmarks',
                title: 'Edit Bookmarks',
                onClick: this.onToggle
            }
            //TODO: enable in V1.1
            // {id: "myBookmarks_hide", title: "Hide"},
        ],

        //array of user bookmarks
        bookmarks: [],

        //control bookmarks manager
        isManager: false
    };

    componentDidMount() {
        this.loadBookmarks();
    }

    //Θ(1) load user bookmarks form server
    loadBookmarks = () => {
        getData(myBookmarks).then(bookmarks => {
            if (bookmarks) this.setState({bookmarks});
        });
    };

    //toggle the widget expantion
    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    //Θ(1) Add a new bookmark for the user and reload
    onAdd = (title, url) => {
        const body = {
            bookmark: {
                TITLE: title,
                URL: url
            }
        };
        putData(myBookmarks, body).then(result => {
            this.loadBookmarks();
            toast.success('Bookmark added');
        });
    };

    //Θ(1) Edit the selected bookmark and reload
    onEdit = (title, url, CREATED_AT) => {
        const body = {
            bookmark: {
                TITLE: title,
                URL: url,
                CREATED_AT
            }
        };
        postData(myBookmarks, body).then(result => {
            this.loadBookmarks();
            toast.success('Bookmark changes saved');
        });
    };

    //Θ(1) delete the selected bookmark and reload
    onDelete = CREATED_AT => {
        deleteData(myBookmarks + '/' + CREATED_AT).then(result => {
            this.loadBookmarks();
            toast.success('Bookmark deleted');
        });
    };

    render() {
        return (
            <>
                {/* Let the AddBookmark unmount to reset internal state */}
                {this.state.isManager && (
                    <BookmarksManager
                        id={"myBookmarks__bookmarksManager"}
                        isOpen={this.state.isManager}
                        bookmarks={this.state.bookmarks}
                        onToggle={this.onToggle}
                        onAdd={this.onAdd}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                    />
                )}

                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title, 
                        isTitleStylized: this.props.isTitleStylized, 
                        menu: this.state.menu
                    }}
                    isExpand={this.state.isExpand}
                    {...this.props}
                >
                    <div className="myOdu__myBookmarks_wrapper" id={"myBookmarks__div_listWrapper"}>
                        <List id={"myBookmarks__list_listItem"} sx={{pt: 0}} dense={true}>
                            {this.state.bookmarks.map(bookmark => (
                                <ListItem id={"myBookmarks__listItem"+bookmark.CREATED_AT}
                                    sx={{pl: 1}}
                                    key={
                                        this.state.widgetId +
                                        '_ul_li_' +
                                        bookmark.CREATED_AT
                                    }
                                    divider
                                >
                                    <PortalTooltip id={"myBookmarks__portalTooltip_listItem"+bookmark.CREATED_AT}>
                                        <Link
                                            id={
                                                'myBookmarks__link_' +
                                                bookmark.CREATED_AT
                                            }
                                            variant="small"
                                            href={bookmark.URL}
                                            underline={'hover'}
                                            target="_blank"
                                        >
                                            {bookmark.TITLE}
                                        </Link>
                                    </PortalTooltip>
                                </ListItem>
                            ))}
                            {this.state.bookmarks.length == 0 && (
                                <>
                                    <Typography id={"myBookmarks__cardText_noBookmarksFound"}
                                        variant="smaller"
                                        xs={{display: 'inline'}}
                                    >
                                        No bookmarks found.{' '}
                                    </Typography>
                                    <PortalTooltip id={"myBookmarks__portalTooltip_noBookmarksFoundAddBookmarks"} title="Open bookmark editor">
                                        <Link
                                            id="myBookmarks__link_noBookmarksFoundAddBookmarks"
                                            className="cursor-pointer"
                                            variant="smaller"
                                            xs={{
                                                display: 'inline',
                                                cursor: 'pointer'
                                            }}
                                            onClick={this.onToggle}
                                        >
                                            Add bookmarks.
                                        </Link>
                                    </PortalTooltip>
                                </>
                            )}
                        </List>
                    </div>
                </Widget>
            </>
        );
    }
}

export default MyBookmarks;
