import React from 'react';
import {Typography, Container, Box} from '@mui/material';
import {FormControlledTextField} from '../common/Form/FormTextField';

export const PreferredFirstNameSection = ({control}) => {
  return (
    <Container sx={{mt: 4}}>
      <Typography component="h3" variant="h4">
        Preferred First Name
      </Typography>
      <Typography variant="body1">
        The{' '}
        <a
          href="https://www.odu.edu/academics/academic-records/preferred-name"
          id="myInfo__link_preferredFirstName"
        >
          preferred first name
        </a>{' '}
        will be used in University systems and communications, except where the
        legal name is required.
      </Typography>

      <Box sx={{flexGrow: 1, mt: 3, mb: 3}}>
        <FormControlledTextField
          name="preferredFirstName"
          control={control}
          label="Preferred First Name"
          rules={{
            pattern: {
              value: /^[a-zA-Z '-]+$/,
              message:
                'Preferred first name can only contain letters, space, apostrophes or hyphens.'
            }
          }}
          autoFocus
        />
      </Box>
    </Container>
  );
};
