import React, {Component} from 'react';
import {Link, Box, Stack, Skeleton, Button} from '@mui/material';
import {Col, Row} from 'reactstrap';
import {
    faChevronDoubleDown,
    faChevronDoubleUp
} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DetailsList from './DetailsList';

class DetailsContent extends Component {
    state = {
        itemsExpansionStatus: [],
        isDisableExpandAll: true,
        isDisableCollapseAll: true
    };

    componentDidMount() {
        if (this.props.items?.length) {
            this.toggleIsExpandAll();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.itemsExpansionStatus !== this.state.itemsExpansionStatus
        ) {
            this.toggleIsDisableExpandAll();
        }

        if (prevProps.items != this.props.items && this.props.items?.length) {
            this.toggleIsExpandAll();
        }
    }

    toggleItemExpansionStatus = (itemIndex, value) => {
        this.setState({
            itemsExpansionStatus: this.state.itemsExpansionStatus?.map(
                (itemStatus, index) => {
                    if (index === itemIndex) {
                        return value;
                    } else {
                        return itemStatus;
                    }
                }
            )
        });
    };

    toggleIsExpandAll = () => {
        this.setState({
            itemsExpansionStatus: this.props.items.map(item => true)
        });
    };

    toggleIsCollapseAll = () => {
        this.setState({
            itemsExpansionStatus: this.props.items.map(item => false)
        });
    };

    toggleIsDisableExpandAll = () => {
        const items = this.state.itemsExpansionStatus.filter(
            status => status === true
        );
        this.setState({
            isDisableExpandAll:
                items.length === this.props.items.length ? true : false,
            isDisableCollapseAll: items.length === 0 ? true : false
        });
    };

    render() {
        return (
            <Box>
                <Stack sx={{width: '100%', mb:2}} direction={{xs:'col', sm:'row'}} alignItems={'center'} justifyContent={"flex-end"}>
                    <Button 
                        id="checklist__button_expandAll"
                        onClick={this.toggleIsExpandAll}
                        variant="outlined"
                        className="myOdu__button secondary checklistButton"
                        disabled={this.state.isDisableExpandAll}
                        startIcon={
                            <FontAwesomeIcon fixedWidth icon={faChevronDoubleDown} size="sm" style={{fontSize: '.8rem'}} />
                        }
                    >
                        Expand All
                    </Button>
                    <Button 
                        id="checklist__button_collapseAll"
                        onClick={this.toggleIsCollapseAll}
                        variant="outlined"
                        className="myOdu__button secondary checklistButton"
                        disabled={this.state.isDisableCollapseAll}
                        startIcon={
                            <FontAwesomeIcon fixedWidth icon={faChevronDoubleUp} size="sm" style={{fontSize: '.8rem'}} />
                        }
                    >
                        Collapse All
                    </Button>
                </Stack>
                {this.state.itemsExpansionStatus.length ===
                    this.props.items.length && (
                    <DetailsList
                        items={this.props.items}
                        isLoading={this.props.isLoading}
                        checklistTitle={this.props.checklistTitle}
                        toggleItemExpansionStatus={
                            this.toggleItemExpansionStatus
                        }
                        itemsExpansionStatus={this.state.itemsExpansionStatus}
                        tabSelected={this.props.tabSelected}
                    />
                )}
                {this.state.itemsExpansionStatus.length !==
                    this.props.items.length && (
                    <Skeleton variant="rectangular" />
                )}
            </Box>
        );
    }
}

export default DetailsContent;
