import React, { Component } from 'react';
import { Typography, Link, List, ListItem, Stack, Tooltip } from '@mui/material';
import PortalTooltip from '../common/PortalTooltip';
import { cleanUpID } from '../common/utils';

class AnnouncementList extends Component {

    getCss = category => {
        if (category === 'Activities/Social') category = 'Social';
        return category.replaceAll(' ', '');
    };
    
    render() {
        return (

            <div id={this.props.id +"__div_wrapper"}>
                {this.props.list ? 
                    <List id={this.props.id +"__list_announcements"} sx={{ pt: 0 }} dense={true} className="myOdu__announcements myOdu__announcements_wrapper">
                        {this.props.list?.map((group, index) => (          
                            <ListItem id={this.props.id +"__listItem_" + index} key={"myOdu__announcements_" + index} sx={{ p: 0, ml: 1 }}>
                                {/* //TODO: add string clean */}
                                <Stack id={this.props.id +"__stack_" + cleanUpID(group.category) }>
                                    <Typography 
                                        id={this.props.id +"__header_" + cleanUpID(group.category)}
                                        variant="h6"
                                        component="h4"
                                        className={
                                            this.getCss(group.category) +
                                            ' iconHeader'
                                        }
                                    >
                                        {group.category}
                                    </Typography>

                                     {/* //TODO: add string clean */}
                                    <List id={this.props.id +"__list_" + cleanUpID(group.category)} dense={true} sx={{ pl: 1 }}>
                                        {/* //TODO: add string clean */}
                                        {group.announcements.map((announcement, index) => (    
                                            <ListItem id={this.props.id +"__listItem_" + cleanUpID(announcement.url) + "_" + index} key={'myAnnouncements__list_item_' + index} sx={{ p: 0 }}>
                                                <PortalTooltip id={this.props.id +"__tooltip_" + cleanUpID(announcement.url) + "_" + index} title="Opens in new window" enterDelay={750} leaveDelay={0} enterNextDelay={750} arrow>
                                                    <Link
                                                        id={this.props.id +"__link_" + cleanUpID(announcement.url) + "_" + index}
                                                        href={announcement.url}
                                                        sx={{ pb: 2 }}
                                                        target="_blank"
                                                        variant="small"
                                                        underline="hover"
                                                    >
                                                        {announcement.title}
                                                    </Link>
                                                </PortalTooltip>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Stack>
                            </ListItem> 
                        ))} 
                    </List>
                : 
                <Typography id={this.props.id + "__announcementsList__text_noAnnouncementsFound"} component='p' variant='small'>No announcements found</Typography>}
          </div>
        );
    }
}

export default AnnouncementList;
