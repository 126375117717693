module.exports = {
    CRITICAL: 'Critical',
    ACTION_HIDE: 'hide',
    ACTION_UNHIDE: 'unhide',
    ALERT_KEY_HOLDS: 'holds',
    ALERT_KEY_UPCOMING_HOLDS: 'upcomingHolds',
    ALERT_KEY_BALANCE: 'balance',
    ALERT_KEY_FINANCIAL_AID: 'financialAid',
    CAMPUS: 'campus',
    HOUSING: 'isHousing',
    ICON_PATH: '/img/logos/',
    MOVE_WIDGET_UP: 'UP',
    MOVE_WIDGET_DOWN: 'DOWN',
    DayMapping: {
        M: 'Monday',
        T: 'Tuesday',
        W: 'Wednesday',
        R: 'Thursday',
        F: 'Friday',
        S: 'Saturday',
        U: 'Sunday'
    },
    zoomDevEmail: 'knavulur@365dev.odu.edu',
    CHECKLIST_YES_INDICATOR: 'Y',
    SYSTEM_STATUS_IDENTIFIED: 'identified',
    SYSTEM_STATUS_FIXED: 'fixed',
    adminGroupName: 'DL MyODU Portal Admins',
    powerUserGroupName: 'DL MyODU Portal Power Users',
    advisorRoleName: 'ADVISOR',
    facultyRoleName: 'FACULTY',
    staffRoleName: 'STAFF',
    studentRoleName: 'STUDENT',
    medicalStudentRoleName: 'SCHOOL_OF_MEDICINE_STUDENT',
    healthProfessionsStudentRoleName: 'SCHOOL_OF_HEALTH_PROF_STUDENT',
    medicalResidentRoleName: 'MEDICAL_RESIDENT',
    //EVCM = people at the Fort Norfolk Campus
    evmcRoleName: 'EVMC',
    evmcStaffRoleName: 'EVMC_STAFF',
    evmcFacultyRoleName: 'EVMC_FACULTY',
    //ECHSC = people involved in the school, could be Fort Norfolk campus, could be Norfolk Main campus
    evhscRoleName: 'EVHSC',
    evhscFacultyRoleName: 'EVHSC_FACULTY',
    evhscStaffRoleName: 'EVHSC_STAFF',
    GOOGLE_ANALYTICS_KEY: 'GTM-5HPKDTRG',
    mealsThreshold: '10',
    flexPointsThreshold: '50',
    MAIN_BRANCH: 'main',
    CURRENT_TERM: 'CURRENT_TERM',
    cardCenterMonarch: 'https://cardcenter.odu.edu/monarch',
    studentAccount: 'https://secure.touchnet.com/C21192_tsa/web/caslogin.jsp',
    meetingStartsWithin: 30,
    monthsList: [   
                    'January', 'February', 'March', 'April', 'May', 'June', 
                    'July', 'August', 'September', 'October', 'November', 'December'
                ],
    FACULTY_EMAIL_CODE: 'ODUF',
    STUDENT_EMAIL_CODE: 'ODUS',
    TIME_ZONE: "America/New_York"
};
