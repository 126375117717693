import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {Controller} from 'react-hook-form';
import {Grid, Typography, TextField} from '@mui/material';
import {FieldRequiredMark} from './FieldRequiredMark';
import _ from 'lodash';
import { DatePicker } from '@mui/x-date-pickers';

export const FormControlledDatePicker = ({
    name,
    control,
    label,
    rules,
    required,
    ...props
}) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({field, formState: {errors}}) => {
                return (
                    <FormDatePicker
                        id={name}
                        {...field}
                        label={label}
                        value={field.value}
                        onChange={field.onChange}
                        error={!!_.get(errors, name)}
                        errorText={_.get(errors, name)?.message}
                        required={rules?.required || required}
                        {...props}
                    />
                );
            }}
        />
    );
};

export const FormDatePicker = ({
    id,
    label,
    error,
    helperText,
    errorText,
    required,
    value,
    onChange,
    textAlign,
    labelGridProps = {
        xs: 12,
        md: 3,
        lg: 4,
        xl: 3
    },
    inputGridProps = {xs: 12, md: 9, lg: 8, xl: 9},
    maxDate,
    minDate,
    ...props
}) => {
    return (
        <Grid container spacing={{xs:0, sm:1}} alignItems={'center'}>
            {label && (
                <Grid
                    item
                    textAlign={textAlign ?? {xs: 'start', md: 'end', lg: 'end'}}
                    {...labelGridProps}
                >
                    <Typography
                        className="myOdu__label"
                        component="label"
                        htmlFor={id}
                    >
                        {label}{' '}
                        {required && <FieldRequiredMark label={label} />}
                    </Typography>
                </Grid>
            )}
            <Grid item {...inputGridProps}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        value={value}
                        onChange={onChange}
                        slotProps={{
                            textField: {
                                id,
                                size: 'small',
                                fullWidth: true,
                                error
                            },
                        }}
                        disabled = {props.disabled}
                        maxDate = {maxDate}
                        minDate = {minDate}
                    />
                </LocalizationProvider>

                {error ? (
                    <Typography
                        display="flex"
                        justifyContent="flex-start"
                        variant="caption"
                        className="myOdu__error"
                    >
                        {errorText}
                    </Typography>
                ) : (
                    <Typography
                        display="flex"
                        justifyContent="flex-start"
                        variant="caption"
                        className="myOdu__helper"
                    >
                        {helperText}
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};
