import React, { Component } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Link, FormControl, FormGroup, Stack, FormControlLabel, Switch } from '@mui/material';
import Icon from '@mui/material/Icon';
import { connect } from 'react-redux';
import ApplicationsList from '../../common/ApplicationsList';

const accordionProps = {
    sx: {
      flexDirection: "row-reverse", 
    },
  };

class Applications extends Component {
    state = {
        isExpand: true,
        isViewByName: true,
        isMobile: true,     //dummy
        isAndroid: false    //dummy
    }

    componentDidUpdate(){
        if(this.props.text && !this.state.isExpand)
            this.toggleExpand(true)
    }

    toggleExpand = (forced) => {
        this.setState({isExpand: forced ?? !this.state.isExpand})
    }

    render() {
        
        return <React.Fragment>
            <Accordion expanded={this.state.isExpand} onChange={() => this.toggleExpand()} elevation={0}>
                <AccordionSummary expandIcon={<Icon baseClassName='fal' className='fa-chevron-down' />} {...accordionProps}>
                    <Typography component="h2" variant="h4" sx={{ml:2}}>All Applications</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{px: 1}}>
                    {/* <Stack sx={{justifyContent: 'space-between', borderBottom: '1px solid #EEE', mb:2, pb:1}} direction="row">
                        <Typography component="h3" variant="h6">Listed By {this.state.isViewByName? 'Name': 'Category'}</Typography>
                        

                        <FormGroup>      
                            <Stack direction="row" spacing={1} alignItems="center">
                                <label className="myOdu__toggleSwitchLabel"><Typography variant="smaller" id="applicationsListViewByLabel">View By</Typography></label>
                                <Typography variant="smaller">Name</Typography>
                                <Switch size="small" color="primary" inputProps={{ 'aria-label': 'View By' }} />
                                <Typography variant="smaller">Category</Typography>
                            </Stack>
                        </FormGroup>
                    </Stack> */}
                    
                    
                   <ApplicationsList isAlphabetBarVisible={true} />
                    


                    
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    }
}
 
const mapStateToProps = (state) => {
    return {
        text: state.applicationsReducer.text
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
});
  
export default connect(mapStateToProps, mapDispatchToProps)(Applications);