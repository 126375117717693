import {store} from '../store';

/**
 * Generates the impersonation header
 * @returns The impersonation header
 */
export const getImpersonationHeader = () => {
    const impersonation = store.getState()?.impersonationReducer?.impersonation;
    return impersonation?.isImpersonating
        ? {Impersonation: impersonation.midas}
        : '';
};

export const getLtiToken = () => {
    return store.getState()?.AWSReducer?.user?.accessToken;
};

export const isLti = () => {
    return !!store.getState()?.AWSReducer?.user?.accessToken;
};

export const getBrowserMetadata = async () => {
    const uaData = navigator.userAgentData;

    return {
        userAgent: uaData
            ? await uaData.getHighEntropyValues([
                  'platform',
                  'platformVersion',
                  'architecture',
                  'model',
                  'uaFullVersion'
              ])
            : navigator.userAgent,
        language: navigator.language,
        cookiesEnabled: navigator.cookieEnabled,
        online: navigator.onLine,
        screenWidth: window.screen.width,
        screenHeight: window.screen.height,
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        devicePixelRatio: window.devicePixelRatio
    };
};
