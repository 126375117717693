import React, {Component} from "react";
import PortalModal from "../../../../../common/PortalModal";
import { FormTextField } from "../../../../../common/Form/FormTextField";
import { Autocomplete, Chip, FormControl, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { isValidEmail, isValidEmails } from "./utils";
import PortalEmailsInput from "../../../../../common/PortalEmailsInput";
import { isError } from "lodash";

class AddAlternativeHosts extends Component {

    state = {
        altHosts: this.props.meeting.settings.alternative_hosts.split(",").filter(host => host !== ''),
        inputValue: '',
        isError: false
    }

    //Θ(1) Sets altHosts
    onChangeAltHosts = (altHosts) => {
        this.setState({
            altHosts
        })
    }

    //Θ(1) Sets input value
    onChangeInputValue = (inputValue) => {
        this.setState({
            inputValue
        })
    }

    //Θ(1) Sets is error
    onChangeIsError = (isError) => {
        this.setState({
            isError
        })
    }

    //Θ(1) Adds the alt hosts
    onAddHosts = () => {
        let hosts = this.state.altHosts;

        //In case of user doesn't hit space and there is an email to add in the textfield
        if(this.state.inputValue !== '' && isValidEmail(this.state.inputValue)) {
            hosts.push(this.state.inputValue)
            this.setState({
                inputValue: ''
            })
        } else if(this.state.inputValue !== '') {
            this.setState({
                isError: true
            })
            return;
        }
        
        this.props.addAltHost(hosts.join(","));
    }

    render() {
        let {meeting} = this.props;
        const isEdit = meeting?.settings?.alternative_hosts.length > 0
        const isPrimaryButtonDisabled = this.props.isLoading || this.state.isError || (this.state.altHosts.length === 0 && this.state.inputValue.length === 0) || !isValidEmails([...this.state.altHosts, this.state.inputValue])
        return <React.Fragment>
            <PortalModal
                maxWidth="md"
                onPrimaryClick={this.onAddHosts}
                onClose={this.props.toggleIsAddAltHostDisplay}
                isOpen={this.props.isAddAltHostDisplay}
                title = {isEdit ? 'Edit Alternative Hosts' : 'Add Alternative Host'}
                id="addAltHostModal"
                primaryButtonTitle={isEdit ? "Edit" : "Add"}
                isPrimaryButtonDisabled = {isPrimaryButtonDisabled}
                isCloseable={true}
                secondaryButtonTitle= "No, Cancel"
                onSecondaryClick={this.props.toggleIsAddAltHostDisplay}
            >
                <FormControl fullWidth >
                    <Grid container direction="column" gap={1} >
                        <Grid item xs={12}>
                            <Typography component="label" className="myOdu__label">
                                Alternative Host(s) Email
                            </Typography>
                        </Grid>
                        <Grid item>
                            <PortalEmailsInput 
                                altHosts = {this.state.altHosts}
                                inputValue = {this.state.inputValue}
                                isError = {this.state.isError}
                                onChangeAltHosts = {this.onChangeAltHosts}
                                onChangeInputValue = {this.onChangeInputValue}
                                onChangeIsError = {this.onChangeIsError}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </PortalModal>
        </React.Fragment>
    }
}

export default AddAlternativeHosts;