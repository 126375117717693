import React, { Component } from 'react';
import { Box, Link, Stack, Typography, ListItem} from '@mui/material';
import DateBox from '../common/DateBox.js';
import PortalTooltip from '../common/PortalTooltip.js';
import { cleanUpID } from '../common/utils';

class NewsItem extends Component {

    getDescription = (description, link) => {

        if (description) {
            return <Typography id={this.props.id +"__text_newsDescription_" + cleanUpID(this.props.data.title)} variant="smaller" className="myOdu__lineClamp-2">{description}</Typography>;
        } else {
            return <Typography id={this.props.id + "__link_moreInfo_" + cleanUpID(this.props.data.title)} variant="smaller" className="myOdu__lineClamp-2">For more information see the <a id={"myNews__link_moreInfoLink_" + cleanUpID(this.props.data.title)} href={link} target='_blank'>ODU news</a> webpage</Typography>;
        }
    }
    
    render() {
        return (this.props.data.description && <ListItem id={this.props.id +"__listItem_newsItem_" + cleanUpID(this.props.data.title)} sx={{ p: 1 }} key={this.props.widgetId + "_ul_li_" + this.props.index} divider>
                <Stack id={this.props.id +"__stack_newsItem_wrapper_" + cleanUpID(this.props.data.title)} direction="row">
                    <DateBox id={this.props.id +"__dateBox_" + cleanUpID(this.props.data.title)} date={this.props.data.date} />
                    <Box id={this.props.id +"__box_newsItem_" + cleanUpID(this.props.data.title)}>
                        <Stack id={this.props.id +"__stack_newsItem_" + cleanUpID(this.props.data.title)}>
                            {this.props.data.title && 
                            <PortalTooltip id={this.props.id +"__tooltip_newsItem_" + cleanUpID(this.props.data.title)}>
                                <Link id={this.props.id +"__link_newsItem_" + cleanUpID(this.props.data.title)} href={this.props.data.url} target="_blank" underline="hover" className="myOdu__lineClamp-1">
                                    <Typography id={this.props.id +"__text_newsItem_" + cleanUpID(this.props.data.title)} component="h3" variant="p" sx={{ fontWeight: 600 }}>{this.props.data.title}</Typography>
                                </Link>
                            </PortalTooltip>
                            }
                            {this.getDescription(this.props.data.description, this.props.data.url)}
                        </Stack>
                    </Box>
                </Stack>
        </ListItem>)
    }
}

export default NewsItem;
