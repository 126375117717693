import {
    Box,
    Typography,
    Link,
    Button,
    Tooltip,
    Stack,
    TextField,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Tabs, Tab,
    InputLabel,
    Select, MenuItem
} from '@mui/material';
import React, {Component} from 'react';
import {Container, Row, Col} from 'reactstrap';
import {DataColumns, DataRows} from './dummyData';
import PortalDataGrid from '../common/PortalDataGrid';
import ActionCheckbox from '../common/ActionCheckbox';
import PortalModal from '../common/PortalModal';
import {BreakpointBoxes} from '../common/BreakpointBoxes';
import {toast} from 'react-toastify';
import PortalToast from '../common/PortalToast';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHeart, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import DragDrop from './DragDrop';
import CctComingSoonModal from '../MyCourses/CctComingSoonModal';
import PortalTooltip from '../common/PortalTooltip';
import {DemoMuiFormComponents} from './DemoMUIFormComponents';
import WithRouter from '../Layout/Router/WithRouter';
import InvalidRoute from '../Layout/Router/InvalidRoute';
import Unauthorized from '../Layout/Router/Unauthorized';
import GenericError from '../Layout/Router/GenericError';
import ColorScheme from './ColorScheme';
import PortalPageLoader from '../common/PortalPageLoader';
import PortalAlert from '../common/PortalAlert';
import PortalTime from "../common/PortalTime";
import { faFaceSadCry } from '@fortawesome/pro-light-svg-icons';
import CCT from './CCT';
// import {BrowserView, MobileView} from 'react-device-detect';


class DevSpace extends Component {
    state = {
        isModalOpen: false,
        isCctModalOpen: false,
        isExpand: false
    };

    toggleAccordion = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    notifySuccess = () => {
        toast.success('Contact for KEENAN, JENNIFER added');
    };

    notifyError = () => {
        toast.error('Oh No! Errors!!!!');
    };

    notifyLongMessage = () => {
        toast.error(this.getLongMessage());
    };

    toggleIsModalOpen = () => {
        this.setState({isModalOpen: !this.state.isModalOpen});
    };

    toggleCctIsModalOpen = () => {
        this.setState({isCctModalOpen: !this.state.isCctModalOpen});
    };

    handlePrimaryClick = () => {
        console.log('primary button clicked');
    };

    handleSecondaryClick = () => {
        console.log('secondary button clicked');
    };

    getLongMessage = () => {
        return (
            <>
                <Stack>
                    <Typography component="h3">OH NO!</Typography>
                    <Typography component="p">Lorem Ispum Dolor</Typography>
                    <ul>
                        <li>blah</li>
                        <li>blah</li>
                        <li>blah</li>
                        <li>blah</li>
                    </ul>
                </Stack>
            </>
        );
    };

    render() {
        return (
            <React.Fragment>
                <BreakpointBoxes />
                {/* <BrowserView>
                    <h1>This is rendered only in browser</h1>
                </BrowserView>
                <MobileView>
                    <h1>This is rendered only on mobile</h1>
                </MobileView> */}
                
                <Container>
                    {/* THESE WORK */}
                    <Row>
                        <Col>
                            <Stack direction="row" spacing={2}>
                                <PortalTooltip title="test">Tooltip Test</PortalTooltip>
                                <PortalTime time="8:00 AM" id="example1"/>
                                <PortalTime id="example2">11:00 PM using children</PortalTime>
                                <PortalTime time="7:37 AM" title="using time and title props" id="example3" />
                            </Stack>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="align-left">
                            <PortalTime><InputLabel id="demo-simple-select-label" className="myOdu__label">Portal Time on Label</InputLabel></PortalTime>
                        </Col>
                        <Col>
                            <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Time1"
                                    value="10:00 AM"
                                    size="small"
                                    >
                                    <MenuItem value="10:00 AM">10:00 AM</MenuItem>
                                    <MenuItem value="10:15 AM">10:15 AM</MenuItem>
                                    <MenuItem value="10:30 AM">10:30 AM</MenuItem>
                                    <MenuItem value="10:45 AM">10:45 AM</MenuItem>
                                </Select>
                        </Col>
                    </Row>
                    {/* DONT USE IT THIS WAY */}
                    <Row>
                        <Col>
                            <FontAwesomeIcon icon={faFaceSadCry}/> This doesnt work
                            <InputLabel id="demo-simple-select-label" className="myOdu__label">Portal Time on Dropdown Value</InputLabel>
                        </Col>
                        <Col>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Time2"
                                    value="10:00 AM"
                                    size="small"
                                    >
                                    <MenuItem value="10:00 AM"><PortalTime>10:00 AM</PortalTime></MenuItem>
                                    <MenuItem value="10:15 AM"><PortalTime time="10:15 AM"/></MenuItem>
                                    <MenuItem value="10:30 AM">10:30 AM</MenuItem>
                                    <MenuItem value="10:45 AM">10:45 AM</MenuItem>
                                </Select>
                        </Col>
                    </Row>
                </Container>
                


                <br/>
                <br/>
                <br/>
                <br/>
                <Box className="myOdu__cct">
                    <Stack className="cctHeader" direction="row" alignItems={"center"}>
                        <Typography component="h2" variant="h2">CS 101</Typography>
                        <Stack>
                            <Typography component="h3" variant="h3">Introduction to Cats and Kittens</Typography>
                            <Typography className="schedule">Monday, Wednesday and Friday <PortalTime variant="smaller">10:00 AM - 10:50 AM</PortalTime></Typography>
                            <Typography className="crns">12345, 13456, 13213 and 13246</Typography>
                        </Stack>
                    </Stack>
                    <Tabs
                        value={this.state.activeTab}
                        onChange={this.onTabChange}
                        aria-label="Collaboration Tools"
                        variant="fullWidth"
                        className="myOdu__tabs secondary"
                    >
                        <Tab value={1} label="Zoom" />
                        <Tab value={2} label="Google Drive" />
                        <Tab value={3} label="Teams" />
                        <Tab value={4} label="Shared Drive" />
                    </Tabs>
                    <Box className="cctBody">
                        <Typography component="h2" variant="h2">Zoom Meeting</Typography>
                        <Button className="myOdu__button primary cctScheduleMeeting" variant="outlined" >Schedule Meeting</Button>
                    </Box>

                </Box>

                {/* <ColorScheme /> */}
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <Typography component="h2" variant="h4">
                    Component Demo Here
                </Typography>
                
                {/* <PortalPageLoader /> */}

                <PortalAlert
                    // severity="success"
                    icon="/img/logos/laundry.svg" 
                    alt="Laundry"
                    title="All Systems Operational"
                    // subTitle="March 3, 2023 10:00 AM"
                    titleLink="http://google.com"
                >
                    Lorem Ipsum dolor Lorem Ipsum dolor Lorem Ipsum dolor Lorem Ipsum dolor Lorem Ipsum dolor Lorem Ipsum dolor Lorem Ipsum dolor Lorem Ipsum dolor 
                </PortalAlert>



                <GenericError />

                <PortalToast severity="error" />
                <CctComingSoonModal
                    isOpen={this.state.isCctModalOpen}
                    onClose={this.toggleCctIsModalOpen}
                />

                <Container className="myOdu__container_maxWidth">
                    {/* <PortalDataGrid 
						checkboxSelection                
						sx={{border: 0}}
						className={this.props.className}
						rows={DataRows}
						columns={DataColumns}
						handleClick = {this.props.onClick}
					/> */}
                    <Box className="myOdu__box">
                        <Stack>
                            {/* //Interesting issue here. If not in div, the whole row in the stack will act as the tooltip */}
                            {/* //Run code below as sample */}
                            <Link
                                onClick={this.toggleCctIsModalOpen}
                                sx={{cursor: 'pointer'}}
                            >
                                CCT Is Coming Soon Modal
                            </Link>
                            <div>
                                <Tooltip
                                    id="tooltip1"
                                    title="Sample Tool Tip Here"
                                    enterDelay={500}
                                    leaveDelay={200}
                                    nextEnterDelay={500}
                                    arrow
                                >
                                    Sample Tooltip 1 Here
                                </Tooltip>
                            </div>
                            <PortalTooltip id="id">
                                Second Tooltip Here to Test Delay on #2
                            </PortalTooltip>
                        </Stack>
                    </Box>
                    <Button
                        onClick={this.toggleIsModalOpen}
                        variant="outlined"
                        className="myOdu__button"
                        sx={{mr: 1}}
                    >
                        Open Modal
                    </Button>
                    <Button
                        variant="outlined"
                        className="myOdu__button disabled"
                        disabled
                        sx={{mr: 1}}
                    >
                        Button Disabled
                    </Button>
                    <Button
                        onClick={this.notifySuccess}
                        variant="outlined"
                        className="myOdu__button primary"
                        sx={{mr: 1}}
                    >
                        Toast Success
                    </Button>
                    <Button
                        variant="outlined"
                        className="myOdu__button primary disabled"
                        disabled
                        sx={{mr: 1}}
                    >
                        Primary Disabled
                    </Button>
                    <Button
                        onClick={this.notifyError}
                        variant="outlined"
                        className="myOdu__button secondary"
                        sx={{mr: 1}}
                    >
                        Toast Error
                    </Button>
                    <Button
                        variant="outlined"
                        className="myOdu__button secondary disabled"
                        disabled
                        sx={{mr: 1}}
                    >
                        Secondary Disabled
                    </Button>
                    <Button
                        onClick={this.notifyLongMessage}
                        variant="outlined"
                        className="myOdu__button text"
                    >
                        Toast Error - Long Message
                    </Button>
                    <Button
                        variant="outlined"
                        className="myOdu__button text disabled"
                        disabled
                    >
                        Text Button Disabled
                    </Button>
                    This is my favorite{' '}
                    <FontAwesomeIcon
                        icon={faHeart}
                        className="myOdu__favorite"
                    />
                    <ActionCheckbox label="Select Users" id="usersSelectAll" />
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="placeholder"
                        sx={{m: 1}}
                        // error
                        errorText="error"
                        helperText="helper"
                        // autoFocus
                    />
                    <Accordion
                        expanded={this.state.isExpand}
                        elevation={0}
                        className="myOdu__accordion"
                        sx={{border: '1px solid red'}}
                    >
                        <AccordionSummary
                            expandIcon={
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    onClick={() => this.toggleAccordion()}
                                    fixedWidth
                                />
                            }
                            aria-controls="acc1"
                            sx={{
                                flexDirection: 'row-reverse',
                                flexGrow: 1,
                                p: 0,
                                mb: 2,
                                border: '1px solid blue'
                            }}
                        >
                            <Typography
                                component="h3"
                                variant="h6"
                                sx={{ml: 1}}
                                className="myOdu__widget_title"
                                onClick={() => this.toggleAccordion()}
                            >
                                Title Here
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            id={this.state.widgetId + '_odu_accordionDetails'}
                            sx={{p: 0, border: '1px solid green'}}
                        >
                            Details Here
                        </AccordionDetails>
                    </Accordion>
                    <Container className="mt-2">
                        <Row>
                            <Col
                                sm={11}
                                md={6}
                                lg={10}
                                xl={4}
                                xxl={3}
                                className="col-4xl-1 col-xxxl-2 myOdu__border debug"
                            >
                                Test col1 sm=11 md=6 lg=10 xl=4 xxl=3 4xl=1
                                xxxl=2{' '}
                            </Col>
                            <Col
                                sm={1}
                                md={6}
                                lg={2}
                                xl={8}
                                xxl={9}
                                className="col-4xl-11 col-xxxl-10 myOdu__border debug"
                            >
                                Test col2{' '}
                            </Col>
                        </Row>
                    </Container>
                    <PortalModal
                        // severity="success imperative"  //no idea why someone would use this
                        // severity="error imperative"  //ONLY in extreme cases, run this by UX dev before using
                        // severity="success"
                        // noHeader
                        //  severity="error"
                        maxWidth="sm"
                        onClose={this.toggleIsModalOpen}
                        onPrimaryClick={this.handlePrimaryClick}
                        onSecondaryClick={this.handleSecondaryClick}
                        isOpen={this.state.isModalOpen}
                        title="Test Modal Title"
                        // subTitle="Test Modal Subtitle"
                        id="testModalButton"
                        primaryButtonTitle="Add"
                        secondaryButtonTitle="Secondary Button with very long title to test wrapping and layout design"
                        isCloseable={true}
                        // noHeader
                    >
                        <Typography variant="h5" component="h3">
                            H3 Nothing within a modal should be bigger than an
                            H3.
                        </Typography>
                        Exercitation amet amet esse ut elit. Sint occaecat
                        pariatur proident exercitation veniam cillum ea.
                    </PortalModal>
                    <Link
                        sx={{cursor: 'pointer'}}
                        onClick={() => this.props.navigate('Crud/')}
                    >
                        <Typography>CRUD Styled</Typography>
                    </Link>
                    <Box className="myOdu__box error">
                        <h3>Form Demo Here</h3>
                        <DemoMuiFormComponents />
                    </Box>
                    <DragDrop />
                    <Box>
                        <Typography>CCT</Typography>
                        <CCT />
                    </Box>
                </Container>
            </React.Fragment>
        );
    }
}

export default  WithRouter(DevSpace);
