import { Autocomplete, Chip, TextField, Typography } from "@mui/material";
import React, {Component} from "react";
import { isValidEmail, isValidEmails } from "../MyCourses/CCT/Body/Zoom/Meetings/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faCircleXmark } from "@fortawesome/pro-light-svg-icons";
import PortalTooltip from "./PortalTooltip";

class PortalEmailsInput extends Component {
    state = {

    }

    //Θ(1) Updates the altHosts array
    onHostsChange = (_, newValue, reason) => {
        if(reason === 'removeOption') {
            this.props.onChangeAltHosts(newValue);
            this.props.onChangeIsError(!isValidEmails(newValue));
            return;
        }
        if(!isValidEmail(newValue[newValue.length - 1])) {
            this.props.onChangeInputValue(newValue[newValue.length - 1]);
            this.props.onChangeIsError(true);
            return;
        }

        this.props.onChangeAltHosts(newValue);
        this.props.onChangeIsError(false);
    }

    //Θ(1) On press of space it updates the altHosts array
    handleKeyDown = event => {
        if(event.target.value.trim().length === 0)
            return;
        switch (event.key) {
          case ",":
          case " ": {
            event.preventDefault();
            event.stopPropagation();
            if (event.target.value.length > 0) {
                this.props.onChangeAltHosts([...this.props.altHosts, event.target.value]);
                this.props.onChangeInputValue('');

                let isError = !isValidEmails([...this.props.altHosts, event.target.value]);
                this.props.onChangeIsError(isError);
            }

            break;
          }
          default:
        }
    };

    //Θ(N) where N is the number of emails
    //Updates the state of input
    onInputChange = (event, newInput) => {
        let value = event.target.value
        let tokens = value.split(/[\s, \n]+/)

        let isError = false;

        if(tokens.length > 1) {
            let distinct = new Set()
            for(const token of tokens) {
                try {
                    distinct.add(token)
                }
                catch {
                }
            }
            let emails = Array.from(distinct);

            isError = !isValidEmails([...this.props.altHosts, ...emails])

            this.props.onChangeAltHosts([...this.props.altHosts, ...emails]);
            this.props.onChangeIsError(isError);

            return;
        }

        isError = newInput.includes('@') && !isValidEmail(newInput) && !isValidEmails([...this.props.altHosts, this.props.inputValue]);

        this.props.onChangeInputValue(newInput);
        this.props.onChangeIsError(isError);
    }

    //Θ(N) where N is the length of altHosts
    //Removes the email form the list
    handleChipClick = (item) => {
        let altHosts = this.props.altHosts.filter(host => host !== item)
        this.props.onChangeInputValue(item);
        this.props.onChangeAltHosts(altHosts);
    }

    render() {
        return <React.Fragment>
            <Autocomplete 
                clearIcon = {true}
                options={[]}
                freeSolo
                multiple
                value={this.props.altHosts}
                inputValue={this.props.inputValue}
                renderTags={(value, props) => {
                    return value.filter(option => option !== '').map((option, index) => {
                        return <Chip variant="outlined" icon={!isValidEmail(option) && <FontAwesomeIcon icon={faCircleExclamation} />} color={isValidEmail(option) ? 'default' : 'error'} onClick = {() => this.handleChipClick(option)} label={option} {...props({ index })} 
                            deleteIcon={
                                <PortalTooltip id={option + '_remove'} title="Remove from alt host list">
                                    <FontAwesomeIcon icon={faCircleXmark} style={{color: 'black'}} />
                                </PortalTooltip>
                            }
                        />
                    })
                }}
                onChange={this.onHostsChange}
                renderInput={(params) => {
                    params.inputProps.onKeyDown = this.handleKeyDown
                    return <TextField
                        label=""
                        variant="outlined"
                        {...params} 
                        placeholder="Add ODU Email Address"
                        className="myOdu__hideFloatLabel"
                    />
                }}
                onInputChange={this.onInputChange}
            />
            {this.props.isError ? (
                <Typography
                    display="flex"
                    justifyContent="flex-start"
                    variant="caption"
                    className="myOdu__error"
                >
                    Alternative hosts can only use an ODU email address
                </Typography>
                ) : (
                <Typography
                    display="flex"
                    justifyContent="flex-start"
                    variant="caption"
                    className="myOdu__helper"
                >
                    Only ODU users can be set as alternative hosts
                </Typography>
                )}
        </React.Fragment>
    }
}

export default PortalEmailsInput;