import React, { Component } from "react";
import Widget from "../../common/Widget";
import { Typography } from "@mui/material";
import AppCard from "./AppCard";
import { DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

class Section extends Component {
    state ={ 

    }

    dragStart = (result) => {
    }

    render() {
        return <React.Fragment>
            {
                <div style={ this.props.selectedSection > 0 && this.props.selectedSection !== this.props.sectionNumber ? {opacity: 0.5, cursor: 'no-drop'} : {}}>
                    <DragDropContext onDragEnd={this.props.dragEnd} onDragStart={this.props.dragStart} onBeforeCapture={this.props.beforeCapture}>
                        <Droppable droppableId={this.props.sectionNumber}>
                            {
                                (provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {
                                        this.props.Widgets.map((widget, idx) => {
                                            {/* return <AppCard widget = {widget} idx = {idx} /> */}
                                            return (
                                                <Draggable key={widget} draggableId={widget} index={idx}>
                                                    {
                                                        (provided) => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps}>
                                                                <AppCard widget = {widget} selectedItem={this.props.selectedItem} isDragging = {this.props.isDragging} provided={provided}/>
                                                            </div>                                                
                                                        )
                                                    }
                                                </Draggable>
                                            )
                                        })
                                    }
                                    {provided.placeholder}
                                </div>
                            )
                            }
                        </Droppable>
                    </DragDropContext>
                </div>
            }
        </React.Fragment>
    }
}

export default Section;