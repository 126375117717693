import { Typography } from '@mui/material';
import React, { Component } from 'react';
import Widgets from './Widgets';
import {Container} from 'reactstrap';

class Settings extends Component {
    state = {

    }

    render() {
        return <React.Fragment>
            <Container className="myOdu__container_maxWidth" style={{marginTop: '2rem'}}>
                <Typography variant='h3' component='h2' sx={{color: 'primary.main'}} >Settings</Typography>
                <Typography variant='p' component='p' sx={{mb:2}}>{'Dashboard / Settings'}</Typography>
                <Widgets />
            </Container>
        </React.Fragment>
    }
}

export default Settings;