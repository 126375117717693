import { Card, Typography } from "@mui/material";
import React, {Component} from "react";
import WidgetCard from "./WidgetCard";
import { DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

class Section extends Component {
    state ={

    }

    getStyle = (style) => {
        return {
            ...style,
            cursor: this.props.isDroppable ? 'no-drop' : ''
        }
    }

    render() {

        //TODO TO be optimized
        const fixedWidgets = this.props.widgetsOrder?.map(widgetId => {
            let widget = this.props.widgets.find(widget => {
                if(widget.id === widgetId && widget.isNoDrag)
                    return widget;
            });
            if(widget) {
                return widget
            }
        }).filter(widget => widget !== undefined);
        const orderedWidgets = this.props.widgetsOrder?.map(widgetId => {
            let widget = this.props.widgets.find(widget => {
                if(widget.id === widgetId && !widget.isNoDrag)
                    return widget;
            });
            if(widget) {
                return widget
            }
        }).filter(widget => widget !== undefined);

        return <React.Fragment>
            <div style={ this.props.isDroppable ? {opacity: 0.5, cursor: 'no-drop'} : {}}>
                <Card sx={{ p:1, height: '100%'}} className={'myOdu__appCard'} elevation={0}>
                    <Typography variant="h4" component='h3'>Section {this.props.sectionNumber}</Typography>
                    <div style={ Number(this.props.selectedSection) > 0 ? {opacity: 0.5, cursor: 'no-drop'} : {}} className="p-0 m-0">
                        {
                            fixedWidgets.map(widget => {
                                return <WidgetCard widget = {widget} isDragging = {false} provided={{}} hideUp hideDown/>
                            })
                        }
                    </div>
                    <DragDropContext onDragEnd={this.props.dragEnd} onDragStart={this.props.dragStart}>
                        <Droppable droppableId={this.props.sectionNumber.toString()}>
                            {
                                (provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {
                                            orderedWidgets.map((widget, idx) => {
                                                return (
                                                    <Draggable key={widget?.id} draggableId={widget?.id} index={fixedWidgets.length + idx}>
                                                        {
                                                            (provided) => {
                                                                return <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={this.getStyle(provided.draggableProps.style)}>
                                                                    <WidgetCard widget = {widget} sectionNumber={this.props.sectionNumber} moveWidget = {this.props.moveWidget} hideUp = {idx === 0} hideDown = {idx === orderedWidgets.length -1} index={fixedWidgets.length + idx}/>
                                                                </div>                                                
                                                            }
                                                        }
                                                    </Draggable>
                                                )
                                            })
                                        }
                                        {provided.placeholder}
                                    </div>
                                )
                            }
                        </Droppable>
                    </DragDropContext>
                </Card>
            </div>
        </React.Fragment>
    }
}

export default Section;