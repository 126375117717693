import React, { Component } from 'react';
import { Stack, Typography, Link } from '@mui/material';
import { cleanUpID } from '../common/utils';

export function SafetyContacts (props){
    return(
       <div id={props.id+"__div_wrapper_"+ props.data.id}>
            <Stack id={props.id+"__stack_contactWrapper_" + props.data.id } direction={{md:"row", sm:"row", xs:"row"}} className="mb-2 mb-sm-0" justifyContent={"space-between"} key={props.data.id}>
                <Typography id={props.id+"__text_title_" + props.data.id} variant="small">{props.data.title}</Typography>
                <Link id={props.id+"__link_phone_" + props.data.id+"_" +cleanUpID(props.data.phoneNumber)} href={"tel:" + props.data.phoneNumber} variant="small" underline='hover'>{props.data.phoneNumber}</Link>
            </Stack>
        </div>
    );
}

