import React, { Component } from "react";
import { Tabs, Tab, Box, Link, Typography } from "@mui/material";
import TabPanel from "../common/TabPanel";
import AnnouncementList from "./AnnouncementList.js";

class AnnouncementTabs extends Component {
    render() {
        return (
            <div id={this.props.id +"__div_wrapper"}>
                <Box id={this.props.id +"__box_tabsWrapperWrapper"} sx={{ width: "100%", pb: 1 }}>
                    <Box id={this.props.id +"__box_tabsWrapper"} sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            id={this.props.id + "__tabs_announcementTypes"}
                            value={this.props.tabIndex}
                            onChange={this.props.onTabChange}
                            aria-label="Announcements Tabs"
                        >
                            <Tab
                                label="Student"
                                id={this.props.id +"__tab_student"}
                                aria-controls={
                                    "announcements__tab_student"
                                }
                            />
                            <Tab
                                label="Faculty/Staff"
                                id={this.props.id +"__tab_university"}
                                aria-controls={
                                    "announcements__tab_university"
                                }
                            />
                            {this.props.isEvms && (
                                <Tab
                                    label="VHS"
                                    id="announcements__tab_evms"
                                    aria-controls={
                                        "announcements__tab_evms"
                                    }
                                />
                            )}
                        </Tabs>
                    </Box>
                </Box>

                <TabPanel
                    value={this.props.tabIndex}
                    sx={{ pt: 1, px: 0 }}
                    index={0}
                    id={this.props.id + "__tabpanel_student"}
                    lablledby="announcements__tab_student"
                >
                    <Box id={this.props.id + "__box_studentAnnouncements"}>
                        <p id={this.props.id +"__text_student_itsHelpDesk"} style={{ lineHeight: "1.25", fontSize: ".8rem" }}>
                            Questions regarding specific announcements can be
                            directed to the point of contact in that
                            announcement. For questions about the announcement
                            system, contact the &nbsp;
                            <Link
                                id={this.props.id +"__link_student_itsHelpDesk"}
                                href="http://www.odu.edu/helpdesk"
                                target="_blank"
                            >
                                ITS Help Desk.
                            </Link>
                        </p>
                        {this.props.studentAnnouncements?.title && 
                        <Typography variant="h5" component="h3" sx={{ pb: 1 }} id={this.props.id + "__header_studentAnnouncement"}>
                            {this.props.studentAnnouncements?.title}
                        </Typography>}
                    </Box>
                    <AnnouncementList
                        id={this.props.id + "__announcementList_student"}
                        list={this.props.studentAnnouncements?.list}
                        tab={"student"}
                    />
                </TabPanel>

                <TabPanel
                    value={this.props.tabIndex}
                    sx={{ pt: 1, px: 0 }}
                    index={1}
                    id={"announcements__tabpanel_university"}
                    lablledby="announcements__tab_university"
                >
                    <Box id={this.props.id +"__box_universityAnnouncements"}>
                        <p id={this.props.id +"__text_universityITSHelpDesk"} style={{ lineHeight: "1.25", fontSize: ".8rem" }}>
                            Questions regarding specific announcements can be
                            directed to the point of contact in that
                            announcement. For questions about the announcement
                            system, contact the &nbsp;
                            <Link
                                id={this.props.id +"__link_facStaff_itsHelpDesk"}
                                href="http://www.odu.edu/helpdesk"
                                target="_blank"
                            >
                                ITS Help Desk.
                            </Link>
                        </p>
                        {this.props.universityAnnouncements?.title && <Typography variant="h5" component="h3" sx={{ pb: 1 }} id={this.props.id + "__header_studentAnnouncement"}>
                            {this.props.universityAnnouncements?.title}
                        </Typography>}
                    </Box>
                    <AnnouncementList
                        id={this.props.id + "__announcementList__university"}
                        list={this.props.universityAnnouncements?.list}
                        tab={"university"}
                    />
                </TabPanel>

                {this.props.isEvhsc && (
                    <TabPanel
                        value={this.props.tabIndex}
                        sx={{ pt: 1, px: 0 }}
                        index={2}
                        id={this.props.id + "__announcements__tabpanel_evhsc"}
                        lablledby="announcements__tab_evhsc"
                    >
                        VHS
                    </TabPanel>
                )}
            </div>
        );
    }
}

export default AnnouncementTabs;
