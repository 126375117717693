import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Badge,
    Typography,
    Breadcrumbs,
    Link,
    List,
    ListItem,
    Skeleton
} from '@mui/material';
import {Container} from 'reactstrap';
import PortalTooltip from '../common/PortalTooltip';
import {saveChecklists, saveChecklistsIsError} from './actions';
import {checklists} from '../../DataAccessLayer/services';
import {getData} from '../../DataAccessLayer';
import PortalAlert from '../common/PortalAlert';
import ApiErrorMessage from '../common/ApiErrorMessage';
import WithRouter from '../Layout/Router/WithRouter';

class Checklists extends Component {
    state = {
        isLoading: false
    };

    componentDidMount() {
        if (!this.props.checklists && !this.props.isError) {
            this.toggleIsLoading(true);
        }
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.checklists !== this.props.checklists &&
                this.props.checklists) ||
            (prevProps.isError !== this.props.isError && this.props.isError)
        ) {
            this.toggleIsLoading(false);
        }
    }

    toggleIsLoading = isLoading => {
        this.setState({isLoading});
    };

    loadChecklists = () => {
        this.toggleIsLoading(true);
        this.props.saveChecklistsIsError(false);
        getData(checklists, true)
            .then(checklists => {
                this.props.saveChecklists(checklists?.checklists); // Also marks Checklists isError to false in the redux
            })
            .catch(err => {
                console.log(err);
                this.props.saveChecklistsIsError(true);
            })
            .finally(() => this.toggleIsLoading(false));
    };

    render() {
        return (
            <>
                <Container
                    className="myOdu__container_maxWidth"
                    style={{marginTop: '2rem'}}
                >
                    <Typography
                        sx={{color: 'primary.main'}}
                        component="h2"
                        variant="h3"
                    >
                        My Checklists
                    </Typography>

                    <Breadcrumbs aria-label="breadcrumb" sx={{mb: 2}}>
                        <Link
                            underline="hover"
                            color="inherit"
                            href="/"
                            sx={{cursor: 'pointer'}}
                        >
                            Dashboard
                        </Link>
                        <Typography color="text.primary">Checklists</Typography>
                    </Breadcrumbs>
                    {!this.state.isLoading && this.props.isError && (
                        <PortalAlert severity="error" type="checklist">
                            <ApiErrorMessage
                                reload={this.loadChecklists}
                                widgetName="Checklists"
                                isPage={true}
                            />
                        </PortalAlert>
                    )}
                    {this.state.isLoading && <Skeleton variant="rectangular" />}
                    {!this.state.isLoading &&
                        !this.props.isError &&
                        this.props.checklists?.length > 0 && (
                            <List sx={{pt: 0, pl: 2, listStyleType: 'disc'}}>
                                {this.props.checklists.map(checklist => {
                                    const code = checklist.checklist_time_code;
                                    return (
                                        <ListItem
                                            key={code + '_listitem'}
                                            sx={{
                                                p: 0,
                                                display: 'list-item'
                                            }}
                                        >
                                            <Link
                                                variant="small"
                                                onClick={() => {
                                                    this.props.navigate(
                                                        '/checklists/' + code,
                                                        {
                                                            state: {
                                                                name: checklist.checklist_desc
                                                            }
                                                        }
                                                    );
                                                }}
                                                underline="always"
                                                color="inherit"
                                                id={'checklist__link_' + code}
                                                sx={{
                                                    mr: 1,
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {checklist.checklist_desc}
                                            </Link>
                                            <PortalTooltip
                                                title={`${checklist.checklist_items_completed} items checked`}
                                            >
                                                <Badge
                                                    badgeContent={
                                                        checklist.checklist_items_completed
                                                    }
                                                    max={10}
                                                    sx={{cursor: 'pointer'}}
                                                    className="myOdu__badge checklistBadge"
                                                />
                                            </PortalTooltip>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )}
                    {!this.state.isLoading &&
                        this.props.checklists?.length === 0 && (
                            <Typography component="p">
                                No checklist found.
                            </Typography>
                        )}
                </Container>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        checklists: state.myChecklistsReducer.checklists,
        isError: state.myChecklistsReducer.isError
    };
};

const mapDispatchToProps = dispatch => ({
    saveChecklists: checklists => dispatch(saveChecklists(checklists)),
    saveChecklistsIsError: isError => dispatch(saveChecklistsIsError(isError))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WithRouter(Checklists));
