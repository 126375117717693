import React from 'react';

import {Grid, Typography, Container, Box} from '@mui/material';
import PortalTooltip from '../common/PortalTooltip';

export const LegalNameSection = ({data}) => {
  return (
    <Container sx={{mt: 4}}>
      <Typography component="h3" variant="h4">
        Legal Name
      </Typography>
      <Typography variant="body1">
        A student's legal name will be used where{' '}
        <a
          href="https://www.odu.edu/academics/academic-records/preferred-name#tab1771972802753=1&amp;done1612907281342"
          target="_blank"
          id="myInfo__link_requiredByLaw"
        >
          required by law or the University
        </a>
        .
      </Typography>

      <Box
        sx={{
          mt: 1,
          mb: 1,
          backgroundColor: '#F5F5F5',
          padding: 1
        }}
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={3}
            lg={4}
            xl={3}
            textAlign={{xs: 'start', md: 'end', lg: 'end', xl: 'end'}}
          >
            <Typography className="myOdu__label" component="label">
              First
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={8} xl={9}>
            <Typography variant="body1">{data.firstName}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={4}
            xl={3}
            textAlign={{xs: 'start', md: 'end', lg: 'end', xl: 'end'}}
          >
            <Typography className="myOdu__label" component="label">
              Middle
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={8} xl={9}>
              {data.middleName ? 
                <Typography variant="body1">{data.middleName}</Typography>
                :
                <PortalTooltip title="No Middle Name Found">
                  <Typography variant="body1" className="myOdu__optional cursor-pointer" >-</Typography>
                </PortalTooltip>
              }
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={4}
            xl={3}
            textAlign={{xs: 'start', md: 'end', lg: 'end', xl: 'end'}}
          >
            <Typography className="myOdu__label" component="label">
              Last
            </Typography>
          </Grid>
          <Grid item xs={12} md={9} lg={8} xl={9}>
            <Typography variant="body1">{data.lastName}</Typography>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
