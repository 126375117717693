import React, {Component} from 'react';
import Widget from '../common/Widget';

import {Link, Box, Typography, Alert, Grid} from '@mui/material';
import {makeTitle, getClickableIcon, makePlainTitle} from './utils';
import PortalTooltip from '../common/PortalTooltip';

class ScheduleItem extends Component {
    render() {
        return (
            <Grid
                id={this.props.id + '__grid'}
                key={this.props.id + '__grid'}
                alignItems={'stretch'}
            >
                {/* <Box sx={{width: '100%', height:'100%'}}> */}
                <Alert
                    id={this.props.id + '__scheduleItemCard'}
                    className="itemBox"
                    variant="outlined"
                    severity="info"
                    sx={{
                        border: '1px solid #EAEBEB',
                        height: '100%',
                        mr: 2
                    }}
                    icon={getClickableIcon({
                        id: this.props.id + '__icon',
                        isSoon: this.props.item.isSoon,
                        isOnline: this.props.item.isOnline,
                        locationUrl: this.props.item.locationUrl
                    })}
                >
                    <Typography
                        component="p"
                        variant="h6"
                        id={this.props.id + '__header_dateTime'}
                    >
                        <span id={this.props.id + '__header_dateTimeWrapper'}>
                            {this.props.item.formattedDateTime}
                        </span>
                    </Typography>

                    <PortalTooltip
                        id={this.props.id + '__tooltip_title'}
                        title={makePlainTitle(
                            this.props.item.courseSubjectNumber,
                            this.props.item.courseTitle
                        )}
                    >
                        {makeTitle(
                            this.props.item.courseSubjectNumber,
                            this.props.item.courseTitle,
                            this.props.id + '__title'
                        )}
                    </PortalTooltip>

                    <Typography
                        component="p"
                        variant="smaller"
                        id={this.props.id + '__p_location'}
                    >
                        {this.props.item.locationUrl ? (
                            <PortalTooltip
                                id={this.props.id + '__tooltip_location'}
                                title={
                                    this.props.item.isOnline
                                        ? 'Launch Zoom'
                                        : 'Open in new window'
                                }
                            >
                                <Link
                                    id={this.props.id + '__link_location'}
                                    href={this.props.item.locationUrl}
                                    target="_blank"
                                    underline="always"
                                >
                                    {this.props.item.location}
                                </Link>
                            </PortalTooltip>
                        ) : !this.props.item.isOnline ? (
                            // Sometimes there are duplicated locations, and we want to filter out the duplicated item.
                            Array.from(
                                new Set(this.props.item.location.split('\n'))
                            ).map(location => (
                                <div id={this.props.id + '__text_location'}>
                                    {' '}
                                    {location}{' '}
                                </div>
                            ))
                        ) : (
                            <span id={this.props.id + '__text_location_online'}>
                                Online
                            </span>
                        )}
                    </Typography>
                </Alert>
                {/* </Box> */}
            </Grid>
        );
    }
}

export default ScheduleItem;
