import React from 'react';

import {Typography, Container, Box} from '@mui/material';

import {campusLocations} from '../common/Form/FormOptions';
import {FormControlledDropdown} from '../common/Form/FormDropdown';

export const CampusServiceSection = ({control}) => {
  return (
    <Container component="h3" sx={{mt: 4}}>
      <Typography variant="h4">Campus Service Location</Typography>
      <Typography variant="body1">
        Location for which campus services will be received.
      </Typography>

      <Box sx={{flexGrow: 1, mt: 3, mb: 3}}>
        <FormControlledDropdown
          name="accessServiceAt"
          label="Location"
          control={control}
          options={campusLocations}
        />
      </Box>
    </Container>
  );
};
