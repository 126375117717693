import React, { Component } from 'react';
import {Accordion, AccordionSummary, AccordionDetails, IconButton,Typography, Box, Menu, MenuItem} from '@mui/material';
import { faChevronDown, faEllipsisVertical } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WidgetMenu from '../../common/WidgetMenu';

	
class Widget extends Component {
    
    state = {
        isExpand: true,
        active: 0,
        dirty: undefined
    }

    toggleExpand = (isExpand = !this.state.isExpand) => {
        if(isExpand !== this.state.isExpand)
            this.setState({isExpand: isExpand})
    }
    
    render() {
        if(this.props?.selectedItem === this.props.widget && this.state.isExpand !== false && this.props.isDragging){
            this.setState({
                dirty: this.state.isExpand
            })
            this.toggleExpand(false)
        } 
        else if(this.state.dirty !== undefined && this.props?.selectedItem === this.props.widget && !this.props.isDragging) {
            this.toggleExpand(true)
            this.setState({
                dirty: undefined
            })
        }
        return <React.Fragment>
            <Accordion expanded={this.state.isExpand} elevation={0} className={"myOdu__widget " + this.props.className}  id={this.props.data.id + "_accordion"}>
                <div {...this.props.provided.dragHandleProps}>
                    <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} size="lg" onClick={() => this.toggleExpand()} fixedWidth/>} id={this.props.data.id + "_accordionSummary"} aria-controls={this.props.data.id + "_accordionDetails"} sx={{flexDirection: "row-reverse", flexGrow: 1}}>
                        <Typography component="h2" variant="h4" sx={{color:'#003057', ml: 1 }} onClick={() => this.toggleExpand()}>
                        {this.props.data.title}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} onClick={() => this.toggleExpand()}/>
                        {this.props.data.menu && <>
                            <WidgetMenu data={{title: this.props.data.title, id: this.props.data.id}} menu={this.props.data.menu}/>
                        </>}
                    </AccordionSummary>
                </div>
                <AccordionDetails id={this.props.data.id + "_accordionDetails"} sx={{pt:0}}>
                    {this.props.children}
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    }
}
 
export default Widget;