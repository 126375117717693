import React, {useEffect, useState, useRef} from 'react';
import {
    Typography,
    Container,
    Stack,
    IconButton,
    Box,
    Grid,
    useMediaQuery
} from '@mui/material';
import {FormControlledPhoneField} from '../common/Form/FormPhoneField';
import {FormButton} from '../common/Form/FormButton';
import {useFieldArray, useWatch, useFormContext} from 'react-hook-form';
import {FormControlledCheckbox} from '../common/Form/FormCheckbox';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/pro-light-svg-icons';
import PortalModal from '../common/PortalModal';

export const UniversityAlertsSection = ({control}) => {
    const alertsConsent = useWatch({control, name: 'emergencyTextAlert'});
    const isInitialRender = useRef(true);
    const [unConsentConfirmation, setUnconsentConfirmation] = useState(false);
    const [deletePrimaryPhoneConfirmation, setDeletePrimaryPhoneConfirmation] =
        useState(false);

    const {setValue, getValues} = useFormContext();

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'emergencyAlertPhones'
    });

    const isSm = useMediaQuery(theme => theme.breakpoints.down('md'));
    const isXs = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const isXxs = useMediaQuery(theme => theme.breakpoints.only('xs'));

    useEffect(() => {
        if (!isInitialRender.current && !alertsConsent) {
            setUnconsentConfirmation(true);
        }
    }, [alertsConsent, remove]);

    useEffect(() => {
        // Set isInitialRender to false after the first render
        isInitialRender.current = false;
    }, []);

    const handleDeletePhone = index => {
        if (index === 0 && fields.length === 1) {
            // confirm dialog here
            setDeletePrimaryPhoneConfirmation(true);
        } else {
            remove(index);
        }
    };

    const handleConfirmationDialogClose = () => {
        setValue('emergencyTextAlert', true);
        setUnconsentConfirmation(false);
    };

    const handleConfirmationDialogConfirm = () => {
        remove();
        setUnconsentConfirmation(false);
    };

    const handleDeletePrimaryPhoneConfirmationDialogClose = () => {
        setDeletePrimaryPhoneConfirmation(false);
    };

    const handleDeletePrimaryPhoneConfirmationDialogConfirm = () => {
        remove(0);
        handleDeletePrimaryPhoneConfirmationDialogClose();
    };

    const renderPhoneField = index => (
        <Stack
            key={fields[index].id}
            direction="row"
            spacing={1}
            alignItems="center"
        >
            <FormControlledPhoneField
                name={`emergencyAlertPhones.${index}`}
                control={control}
                label={
                    index === 0 ? 'Primary Phone' : `Alternative Phone ${index}`
                }
            />

            <IconButton
                aria-label="delete"
                onClick={() => handleDeletePhone(index)}
                {...(isSm && {
                    style: {alignSelf: 'flex-start', marginTop: '15%'}
                })}
                {...(isXs && {
                    style: {
                        alignSelf: 'flex-start',
                        marginTop: '20%'
                    }
                })}
                {...(isXxs && {
                    style: {
                        alignSelf: 'flex-start',
                        marginTop: '25%'
                    }
                })}
            >
                <FontAwesomeIcon
                    icon={faTrash}
                    aria-label={`Delete ${
                        index === 0
                            ? 'Primary Phone'
                            : `Alternative Phone ${index}`
                    }`}
                />
            </IconButton>
        </Stack>
    );

    return (
        <Container sx={{mt: 4}}>
            <Typography component="h3" variant="h4">
                University Alerts
            </Typography>
            <Typography variant="body1">
                The University is committed to information privacy. The
                information below may be used in the event of an emergency or to
                provide important University information.
            </Typography>

            <Box sx={{flexGrow: 1, mt: 3, mb: 3}}>
                <Stack direction="column" marginTop={3} gap={1}>
                    <FormControlledCheckbox
                        name="emergencyTextAlert"
                        control={control}
                        label="I consent to receive text and/or voice message alerts."
                    />

                    {alertsConsent && fields.length > 0
                        ? renderPhoneField(0)
                        : null}

                    {alertsConsent && fields.length === 0 && (
                        <Grid container alignItems="center" rowSpacing={0}>
                            <Grid item sx={0} md={3} lg={4} xl={3} />
                            <Grid item sx={12} md={0} lg={0} xl={9} >
                                <FormButton
                                    id="addPrimaryPhoneNumber"
                                    className="myOdu__button secondary myInfoButton"
                                    label="Add Primary Phone Number"
                                    onClick={() =>
                                        append({
                                            voiceOnly: 'false',
                                            countryCode: 1,
                                            number: ''
                                        })
                                    }
                                />
                            </Grid>
                        </Grid>
                    )}

                    {alertsConsent && fields.length > 0 && (
                        <Box sx={{mt: 3, border: '1px solid #dadada'}}>
                            <Box sx={{backgroundColor: '#F5F5F5', padding: 1}}>
                                <Typography
                                    className="myOdu__label"
                                    component="label"
                                >
                                    Additional University Alert Phone Numbers
                                </Typography>
                            </Box>

                            <Box sx={{p:2}}>
                                <Typography>
                                    Three additional phone numbers may be
                                    provided as alternative alert contacts.
                                </Typography>
                                {/* <Stack direction="row" spacing={1}> */}
                                    <Grid
                                        container
                                        spacing={0}
                                        alignItems="center"
                                        sx={{width: '100%'}}
                                    >
                                        <Grid item xs={0} md={3} lg={4} xl={3} />
                                        <Grid item xs={0} md={0} lg={0} xl={9} className="mb-2 w-100">
                                            <FormButton
                                                className="myOdu__button secondary myInfoButton"
                                                id="addAlternativePhoneNumber"
                                                label="Add Phone Number"
                                                onClick={() =>
                                                    append({
                                                        voiceOnly: 'false',
                                                        countryCode: 1,
                                                        number: ''
                                                    })
                                                }
                                                disabled={fields.length >= 4}
                                            />
                                        </Grid>
                                    </Grid>
                                {/* </Stack> */}

                                {alertsConsent &&
                                    fields.length > 0 &&
                                    fields
                                        .slice(1)
                                        .map((_, index) =>
                                            renderPhoneField(index + 1)
                                        )}
                            </Box>
                        </Box>
                    )}
                </Stack>
            </Box>

            <PortalModal
                id="deletePrimaryPhoneConfirmationDialog"
                severity="error imperative"
                maxWidth="md"
                onClose={() =>
                    handleDeletePrimaryPhoneConfirmationDialogClose()
                }
                onPrimaryClick={() =>
                    handleDeletePrimaryPhoneConfirmationDialogClose()
                }
                onSecondaryClick={() =>
                    handleDeletePrimaryPhoneConfirmationDialogConfirm()
                }
                isOpen={deletePrimaryPhoneConfirmation}
                title="Confirmation Needed"
                primaryButtonTitle="Nevermind, I'll Keep a Primary Phone Number"
                secondaryButtonTitle="Delete Primary Phone Number"
            >
                <Typography paragraph>
                    Deleting your primary phone number with no alernatives phone
                    numbers means that you will{' '}
                    {<span style={{fontWeight: 'bold'}}>NOT RECEIVE</span>} ODU
                    Alert emergency notifications via text and/or voice
                    messages.
                </Typography>
            </PortalModal>

            <PortalModal
                id="unconsentConfirmationDialog"
                severity="error imperative"
                maxWidth="md"
                onClose={() => handleConfirmationDialogClose()}
                onPrimaryClick={() => handleConfirmationDialogClose()}
                onSecondaryClick={() => handleConfirmationDialogConfirm()}
                isOpen={unConsentConfirmation}
                title="Confirmation Needed"
                primaryButtonTitle="Nevermind, I want text and/or voice message alerts"
                secondaryButtonTitle="Confirm"
            >
                <>
                    <Typography paragraph>
                        ODU Alerts are sent in the event of a emergency,
                        inclement weather, or a closure expected to impact the
                        entire ODU community.
                    </Typography>
                    <Typography paragraph>
                        By choosing "Confirm", you acknowledge you{' '}
                        <span
                            className="myOdu__error"
                            style={{fontWeight: 'bold'}}
                        >
                            DO NOT
                        </span>{' '}
                        want to receive ODU Alert emergency notifications via
                        text and/or voice message.
                    </Typography>
                    <Typography paragraph>
                        This action will also{' '}
                        {
                            <span style={{fontWeight: 'bold'}}>
                                delete all Univsersity Alert phone numbers
                            </span>
                        }{' '}
                        provided.
                    </Typography>
                </>
            </PortalModal>
        </Container>
    );
};
