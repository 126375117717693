import React, {useState} from 'react';
import {Controller} from 'react-hook-form';
import _ from 'lodash';
import {
    Typography,
    TextField,
    Grid,
    useMediaQuery,
    Tooltip
} from '@mui/material';
import InputMask from 'react-input-mask';
import {FieldRequiredMark} from './FieldRequiredMark';

export const FormControlledTextField = ({
    name,
    control,
    label,
    rules,
    required,
    mask = null,
    ...props
}) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({field, formState: {errors}}) => {
                return mask ? (
                    <InputMask
                        alwaysShowMask
                        mask={mask}
                        label={label}
                        variant="outlined"
                        fullWidth={true}
                        value={field.value}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        maskChar={'_'}
                    >
                        {inputProps => {
                            return (
                                <FormTextField
                                    id={name}
                                    type="number"
                                    label={label}
                                    error={!!_.get(errors, name)}
                                    errorText={_.get(errors, name)?.message}
                                    required={rules?.required || required}
                                    {...props}
                                    {...inputProps}
                                />
                            );
                        }}
                    </InputMask>
                ) : (
                    <FormTextField
                        id={name}
                        {...field}
                        label={label}
                        value={field.value}
                        onChange={field.onChange}
                        error={!!_.get(errors, name)}
                        errorText={_.get(errors, name)?.message}
                        required={rules?.required || required}
                        {...props}
                    />
                );
            }}
        />
    );
};

export const FormTextField = ({
    id,
    label,
    error,
    helperText,
    errorText,
    required,
    value,
    onChange,
    charCountEnabled = false,
    maxChars = 200,
    type = 'text',
    labelGridProps = {
        xs: 12,
        md: 3,
        lg: 4,
        xl: 3
    },
    inputGridProps = {xs: 12, md: 9, lg: 8, xl: 9},
    textAlign = {xs: 'start', md: 'end', lg: 'end'},
    isTextArea = false,
    ...props
}) => {
    const isSm = useMediaQuery(theme => theme.breakpoints.only('sm'));
    const [charCount, setCharCount] = useState(value?.length || 0);

    const handleChange = e => {
        const inputValue = e.target.value;
        // Hacky fix to trim all non digit character when masking phone number
        const trimmedValue =
            type !== 'text' ? inputValue.replace(/\D/g, '') : inputValue;

        if (maxChars) {
            if (trimmedValue.length <= maxChars) {
                onChange(trimmedValue);
                setCharCount(trimmedValue.length);
            }
        } else {
            onChange(trimmedValue);
            setCharCount(trimmedValue.length);
        }
    };

    return (
        <Grid
            container
            spacing={{xs:0, sm:1}}
            alignItems={isTextArea ? 'start' : 'center'}
        >
            {label && (
                <Grid item textAlign={textAlign} {...labelGridProps}>
                    <Typography
                        className="myOdu__label"
                        component="label"
                        htmlFor={id}
                    >
                        {label}{' '}
                        {required && <FieldRequiredMark label={label} />}
                    </Typography>
                    {!isSm && error && <p></p>}
                </Grid>
            )}

            <Grid item {...inputGridProps}>
                <TextField
                    id={id}
                    size="small"
                    aria-label={props.ariaLabel || label}
                    value={value}
                    onChange={handleChange}
                    {...props}
                    error={error}
                    fullWidth
                    inputProps={{
                        'aria-label': props.ariaLabel || label
                    }}
                />

                {charCountEnabled && (
                    <Typography
                        variant="caption"
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <div>
                            <Tooltip
                                title={`${charCount} characters used of ${maxChars} character maximum`}
                                placement="bottom" // Adjust the placement as needed
                                arrow
                                enterDelay={500}
                                leaveDelay={200}
                            >
                                {charCount}/{maxChars}
                            </Tooltip>
                        </div>
                    </Typography>
                )}

                {error ? (
                    <Typography
                        display="flex"
                        justifyContent="flex-start"
                        variant="caption"
                        className="myOdu__error"
                    >
                        {errorText}
                    </Typography>
                ) : (
                    <Typography
                        display="flex"
                        justifyContent="flex-start"
                        variant="caption"
                        className="myOdu__helper"
                    >
                        {helperText}
                    </Typography>
                )}
            </Grid>
        </Grid>
    );
};
