import React, {Component} from 'react';
import {Typography, Box} from '@mui/material';
import GPADetails from '../Details/GpaDetails';

class GpaAcademicStanding extends Component {
    render() {
        return <Box className="itemBox">
                    <Typography component="h4" variant="h6">Grade Point Average</Typography>
                    <GPADetails
                        id="myPrograms__current_gpa_details"
                        title="Current GPA"
                        data={this.props.currentSem?.CUML_GPA}
                        isDisplayGPA={this.props.isDisplayGPA}
                        toggleIsDisplayGPA={this.props.toggleIsDisplayGPA}
                    />


                    <Typography component="h4" variant="h6" sx={{mt:2}}>Academic Standing</Typography>
                    <Typography
                        id={'myPrograms__academicStanding'}
                        component="p"
                        variant="small"
                    >
                        {this.props.currentSem?.ACADEMIC_STANDING_DESC ||
                            'No academic standing found'}
                    </Typography>
                </Box>
    }
}

export default GpaAcademicStanding;
