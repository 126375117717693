import React from 'react';
import {Typography, Grid, Container, Box, Stack} from '@mui/material';
import {relationshipOptions} from '../common/Form/FormOptions';
import {useFieldArray} from 'react-hook-form';
import {FormButton} from '../common/Form/FormButton';
import {FormControlledDropdown} from '../common/Form/FormDropdown';
import {FormControlledTextField} from '../common/Form/FormTextField';
import {FormControlledPhoneField} from '../common/Form/FormPhoneField';

export const RelationshipsSection = ({control}) => {
    const {fields, append, remove} = useFieldArray({
        control,
        name: 'parents'
    });

    const handleAddRelationship = () => {
        append({
            relationship: '',
            firstName: '',
            middleName: '',
            lastName: '',
            phone: {voiceOnly: 'false', countryCode: 1, number: ''}
        });
    };

    return (
        <Container sx={{mt: 4, mb: 4}} id="relationships">
            <Typography component="h3" variant="h4">
                Parent/Legal Guardian Relationships
            </Typography>
            <Typography variant="body1">
                Optional. Relationships are used in the event of an emergency.
            </Typography>

            <Stack direction="row" spacing={1} sx={{mt: 2}}>
                <Grid container alignItems="center">
                    <Grid item md={3} lg={4} xl={3} />
                    <Grid item xs={12} md={0} lg={0} xl={0}>
                        <FormButton
                            className="myOdu__button secondary myInfoButton"
                            id="addRelationship"
                            label="Add Relationship"
                            onClick={handleAddRelationship}
                            disabled={fields.length >= 4}
                        />
                    </Grid>
                </Grid>
            </Stack>

            {fields.map((field, index) => (<>
                <Box
                sx={{backgroundColor: '#F5F5F5', p: 1, mt:2 }}
                className="myOdu_box"
                >
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{pr:1}}
                >
                    <Typography
                        className="myOdu__label"
                        component="label"
                    >{`Relationship ${index + 1}`}</Typography>
                    <FormButton
                        label="Remove"
                        onClick={() => remove(index)}
                    />
                </Stack>
            </Box>

                <Box className="myOdu__box" key={field.id} sx={{p: 2, m:0}}>


                    <Stack
                        direction="column"
                        alignContent="center"
                        gap={1}
                        marginTop={3}
                    >
                        <FormControlledDropdown
                            control={control}
                            name={`parents.${index}.relationship`}
                            label="Relationship"
                            options={relationshipOptions}
                            rules={{required: 'Relationship is required'}}
                        />
                        <FormControlledTextField
                            name={`parents.${index}.firstName`}
                            control={control}
                            label={'First Name'}
                            rules={{
                                required: 'First Name is required',
                                pattern: {
                                    value: /^[a-zA-Z '-]+$/,
                                    message:
                                        'First Name can only contain letters, space, apostrophes or hyphens.'
                                }
                            }}
                        />
                        <FormControlledTextField
                            name={`parents.${index}.middleName`}
                            control={control}
                            label={'Middle Name'}
                        />
                        <FormControlledTextField
                            name={`parents.${index}.lastName`}
                            control={control}
                            label={'Last Name'}
                            rules={{
                                required: 'Last Name is required',
                                pattern: {
                                    value: /^[a-zA-Z '-]+$/,
                                    message:
                                        'Last Name can only contain letters, space, apostrophes or hyphens.'
                                }
                            }}
                        />

                        <FormControlledPhoneField
                            name={`parents.${index}.phone`}
                            control={control}
                            label={'Phone'}
                        />
                    </Stack>
                </Box>
                </>))}
        </Container>
    );
};
