import { faCircleArrowDown, faCircleArrowUp, faCircleQuestion } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Grid, IconButton, Typography, Box, Tooltip } from "@mui/material";
import React, {Component} from "react";
import { Col, Row } from "reactstrap";
import { MOVE_WIDGET_DOWN, MOVE_WIDGET_UP } from "../../common/constants";


class WidgetCard extends Component {
    state ={

    }

    getTitle = (direction, position) => {
        return "Move " + this.props.widget.title + " " + direction + " to position " + position;
    }

    render() {
        return <React.Fragment>
            {/* <Card sx={{ p:1, height: '80%'}} className={'myOdu__appCard'} elevation={0}> */}
                <Grid container direction={"row"} wrap="nowrap" className="myOdu__widgetCard" style={(this.props.hideUp && this.props.hideDown) ? {border: 0, width: 'auto'} : {width:'auto'}}>
                    
                {!(this.props.hideUp && this.props.hideDown) && 
                    <Grid item container xs="auto" direction={"row"} wrap="nowrap">
                        <Grid item>
                            <Tooltip title={this.getTitle('up', (this.props.index))} enterDelay={850} leaveDelay={0} enterNextDelay={850} arrow>
                                <IconButton id={"settings__button_widgetCard_" + this.props.widget.id + "_up"} onClick={() => this.props.moveWidget(this.props.widget.id, this.props.sectionNumber, MOVE_WIDGET_UP)} style={this.props.hideUp ? {opacity: 0} : {}} disabled={this.props.hideUp}>
                                    <FontAwesomeIcon icon={faCircleArrowUp} fixedWidth size="md" className="myOdu__arrowButton"/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={this.getTitle('down', (this.props.index+2))} enterDelay={850} leaveDelay={0} enterNextDelay={850} arrow>
                                <IconButton id={"settings__button_widgetCard_" + this.props.widget.id + "_down"} onClick={() => this.props.moveWidget(this.props.widget.id, this.props.sectionNumber, MOVE_WIDGET_DOWN)} style={this.props.hideDown ? {opacity: 0}: {}} disabled={this.props.hideDown}>
                                    <FontAwesomeIcon icon={faCircleArrowDown} fixedWidth size="md" className="myOdu__arrowButton" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                }

                    <Grid item sx={{ml:1}}>
                        <Typography variant="h6" component='h4'>
                            {this.props?.widget?.title}
                            {(this.props.hideUp && this.props.hideDown) && 
                            <Tooltip title={this.props?.widget?.title + " widget cannot be moved."} enterDelay={750} leaveDelay={0} enterNextDelay={750} arrow>
                                    <FontAwesomeIcon icon={faCircleQuestion} size="sm" style={{marginLeft:'0.25rem'}}/>
                                </Tooltip> }
                        </Typography>
                        <Typography variant="smaller" component='p'>{this.props?.widget?.desc}</Typography>
                    </Grid>
                </Grid>
            {/* </Card> */}
        </React.Fragment>
    }
}

export default WidgetCard;