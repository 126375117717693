import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLocationDot} from '@fortawesome/pro-solid-svg-icons';
import {Link, Typography, Stack, Tooltip} from '@mui/material';
import Alarm from '../common/icons/Alarm.js';
import ZoomClass from '../common/icons/ZoomClass.js';

const getIcon = (isSoon, isOnline, id) => {
    return isSoon ? (
        //TODO: pass id to icon
        <Alarm />
    ) : isOnline ? (
        //TODO: pass id to icon
        <ZoomClass />
    ) : (
        <FontAwesomeIcon id={id} icon={faLocationDot} fixedWidth size={'xl'} />
    );
};

export const makeTitle = (courseSubjectNumber, courseTitle, id) => {
    return (
        <Stack id={id + '__wrapper__subjNumTitle'}>
            <Typography
                component="h3"
                variant="small"
                className="myOdu__lineClamp-1"
                id={id + '__subjNumTitle'}
            >
                {makePlainTitle(courseSubjectNumber, courseTitle)}
            </Typography>
        </Stack>
    );
};

export const makePlainTitle = (courseSubjectNumber, courseTitle) => {
    return `${courseSubjectNumber} ${courseTitle}`;
};

export const getClickableIcon = ({id, isSoon, isOnline, locationUrl}) => {
    if (locationUrl) {
        return (
            <Tooltip
                id={id + '__tooltip'}
                title={isOnline ? 'Launch Zoom' : 'Open in new window'}
                enterDelay={750}
                leaveDelay={0}
                enterNextDelay={750}
                arrow
            >
                <Link
                    href={locationUrl}
                    target="_blank"
                    id={id + '__link_locationUrl'}
                >
                    {getIcon(isSoon, isOnline, id + '__icon')}
                </Link>
            </Tooltip>
        );
    } else return getIcon(isSoon, isOnline);
};
