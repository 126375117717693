//Θ(N) where N is the length of email
//Checks if the email is a valid email
export const isValidEmail = (email) => {
    if(email.length === 0)
        return true;
    let isValidEmail = email.includes('@odu.edu')
    return isValidEmail;
}

//Θ(N) where N is the number of emails
//Checks if the emails are valid
export const isValidEmails = (emails) => {
    let isValid = true

    emails.forEach(email => {
        if(!isValidEmail(email)) {
            isValid = false;
        }
    });

    return isValid;
}