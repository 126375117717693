import React, {Component} from 'react';
import {Stack, Typography, Box} from '@mui/material';
import {Time} from '../../common/MomentTime';
import {FormTextField} from '../../common/Form/FormTextField';
import {FormTextArea} from '../../common/Form/FormTextArea';

class FeedbackDetails extends Component {
    render() {
        const {data} = this.props;
        const {
            midas,
            bugDetail,
            changeDetail,
            ideaDetail,
            metadata,
            otherDetail,
            pageUrl,
            rating,
            timestamp
        } = data || {};

        return (
            <Stack spacing={1} >
                <Typography component="h3" variant="h3">
                    Details
                </Typography>

                <Stack direction="row" >
                    <Typography component="label" variant="p" className="myOdu__label" sx={{mr:1}}>User MIDAS</Typography>
                    <Typography component="p">
                        {midas}
                    </Typography>
                </Stack>

                <Stack direction="row" >
                    <Typography component="label" className="myOdu__label" sx={{mr:1}}>Time Submitted</Typography>
                    <Typography component="p">
                       {timestamp ? Time.University(timestamp).format(
                    'M/DD/YYYY h:mm:ss A') : '-'}
                    </Typography>
                </Stack>

                <Stack direction="row" >
                    <Typography component="label" className="myOdu__label" sx={{mr:1}}>Rating</Typography>
                    <Typography component="p">
                        {rating ? rating : '-'}
                    </Typography>
                </Stack>


                <Box className="myOdu__box bordered" sx={{m:0}}>
                    <Typography component="label" className="myOdu__label">Bug Details</Typography>
                    <Stack direction="row" sx={{my: 1}} >
                    <Typography component="p" variant="smaller" sx={{mr:1, fontWeight: 'bold'}}>Page URL</Typography>
                    <Typography component="p" variant="smaller">
                        {pageUrl ? pageUrl : '-'}
                    </Typography>
                </Stack>

                    <Typography component="p" variant="smaller">
                        {bugDetail ? bugDetail : '-'}
                    </Typography>
                </Box>
                <Box className="myOdu__box bordered" sx={{m:0}}>
                    <Typography component="label" className="myOdu__label">Idea Details</Typography>
                    <Typography component="p" variant="smaller">
                        {ideaDetail ? ideaDetail : '-'}
                    </Typography>
                </Box>
                <Box className="myOdu__box bordered" sx={{m:0}}>
                    <Typography component="label" className="myOdu__label">Change Details</Typography>
                    <Typography component="p" variant="smaller">
                        {changeDetail ? changeDetail : '-'}
                    </Typography>
                </Box>
                <Box className="myOdu__box bordered" sx={{m:0}}>
                    <Typography component="label" className="myOdu__label">Other Details</Typography>
                    <Typography component="p" variant="smaller">
                        {otherDetail}
                        {otherDetail ? otherDetail : '-'}
                    </Typography>
                </Box>
                <Box className="myOdu__box bordered" sx={{m:0}}>
                    <Typography component="label" className="myOdu__label">MetaData</Typography>
                    <Typography component="p" variant="smaller">
                        <div><pre>{JSON.stringify(metadata, null, 2)}</pre></div>
                    </Typography>
                </Box>
            </Stack>
        );
    }
}

export default FeedbackDetails;
