import React, {Component} from 'react';
import Widget from '../common/Widget';
import {Link, Box, Grid, Stack, Typography, Skeleton} from '@mui/material';
import {SafetyContacts} from './SafetyContacts';
import {safety} from '../../DataAccessLayer/services';
import {getData} from '../../DataAccessLayer';
import ApiErrorMessage from '../common/ApiErrorMessage';
import { cleanUpID } from '../common/utils';

class Safety extends Component {
    state = {
        isExpand: false,
        contacts: {},
        isError: false,
        isLoading: false
    };

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    toggleIsError = value => {
        this.setState({isError: value});
    };

    componentDidMount() {
        this.loadSafetyContacts();
    }

    loadSafetyContacts = () => {
        this.toggleIsError(false);
        this.setState({isLoading: true});
        getData(safety, true)
            .then(data => {
                if (data) {
                    this.setState({contacts: data});
                }
            })
            .catch(err => {
                this.toggleIsError(true);
                console.log(err);
            })
            .finally(() => this.setState({isLoading: false}));
    };

    render() {
        return (
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title,
                        isTitleStylized: this.props.isTitleStylized
                    }}
                    {...this.props}
                    isExpand={this.state.isExpand}
                >
                    {this.state.isLoading && (
                        <Skeleton id="safety__skeleton_loading" variant="rectangular" height={80} />
                    )}
                    {!this.state.isLoading && this.state.isError && (
                        <ApiErrorMessage
                            id={"safety__apiErrorMessage"}
                            widgetName={this.props.title}
                            reload={this.loadSafetyContacts}
                        />
                    )}
                    {!this.state.isLoading && !this.state.isError && (
                        <Grid id={"safety__grid_safetyGrid_wrapper"} container spacing={2}>
                            {this.state.contacts.titleContact && (
                                <Grid
                                    id={"safety__grid_safetyCard_wrapper"}
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    sx={{justifyContent: 'center'}}
                                    className="ms-xl-3 pt-0 ps-0"
                                >
                                    <Box
                                        id={"safety__box_safetyCardWrapper"}
                                        sx={{
                                            p: 2,
                                            textAlign: 'center',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            borderBottom: '1px solid #DEDEDE'
                                        }}
                                    >
                                        <Typography id={"safety__title_" + cleanUpID(this.state.contacts.titleContact.title)}
                                                    component="h3" variant="h6">
                                            {
                                                this.state.contacts.titleContact
                                                    .title
                                            }
                                        </Typography>
                                        <Stack id={"safety__stack_link_phone"}>
                                            {this.state.contacts.titleContact
                                                .phoneNumber && (
                                                <Link
                                                    id={
                                                        'safety__link_phone_' +
                                                        this.state.contacts
                                                            .titleContact
                                                            .phoneNumber
                                                    }
                                                    href={`tel:${this.state.contacts.titleContact.phoneNumber}`}
                                                    underline={'hover'}
                                                    title={`Call ${this.state.contacts.titleContact.title}`}
                                                >
                                                    {
                                                        this.state.contacts
                                                            .titleContact
                                                            .phoneNumber
                                                    }
                                                </Link>
                                            )}
                                            {this.state.contacts.titleContact
                                                .alternativeNumber && (
                                                <span id={"safety__span_linkWrapper"}>
                                                    Or{' '}
                                                    <Link
                                                        id={
                                                            'safety__link_phone_' +
                                                            this.state.contacts
                                                                .titleContact
                                                                .alternativeNumber
                                                        }
                                                        href={`tel:${this.state.contacts.titleContact.alternativeNumber}`}
                                                        underline={'hover'}
                                                        title={`Call ${this.state.contacts.titleContact.alternativeNumber}`}
                                                    >
                                                        {
                                                            this.state.contacts
                                                                .titleContact
                                                                .alternativeNumber
                                                        }
                                                    </Link>
                                                </span>
                                            )}
                                        </Stack>
                                    </Box>
                                </Grid>
                            )}
                            <Grid id={"safety__grid_safetyBox_wrapper"} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box
                                    id={"safety__box_safetyList_wrapper"}
                                    className="myOdu__safetyList">
                                    {this.state.contacts.contactsList?.length &&
                                        this.state.contacts.contactsList.map(
                                            campus => (
                                                <Box id={'safety__safetyContact_' +campus.id}
                                                    className="safetyContact"
                                                    key={
                                                        'safety__safetyContact_' +
                                                        campus.id
                                                    }
                                                >
                                                    <Typography
                                                        id={"safety__title_campusTitle_" + campus.id}
                                                        component="h3"
                                                        variant="p"
                                                        sx={{fontWeight: 600}}
                                                    >
                                                        {campus.title}
                                                    </Typography>
                                                    {campus.contacts.map(
                                                        contact => (
                                                            <SafetyContacts
                                                                id={"safety__safetyContacts"}
                                                                data={contact}
                                                                key={
                                                                    'safety__safetyContact_' +
                                                                    campus.id +
                                                                    '_' +
                                                                    contact.id
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </Box>
                                            )
                                        )}
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                </Widget>
        );
    }
}

export default Safety;
