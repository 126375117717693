import React, {Component} from 'react';
import { Link, List, ListItem, Box, } from '@mui/material';
import PortalTooltip from '../../common/PortalTooltip';

class ProgramResources extends Component {
    render() {
        return <Box className="itemBox programResources">
            <List>
                {this.props.myProgramLinks.map(item => {
                    return (
                        <ListItem sx={{py:0}}>
                            <PortalTooltip id={'myPrograms__tooltip_' + item.id} title={item?.tooltipTitle} >                                        
                                <Link rel={item.rel} target={item.target} href={item.link} underline={'hover'} >
                                    {item.title}
                                </Link>
                            </PortalTooltip>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    }
}

export default ProgramResources;
