import React, {Component, useEffect, useRef, useState} from "react";
import { AppConfigs } from "../SetUp/config";
import { Col, Row } from "reactstrap";
import ApplicationCard from "../common/ApplicationCard";
import { Box, Card, Grid } from "@mui/material";
import { ICON_PATH } from "../common/constants";

class AppsCard extends Component {

    state = {
    }

    render() {
        return <React.Fragment>
            <div
                ref={!this.props.isSideBox && this.props.refDiv}
                style={this.props.isSideBox && {minWidth: (this.props.refDiv?.current?.clientWidth) + 'px'}}
            >
            <Row 
                style={{height: '100%'}}
                className={this.props.className}
            >
                {
                    this.props.apps?.map((app) => {
                        const config = app
                        return <Col xxl={4} xl={6} lg={2} md={2} sm={4} xs={6} className='mb-2 px-1 col-4xl-2' key={"myApps__appCardFor_" + config?.id}>
                            {
                                !this.props.isSideBox ?
                                <div style={{height: '100%'}} className="appCardCenter" ><ApplicationCard widgetId="myApps" app={config} data={{icon: ICON_PATH + config?.icon}} isActive={true} showHeart={false} showInfo={false} showLinks={true} /></div>
                                : <div style={{height: '100%'}} className="appCardCenter"><ApplicationCard widgetId="myApps" app={config} data={{icon: ICON_PATH + config?.icon}} isActive={true} showHeart={false} showInfo={false} showLinks={true} opacity={0}/></div>
                            }
                        </Col>
                    })
                    
                }
            </Row>
            </div>
        </React.Fragment>
    }
}

export default AppsCard;