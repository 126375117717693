import React, {Component} from 'react';
import {Typography, Breadcrumbs, Link, Skeleton, Box} from '@mui/material';
import {checklistDetails} from '../../DataAccessLayer/services';
import {getData} from '../../DataAccessLayer';
import {Container} from 'reactstrap';
import DetailsTabs from './DetailsTabs';
import PortalAlert from '../common/PortalAlert';
import ApiErrorMessage from '../common/ApiErrorMessage';
import WithPropsRouter from '../Layout/Router/WithPropsRouter';

class Details extends Component {
    state = {
        isLoading: false,
        isError: false,
        details: null,
        code: window.location.pathname.split('/')[2]
    };

    componentDidMount() {
        this.loadChecklistDetails(this.state.code);
    }

    toggleIsLoading = isLoading => {
        this.setState({isLoading});
    };

    toggleIsError = value => {
        this.setState({isError: value});
    };

    /**
     * Fetches the checklist details
     * @param {string} code The id of the checklist
     */
    loadChecklistDetails = code => {
        this.toggleIsLoading(true);
        this.toggleIsError(false);
        getData(checklistDetails(code), true)
            .then(details => {
                this.setState({details});
            })
            .catch(err => {
                console.log(err);
                this.toggleIsError(true);
            })
            .finally(() => this.toggleIsLoading(false));
    };

    render() {
        const name =
            this.props.location?.state?.name ||
            this.state.details?.checklistDesc;
        return (
            <>
                <Container className="myOdu__container_maxWidth">
                    {name && (
                        <Box>
                            <Typography
                                sx={{color: 'primary.main'}}
                                component="h2"
                                variant="h3"
                            >
                                {name}
                            </Typography>
                            <Breadcrumbs aria-label="breadcrumb" sx={{mb: 2}}>
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    href="/"
                                    sx={{cursor: 'pointer'}}
                                    id="checklist__link_dashboard"
                                >
                                    Dashboard
                                </Link>
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    href="/checklists"
                                    sx={{cursor: 'pointer'}}
                                    id="checklist__link_checklistIndex"
                                >
                                    Checklists
                                </Link>
                                <Typography color="text.primary">
                                    {name}
                                </Typography>
                            </Breadcrumbs>
                        </Box>
                    )}

                    {this.state.isLoading && <Skeleton variant="rectangular" />}

                    {!this.state.isLoading && this.state.isError && (
                        <PortalAlert severity="error" type="checklist">
                            <ApiErrorMessage
                                reload={() => {
                                    this.loadChecklistDetails(this.state.code);
                                }}
                                widgetName={name ?? 'checklist'}
                                isPage={true}
                            />
                        </PortalAlert>
                    )}

                    {!this.state.isLoading &&
                        !this.state.isError &&
                        this.state.details && (
                            <DetailsTabs
                                items={this.state.details.itemsList ?? []}
                                isLoading={this.state.isLoading}
                                checklistTitle={
                                    this.state.details.checklistDesc
                                }
                            />
                        )}
                </Container>
            </>
        );
    }
}

export default WithPropsRouter(Details);
