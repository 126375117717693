import * as React from 'react';
import { Card, Typography, Link, Box, IconButton, Grid, Stack, Chip} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faCircleInfo, faDisplayMedical } from '@fortawesome/pro-light-svg-icons';
import {faHeart as faHeart_solid} from '@fortawesome/pro-solid-svg-icons/faHeart';
import PortalTooltip from "../common/PortalTooltip";

export class ApplicationCard extends React.Component {
  state={
    tooltipIsOpen: false,
  }

  toggleTooltip = () => {
    this.state.tooltipIsOpen= !this.state.tooltipIsOpen;
  };


  getLinkTitle = () =>{
    return ((this.props.app?.linkTarget == "_blank") ? "Open " + this.props.app?.name + " in new window" : "Go to " + this.props.app?.name );
  };

    render(){
        const linkTitle = this.getLinkTitle();
        return (   
            <Card sx={{ p:1, height: '100%', width: '100%'}} className={'myOdu__appCard ' + (this.props.isActive ? "active " : "disabled ") + this.props.className } elevation={0}>
                <Grid container direction='column' style={{opacity: this.props.opacity}}>
                {(this.props.showHeart || this.props.showInfo) && 
                    <Stack
                        direction="row"
                        alignItems="flex-start"
                        justifyContent={'space-between'}
                        sx={{mb: "-.5rem"}}
                    >
                        {this.props.showHeart && (
                            <PortalTooltip title={ this.props.isActive ? 'Remove ' + this.props.app?.name + ' from favorite apps' : 'Add to favorite apps' } >
                                {/* //TODO: call string cleaner here */}
                                <IconButton
                                    onClick={this.props.onClick} 
                                    size="small"
                                    id={ this.props.id + '__applicationCard__button_favorite_' + this.props.app?.name.replace( ' ', '' ) }
                                >
                                    {this.props.isActive ? (
                                        <FontAwesomeIcon icon={faHeart_solid} className="myOdu__favorite" size="small" />
                                    ) : (
                                        <FontAwesomeIcon icon={faHeart} size="small" />
                                    )}
                                </IconButton>
                            </PortalTooltip>
                        )}
                        {this.props.showInfo && <>
                                <PortalTooltip title={ this.props.app ?.desc ?? this.props.app?.name } >
                                    <IconButton id={ 'applicationCard__button_moreInfo_' + this.props.app?.name.replace( ' ', '' ) } size="small" >
                                        <FontAwesomeIcon icon={ faCircleInfo } size="small" />
                                    </IconButton>
                                </PortalTooltip>
                            </>
                        }
                    </Stack>
                    }

                    <Grid item sx={{lineHeight: "110%", mt: 1}}>
                        <div className={this.props.className}>
                            {/* //With link */}
                            {this.props.showLinks && this.props.app?.link ? <>
                                <PortalTooltip id={this.props.app?.id + "_tooltip_box"} title={linkTitle}>
                                    <Link id={"applicationCard__button_" + this.props.app?.name.replace(" ", "")} href={this.props.app?.link} target="_blank" underline="none" className={"cursor-pointer"} >
                                        <Box sx={{width: '40px', margin: '0 auto'}}  className='myOdu__logoZoom'>
                                            <img src={this.props.data.icon} alt={this.props.app?.name ?? "application icon"} />
                                        </Box>
                                    </Link>
                                </PortalTooltip>
                                <PortalTooltip id={this.props.app?.id + "_tooltip"} title={linkTitle}>
                                    <Typography align="center" variant="smaller" sx={{ml:0, wordBreak: 'break-word'}}>
                                        <Link id={"applicationCard__link_" + this.props.app?.name.replace(" ", "")} href={this.props.app?.link} underline={"hover"} sx={{cursor: 'pointer'}} target={this.props.app.linkTarget ?? ""} className="myOdu__appCardTitleLink">
                                            {this.props.app?.name}
                                        </Link>
                                    </Typography>
                                </PortalTooltip>
                            </> : <>
                            {/* //Without link */}
                                <PortalTooltip title={this.props.data.tooltip}>
                                    <Box sx={{width: '40px', margin: '0 auto .5rem auto', cursor: 'auto'}}>
                                        <img src={this.props.data.icon} alt="" />
                                    </Box>
                                </PortalTooltip>
                                <Typography component="p" align="center" variant="smaller" sx={{ml:0}} >
                                    {this.props.app?.name}
                                </Typography>                    
                            </>}
                        </div>
                    </Grid>

                {this.props.hasSchool && 
                    <Stack direction="row" alignItems={"flex-end"} justifyContent={"center"} sx={{my:1, pt: 1, height: '100%'}} >
                        { this.props.app.isODU && <PortalTooltip title="Available for Old Dominion University users"><Chip label="ODU" color="primary" sx={{fontSize: '.65rem', mr:1, height: '1rem'}}/></PortalTooltip> }
                        { this.props.app.isEVMS && <PortalTooltip title="Available for Virginia Health Sciences users"><Chip label="VHS" color="secondary" sx={{fontSize: '.65rem', height: '1rem'}}/></PortalTooltip> }
                        {/* <Box sx={{ height: '1rem', width: '1rem', marginTop: '-4px', marginRight: '.5rem' }} >
                            <img src={'/img/oduCrown_blue.svg'} alt="ODU app" />
                        </Box>
                        <Box sx={{ height: '1rem', width: '2.75rem', marginTop: '-4px', marginRight: '.5rem' }} >
                            <img src={'/img/logos/evmsText.svg'} alt="EVMS app" />
                        </Box> */}
                    </Stack>
                }
                </Grid>
            </Card>
        );
    }
}

export default ApplicationCard;

