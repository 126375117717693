import { isUserAdmin } from "../Layout/utils";
import { AppConfigs, AppVersionHistory, DefaultWidgetOrder, WidgetConfigs, WidgetVersionHistory } from "./config"


export const isSameWidgetsVersion = (widgets) => {
    if (widgets.Version === WidgetConfigs.Version)
        return true
    
    return false;
}

export const updateWidgetsOrder = (widgets, user) => {
    let versions = Object.keys(WidgetVersionHistory).filter(version => version > widgets.Version);
    let widgetsOrder = getWidgetOrderBasedOnRole(widgets.WidgetsOrder, user);

    versions.forEach(version => {
        //First check for deletions
        if(WidgetVersionHistory[version].Deleted.length) {
            WidgetVersionHistory[version].Deleted.forEach(widgetId => {
                let widget = WidgetConfigs.Widgets.find(widget => widget.id === widgetId);
                let idx = widgetsOrder[widget.section - 1].indexOf(widgetId);
                widgetsOrder[widget.section - 1].splice(idx, 1);
            })
        }

        //Check for new widgets
        if(WidgetVersionHistory[version].Added.length) {
            WidgetVersionHistory[version].Added.forEach(widgetId => {
                let widget = WidgetConfigs.Widgets.find(widget => widget.id === widgetId)
                //Check if there is any fixed widget in the lane
                if(isDisplayWidget(widget, user)) {
                    let fixedWidgets = WidgetConfigs.Widgets.filter(wid => wid.section === widget.section && wid.isNoDrag)
                    
                    if(widget.isNoDrag) {
                        widgetsOrder[widget.section - 1].splice(0, 0, widgetId);
                    }
                    else {
                        widgetsOrder[widget.section - 1].splice(fixedWidgets.length, 0, widgetId);
                    }
                }
            })
        }
    });

    widgets.WidgetsOrder = widgetsOrder;
    widgets.Version = WidgetConfigs.Version;

    return widgets
}

export const isSameAppsVersion = (apps) => {
    if(apps.Version === AppConfigs.Version)
        return true;

    return false;
}

export const updateApps = (preferences, isImpersonating, impersonation, user) => {
    let versions = Object.keys(AppVersionHistory).filter(version => version > preferences.Version);
    versions.forEach(version => {
        //Check for Deletes
        if(AppVersionHistory[version].Deleted.length) {
            AppVersionHistory[version].Deleted.forEach(appId => {
                delete preferences.Apps[appId];
            })
        }

        if(AppVersionHistory[version].Added.length) {
            AppVersionHistory[version].Added.forEach(appId => {
                let app = AppConfigs.Apps.find(app => app.id === appId)
                preferences = addAppBasedOnRole(preferences, app, isImpersonating, impersonation, user)
            })
        }
    });

    preferences.Version = AppConfigs.Version;

    return preferences;
}

const addAppBasedOnRole = (preferences, app, isImpersonating, impersonation, user) => {
    if(!(app.id in (preferences?.Apps ?? {}))) {
        if(app.isDefaultSelected || app?.preSelected?.role?.some((item) => item in (isImpersonating ? impersonation?.roles ?? {} : user?.roles ?? {}))) {
            preferences['Apps'] = {...(preferences?.Apps ?? {}), [app.id]: true}
            return preferences;
        }
    }
    return preferences;
}

//Θ(N) where N is the number of roles
//Checks if the widget has to be displayed to the user based on user's role
const isDisplayWidget = (widget, user) => {
    const roles = user.roles;

    if((!widget.defaultDisplay || isUserAdmin(user)) && !widget?.isDeleted )
        return true
    else if(widget.defaultDisplay?.role) {
        //Check if the user has the required role
        return widget?.defaultDisplay?.role?.some(role => {
            return ((role in roles) && !widget?.isDeleted)
        });
    } else if (widget?.isDeleted) {
        return false;
    }
    return true;
}

//Θ(NxM) where N is the size of widgets and M is the size of user roles
//Returns the list of widget along with config filtered on user roles
export const getWidgetsBasedOnRole = (widgets, user) => {
    const roles = user.roles
    const filteredWidgets = widgets.filter(widget => {
        //Keep the widget if its being shown for all
        return isDisplayWidget(widget, user)
    });

    return filteredWidgets;
}

//Θ(NxM) where N is the size of sections on dashboard and M is the size of widgets in section
//Returns the widget order filtered by role
export const getWidgetOrderBasedOnRole = (widgetOrder, user) => {
    //Fetch the list of widgets filtered on role
    let roleBasedWidgets = getWidgetsBasedOnRole(WidgetConfigs.Widgets, user);
    //Create an object of widget ids which has to be shown
    roleBasedWidgets = roleBasedWidgets?.reduce((widgetObject, widget) => {
        if(WidgetVersionHistory[WidgetConfigs.Version].Added.includes(widget.id)) {
            //We dont add new widgets being added in current version as its being handled later in updateWidgetsOrder
            return widgetObject
        } else {
            return {
                ...widgetObject,
                [widget.id]: widget.section
            }
        }
    }, {});

    //Filter out widget ids from the widget order by checking if its present in the roleBasedWidget object
    widgetOrder = widgetOrder.map(section => {
        const filteredSection = section.filter((widgetId) => {
            if(widgetId in roleBasedWidgets) {
                delete roleBasedWidgets[widgetId];
                return true
            }
            return false
        })
        return filteredSection;
    });

    //Add widgets in widgets order which were missing previously
    Object.keys(roleBasedWidgets).forEach((widgetId) => {
        let widget = WidgetConfigs.Widgets.find(widget => widget.id === widgetId)
        //Check if there is any fixed widghet in the lane
        let fixedWidgets = WidgetConfigs.Widgets.filter(wid => wid.section === widget.section && wid.isNoDrag)
        if(widget.isNoDrag) {
            widgetOrder[widget.section - 1].splice(0, 0, widgetId);
        }
        else {
            widgetOrder[widget.section - 1].splice(fixedWidgets.length, 0, widgetId);
        }
    });

    return widgetOrder;
}