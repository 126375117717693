import React, {Component} from 'react';
import Widget from '../common/Widget';
// import {ResourceLinks} from './config';
import {Link, List, ListItem, Tooltip} from '@mui/material';
import {UncontrolledTooltip} from 'reactstrap';

class Resources extends Component {
    state = {
        isExpand: true,
        //TODO: enable in v1.1
        // menu:[
        //     {id: "Resources_hide", title: "Hide"},
        // ]
    };

    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    render() {
        return (
            <>
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title, 
                        isTitleStylized: this.props.isTitleStylized,
                    }}
                    {...this.props}
                >
                    <div className="myOdu__resources" id={'resources__listWrapper'}>
                        <List sx={{pt: 0}} dense={true} id={"resources__list"}>
                            {this.props.resourceLinks.map(resource => (
                                <ListItem
                                    id={"resources__listItem_"+resource.id}
                                    sx={{pl: 1}}
                                    key={
                                        'resources__listItem_' +resource.id
                                    }
                                    divider
                                >
                                    <Tooltip
                                        id={'resources__tooltip_'+resource.id}
                                        title="Opens in new window"
                                        enterDelay={750}
                                        leaveDelay={0}
                                        enterNextDelay={750}
                                        arrow
                                    >
                                        <Link
                                            id={'resources__link_'+resource.id}
                                            variant="small"
                                            href={resource.link}
                                            underline={'hover'}
                                            target="_blank"
                                        >
                                            {resource.title}
                                        </Link>
                                    </Tooltip>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Widget>
            </>
        );
    }
}

export default Resources;
