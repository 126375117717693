import React from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    useRouteError,
    Navigate
} from 'react-router-dom';
import AdminPanel from '../../AdminPanel';
import Tutoring from '../../AdminPanel/Tutoring';
import Checklists from '../../Checklists';
import Details from '../../Checklists/Details';
import Dashboard from '../../Dashboard';
import DevSpace from '../../DevSpace';
import Crud from '../../DevSpace/Crud';
import {MyInfo} from '../../MyInfo';
import Settings from '../../Settings';
import StatusAlerts from '../../StatusAlerts';
import Error from './Error';
import AdminRoute from './AdminRoute';
import Layout from '../../Layout';
import {Locations} from '../../AdminPanel/Locations';
import AllApps from '../../AllApps';
import PowerRoute from './PowerRoute';
import AuthRedirect from '../../AuthRedirect';
import Landing from '../../Landing';
import CCTWrapper from '../../MyCourses/CCT/CCTWrapper';
import Feedback from '../../AdminPanel/Feedback';
import CognitoErrorLog from '../../AdminPanel/CognitoErrorLog';

const router = isLoggedIn => {
    return createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" errorElement={<Error isLoggedIn={isLoggedIn} />}>
                <Route
                    index
                    element={
                        isLoggedIn ? (
                            <AuthRedirect
                                path={window.location.pathname}
                                isLoggedIn={isLoggedIn}
                                element={<Dashboard />}
                            />
                        ) : (
                            <Landing />
                        )
                    }
                />
                <Route path="devSpace">
                    <Route
                        index
                        element={
                            <AuthRedirect
                                path={window.location.pathname}
                                isLoggedIn={isLoggedIn}
                                element={<AdminRoute element={<DevSpace />} />}
                            />
                        }
                    />
                    <Route
                        path="crud"
                        element={
                            <AuthRedirect
                                path={window.location.pathname}
                                isLoggedIn={isLoggedIn}
                                element={<AdminRoute element={<Crud />} />}
                            />
                        }
                    />
                </Route>
                <Route path="admin">
                    <Route
                        index
                        element={
                            <AuthRedirect
                                path={window.location.pathname}
                                isLoggedIn={isLoggedIn}
                                element={
                                    <PowerRoute element={<AdminPanel />} />
                                }
                            />
                        }
                    />
                    <Route
                        path="tutoring"
                        element={
                            <AuthRedirect
                                path={window.location.pathname}
                                isLoggedIn={isLoggedIn}
                                element={<PowerRoute element={<Tutoring />} />}
                            />
                        }
                    />
                    <Route
                        path="locations"
                        element={
                            <AuthRedirect
                                path={window.location.pathname}
                                isLoggedIn={isLoggedIn}
                                element={<PowerRoute element={<Locations />} />}
                            />
                        }
                    />
                    <Route
                        path="feedback"
                        element={
                            <AuthRedirect
                                path={window.location.pathname}
                                isLoggedIn={isLoggedIn}
                                element={<AdminRoute element={<Feedback />} />}
                            />
                        }
                    />
                    <Route
                        path="cognito-error-log"
                        element={
                            <AuthRedirect
                                path={window.location.pathname}
                                isLoggedIn={isLoggedIn}
                                element={
                                    <AdminRoute element={<CognitoErrorLog />} />
                                }
                            />
                        }
                    />
                </Route>
                <Route
                    path="statusAlerts"
                    element={
                        <AuthRedirect
                            path={window.location.pathname}
                            isLoggedIn={isLoggedIn}
                            element={<StatusAlerts />}
                        />
                    }
                />
                <Route
                    path="settings"
                    element={
                        <AuthRedirect
                            path={window.location.pathname}
                            isLoggedIn={isLoggedIn}
                            element={<Settings />}
                        />
                    }
                />
                <Route
                    path="checklists"
                    element={
                        <AuthRedirect
                            path={window.location.pathname}
                            isLoggedIn={isLoggedIn}
                            element={<Checklists />}
                        />
                    }
                />
                <Route
                    path="checklists/:code"
                    element={
                        <AuthRedirect
                            path={window.location.pathname}
                            isLoggedIn={isLoggedIn}
                            element={<Details />}
                        />
                    }
                />
                <Route
                    path="myInfo"
                    element={
                        <AuthRedirect
                            path={window.location.pathname}
                            isLoggedIn={isLoggedIn}
                            element={<MyInfo />}
                        />
                    }
                />
                <Route
                    path="allApps"
                    element={
                        <AuthRedirect
                            path={window.location.pathname}
                            isLoggedIn={isLoggedIn}
                            element={<AllApps />}
                        />
                    }
                />
                <Route
                    path="lti/:session"
                    element={<CCTWrapper />}
                    errorElement={<Error isHideLayout isHideHomePage />}
                />
                <Route
                    path="login"
                    // If the user is not logged-in, redirect the user to Shibboleth page. If the user is already logged-in, the route https://.../login should not trigger another authentication. Let's just move the page to / instead.
                    element={
                        isLoggedIn ? (
                            <Navigate to="/" replace />
                        ) : (
                            <AuthRedirect />
                        )
                    }
                />
            </Route>
        )
    );
};

export default router;
