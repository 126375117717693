import React, { Component } from "react";

import { Typography } from "@mui/material";
import Widget from "./Widget";

class AppCard extends Component {
    state ={ 

    }

    render() {
        return <React.Fragment>
            <div style={{padding: 2}}>
            <Widget data={{title: this.props.widget, id: this.props.widget}} selectedItem={this.props.selectedItem} isDragging = {this.props.isDragging} widget = {this.props.widget} idx = {this.props.idx} provided={this.props.provided}>
                <Typography variant="p">This is body</Typography>
            </Widget>
            </div>
        </React.Fragment>
    }
}

export default AppCard;