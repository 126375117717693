import React, {Component} from 'react';
import Widget from '../common/Widget';
import {Link, List, ListItem, Box, Grid, Typography} from '@mui/material';
import ContactCard from '../common/ContactCard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAddressBook} from '@fortawesome/pro-light-svg-icons';
import {getData, postData, putData} from '../../DataAccessLayer';
import {myContacts} from '../../DataAccessLayer/services';
import {connect} from 'react-redux';
import {
    saveContacts,
    saveContactsResults,
    saveContactsUpdate,
    toggleContactsManager,
    toggleContactsLoading
} from './actions';
import ContactsManager from './ContactsManager';
import PortalTooltip from '../common/PortalTooltip';
import {toast} from 'react-toastify';

class MyContacts extends Component {
    state = {
        isExpand: false,
        menu: [
            {
                id: 'myContacts_editContacts',
                title: 'Edit Contacts',
                onClick: this.props.toggleContactsManager
            }
            //TODO:  enable in v1.1
            // {id: "myContacts_hide", title: "Hide"},
        ],
        isSearching: false
    };

    componentDidMount() {
        this.loadContacts();
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.updates &&
            JSON.stringify(prevProps.updates) !=
                JSON.stringify(this.props.updates)
        )
            this.onUpdate();
    }

    //Θ(1) load contacts and save
    loadContacts = () => {
        getData(myContacts)
            .then(contacts => {
                this.props.saveContacts(contacts);
            })
            .finally(_ => {
                this.props.toggleContactsLoading();
            });
    };

    //Θ(1) toggle widget expand
    toggleExpand = () => {
        this.setState({isExpand: !this.state.isExpand});
    };

    toggleIsSearching = () => {
        this.setState({
            isSearching: !this.state.isSearching
        });
    };

    //search contacts and save results
    onSearch = () => {
        this.props.toggleContactsLoading();
        this.toggleIsSearching();
        postData(myContacts, this.props.search)
            .then(results => {
                this.props.saveContactsResults(results);
            })
            .finally(() => {
                this.props.toggleContactsLoading();
                this.toggleIsSearching();
            });
    };

    //Θ(1) update contacts and reload
    onUpdate = () => {
        this.props.toggleContactsLoading();
        putData(myContacts, {contacts: this.props.updates.sort()}).then(
            data => {
                this.loadContacts();
                this.props.saveContactsUpdate();
            }
        );
    };

    render() {
        return (
            <div id={"myContacts__div_contentWrapper"}>
                <ContactsManager
                    onSearch={this.onSearch}
                    onUpdate={this.onUpdate}
                    isSearching={this.state.isSearching}
                    toggleIsSearching={this.toggleIsSearching}
                    id="myContacts__contactsManager"
                />
                <Widget
                    data={{
                        id: this.props.widget.id,
                        title: this.props.title,
                        isTitleStylized: this.props.isTitleStylized,
                        menu: this.state.menu
                    }}
                    isExpand={this.state.isExpand}
                    {...this.props}
                >
                    <Box id={"myContacts__box_universityDirectory_wrapper"}
                        sx={{
                            width: '100%',
                            py: 2
                        }}
                        className="bb-1"
                    >
                        <FontAwesomeIcon
                            id={"myContacts__icon_contactsBook"}
                            icon={faAddressBook}
                            size="lg"
                            style={{
                                marginRight: '0.51rem',
                                marginLeft: '0.5rem'
                            }}
                        />
                        <Link
                            id="myContacts__link_universityDirectory"
                            href="http://odu.edu/directory"
                            sx={{fontWeight: 600}}
                            underline={'hover'}
                            target="_blank"
                        >
                            University Directory
                        </Link>
                    </Box>
                    <Grid id={"myContacts__grid_listWrapper"}
                        container
                        alignItems={'stretch'}
                        sx={{p: 0, mt: 1}}
                        spacing={1}
                        className="myOdu__myContacts_list_wrapper"
                    >
                        {this.props.contacts.length ? (
                            this.props.contacts?.map(contact => (
                                <Grid id={"myContacts__gridItem_contactCard_wrapper_"+contact.midas}
                                    item
                                    sx={{pt: 1}}
                                    xl={12}
                                    lg={12}
                                    md={12}
                                    sm={6}
                                    xs={12}
                                    key={contact.midas}
                                >
                                    <ContactCard id={"myContacts__contactCard"} midas={contact.midas} data={contact} />
                                </Grid>
                            ))
                        ) : (
                            <Grid id={"myContacts__gridItem__addContacts"} item>
                                <Typography id={"myContacts__text_noContactsFound"}
                                    variant="smaller"
                                    xs={{display: 'inline'}}
                                >
                                    No contacts found.{' '}
                                </Typography>
                                <PortalTooltip
                                     id={"myContacts__tooltip_addContacts"}
                                     title="Open Contact Editor"
                                >
                                    <Link
                                        id="myContacts__link_addContacts"
                                        className="cursor-pointer"
                                        variant="smaller"
                                        xs={{
                                            display: 'inline',
                                            cursor: 'pointer'
                                        }}
                                        onClick={
                                            this.props.toggleContactsManager
                                        }
                                    >
                                        Add contacts.
                                    </Link>
                                </PortalTooltip>
                            </Grid>
                        )}
                    </Grid>
                </Widget>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        contacts: state.myContactsReducer.contacts,
        search: state.myContactsReducer.search,
        updates: state.myContactsReducer.updates
    };
};

const mapDispatchToProps = dispatch => ({
    saveContacts: contacts => dispatch(saveContacts(contacts)),
    saveContactsUpdate: contacts => dispatch(saveContactsUpdate(contacts)),
    saveContactsResults: results => dispatch(saveContactsResults(results)),
    toggleContactsManager: () => dispatch(toggleContactsManager()),
    toggleContactsLoading: () => dispatch(toggleContactsLoading())
});

export default connect(mapStateToProps, mapDispatchToProps)(MyContacts);
