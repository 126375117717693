import React, {useState} from 'react';
import {FormTextField} from '../common/Form/FormTextField';
import {FormTextArea} from '../common/Form/FormTextArea';
import {FormDropDown} from '../common/Form/FormDropdown';
import {FormCheckbox} from '../common/Form/FormCheckbox';
import {FormRadioButton} from '../common/Form/FormRadioButton';
import {FormControlledPhoneField} from '../common/Form/FormPhoneField';
import {SearchBar} from '../common/SearchBar';
import {Container, Grid} from '@mui/material';

import {useForm, FormProvider} from 'react-hook-form';
import {FormControlledMultiCheckbox} from '../common/Form/FormMuiltiCheckbox';

export const DemoMuiFormComponents = () => {
    const [textValue, setTextValue] = useState('');
    const [textValue1, setTextValue1] = useState('');
    const [textValue3, setTextValue3] = useState('');
    const [textValue4, setTextValue4] = useState('');
    const [checked, setChecked] = useState(false);

    const methods = useForm({
        defaultValues: {
            phone: {voiceOnly: 'false', countryCode: 1, number: ''},
            requiredPhone: {}
        },
        mode: 'onTouched'
    });

    return (
        <Container>
            <FormProvider {...methods}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SearchBar />
                    </Grid>
                    <Grid item xs={6}>
                        <FormTextField
                            label="Text field"
                            required
                            value={textValue}
                            onChange={setTextValue}
                            error
                            errorText="Sample error text"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormTextField
                            ariaLabel="Text field no label"
                            value={textValue1}
                            onChange={setTextValue1}
                            charCountEnabled
                            helperText="Sample helper text"
                            maxChars={5}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormTextArea
                            id="textArea1"
                            label="Text area"
                            required
                            value={textValue3}
                            onChange={setTextValue3}
                            charCountEnabled={false}
                            error
                            errorText="Sample error text"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormTextArea
                            id="textArea2"
                            ariaLabel="Text area no label"
                            value={textValue4}
                            onChange={setTextValue4}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormDropDown
                            label={'Dropdown'}
                            required
                            options={[
                                {label: 'option1', value: 1},
                                {label: 'option2', value: 2}
                            ]}
                            error
                            errorText="Sample error text"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormDropDown
                            options={[
                                {label: 'option1', value: 1},
                                {label: 'option2', value: 2}
                            ]}
                            helperText="Sample helper text"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormRadioButton
                            label="Radio button"
                            required
                            options={[
                                {label: 'option1', value: 1},
                                {label: 'option2', value: 2}
                            ]}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormRadioButton
                            required
                            options={[
                                {label: 'option1', value: 1},
                                {label: 'option2', value: 2}
                            ]}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlledMultiCheckbox
                            control={methods.control}
                            checkboxes={[
                                {name: 'checkbox1', label: 'checkbox1'},
                                {name: 'checkbox2', label: 'checkbox2'},
                                {name: 'checkbox3', label: 'checkbox3'}
                            ]}
                            label={'Please select at least 2 boxes'}
                            error={
                                [
                                    methods.watch('checkbox1'),
                                    methods.watch('checkbox2'),
                                    methods.watch('checkbox3')
                                ].filter(v => v).length < 2
                            }
                            errorText={'Not enough'}
                            required
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormCheckbox
                            ariaLabel="checkbox no label"
                            value={checked}
                            onChange={() => setChecked(!checked)}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControlledPhoneField
                            name={'phone'}
                            control={methods.control}
                            label="Phone"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlledPhoneField
                            name={'phone'}
                            control={methods.control}
                            label="Phone number only"
                            showPhoneCountryCodeInput={false}
                            showPhoneTypeInput={false}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormControlledPhoneField
                            name={'phone'}
                            control={methods.control}
                            label="Phone w/o type"
                            showPhoneTypeInput={false}
                            phoneCountryCodeInputGridProps={{
                                xs: 5,
                                md: 5,
                                lg: 5,
                                xl: 5
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlledPhoneField
                            name={'requiredPhone'}
                            control={methods.control}
                            label="Phone w/o country"
                            showPhoneCountryCodeInput={false}
                            phoneTypeInputGridProps={{
                                xs: 5,
                                md: 5,
                                lg: 5,
                                xl: 5
                            }}
                            rules={{
                                phoneNumberRule: {
                                    required: 'Phone number is required'
                                }
                            }}
                            required
                        />
                    </Grid>
                </Grid>
            </FormProvider>
        </Container>
    );
};
