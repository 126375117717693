import React, {Component} from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    List,
    ListItem,
    Box
} from '@mui/material';
import PlaceCard from '../common/PlaceCard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/pro-light-svg-icons';
import {connect} from 'react-redux';
import {toggleCategory} from './actions';

class PlaceCategories extends Component {
    toggleExpand = () => {
        this.props.toggleCategory({
            ...this.props.expandedCategories,
            [this.props.data.category.id]:
                !this.props.expandedCategories[this.props.data.category.id]
        });
    };

    render() {
        const {
            data: {category},
            expandedCategories
        } = this.props;

        const isExpand = !!expandedCategories[category.id];

        return (
            <Accordion
                elevation={0}
                expanded={isExpand}
                id={this.props.id + "__accordion_category_" + category.id}
                className="myOdu__placesAndHoursCategories"
                sx={{width: '100%'}}
            >
                <AccordionSummary
                    expandIcon={
                        <FontAwesomeIcon id={this.props.id + "__icon" + "_category" + category.id} icon={faChevronDown} size="lg" />
                    }
                    onClick={this.toggleExpand}
                    id={this.props.id + "__accordionSummary_category_" + category.id}
                    aria-controls={this.props.id + "__accordionDetails_category_" + category.id}
                    sx={{
                        my: 1,
                        flexDirection: 'row-reverse',
                        flexGrow: 1,
                        pl: 0
                    }}
                >
                    <Typography variant="h5" component="h3" sx={{ml: 1}} id={this.props.id + "__category_title" + category.id}>
                        {category.title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{pt: 0, pb: 0}} id={this.props.id + "__accordionDetails_category" + category.id}>
                    <Box id={this.props.id + "__box_placeCardWrapper_category_" + category.id}>
                        <List sx={{p: 0}} id={this.props.id + "_list_placeCards_category" + category.id}>
                            {category.places.map(place => (
                                <ListItem
                                    divider
                                    sx={{ml: 0, p: 0}}
                                    key={this.props.id + "_listItem_" + place.id + "_category_" + category.id}
                                    id={this.props.id + "_listItem_" + place.id + "_category_" + category.id}
                                >
                                    <Box sx={{p: 0, width: '100%'}} id={this.props.id + "_listItem_box_" + place.id + "_category_" + category.id}>
                                        <PlaceCard
                                            data={{location: place}}
                                            id={this.props.id + "_placeCard_" + place.id + "_category_" + category.id}
                                        />
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </AccordionDetails>
            </Accordion>
        );
    }
}

const mapStateToProps = state => ({
    expandedCategories: state.placesAndHoursReducer.expandedCategories
});

const mapDispatchToProps = {
    toggleCategory
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaceCategories);
