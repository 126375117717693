import React, {Component} from 'react';
import {IconButton, Typography, Tooltip, Slide, Fade, Zoom} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMessageSmile, faXmark} from '@fortawesome/pro-light-svg-icons';
import PortalModal from '../PortalModal';
import FeedbackForm from './FeedbackForm';
import {WithSmallScreen} from '../WithSmallScreen';

class FeedbackButton extends Component {
    state = {

        //boolean for tooltip on feedback button
        isTooltip: true,

        //time after page load when tooltip is activated
        timeoutIn: 2 * 1000,

        //time after tooltip activation when tooltip is deactivated
        timeoutOut: 6 * 1000
    };

    //Θ(1) toggle or override isTooltip boolean
    toggleTooltip = (isOverride) => {
        this.setState({isTooltip: isOverride !== undefined ? isOverride : !this.state.isTooltip});
    };

    componentDidMount(){
        this.setupAnimation()
    }

    //Θ(1) setup ttoltip animation to start after timeoutIn and end after timeoutOut from state
    setupAnimation = () => {
        setTimeout(() => {
            this.toggleTooltip(true)
            setTimeout(() => {
                this.toggleTooltip(false);
            }, this.state.timeoutOut);
        }, this.state.timeoutIn);
    }

    render() {
        return (
            <React.Fragment>
            <Tooltip
                    id="feedback__button_tooltip"
                    TransitionComponent={Zoom}
                    TransitionProps={{timeout: 600}}
                    open={this.state.isTooltip && !this.props.isSmall}
                    onOpen={() => this.toggleTooltip(false)}
                    onClose={() => this.toggleTooltip(false)}
                    onMouseEnter={() => this.toggleTooltip(false)}
                    placement="left"
                    title={
                        <>
                            <Typography variant="h5" component="p" id="feedback__text_thanks">
                                Thanks for checking out the new portal!
                            </Typography>
                            Let us know what you think!
                        </>
                    }
                    arrow
                    className="myOdu__feedbackButton_tooltip"
                >
                <IconButton
                    className="myOdu__feedbackButton"
                    onClick={this.props.toggleModalOpen}
                    onMouseEnter={() => this.toggleTooltip(false)}
                    onMouseLeave={() => this.toggleTooltip(false)}
                    id="feedbackButton__iconButton"
                >
                    <span className="icon" id="feedbackButton__iconWrapper">
                        <FontAwesomeIcon icon={faMessageSmile} id="feedbackButton__icon"/>
                    </span>
                    <Typography
                        component="span"
                        variant="smaller"
                        sx={{ml: 1, fontWeight: 500}}
                        id="feedbackButton__question_gotFeedback"
                    >
                        Got Feedback?
                    </Typography>
                </IconButton>
            </Tooltip>
            </React.Fragment>
        );
    }
}

export default WithSmallScreen(FeedbackButton);
