import React, {Component} from 'react';
import { Typography, Link, Stack, Box} from '@mui/material';
import PortalModal from "../common/PortalModal";
import {Col, Row, Container} from "reactstrap";
import PortalTooltip from '../common/PortalTooltip';
import {stopPreview} from '../common/utils';

class CctComingSoonModal extends Component {
  state = {};

  render() {
    return (
      <PortalModal 
        isOpen={this.props.isOpen} 
        isCloseable={true} 
        onClose={this.props.onClose} 
        noHeader
        maxWidth="md"
        className="myOdu__cctComingSoonModal"
      >
        <Container fluid>
          <Row className='justify-content-center'>
            <Col sm={12} md={6} lg={6}>
                <Typography component="h2" variant="h3">CCT Is Coming Soon!</Typography>
                <Typography component="p" variant="smaller" sx={{mt:2, mb:2}}>Hey Monarch! Thanks for stopping by. We're currently sprucing up the Class Collaboration Tool to make it even better for you. 
                  Hang tight, we'll be back soon with some exciting updates!  </Typography>
                <PortalTooltip title="Open Course Collaboration Tool">
                    <Link sx={{cursor: 'pointer'}} onClick={() => {stopPreview('https://portal.odu.edu/application/mycourses')}} variant="smaller" underline="always">Stop Sneak Peak and Go To Original CCT</Link>
                </PortalTooltip>
            </Col>
            <Col xs={10} sm={8} md={6} lg={6}>
              <img src="/img/cctComingSoon.svg" alt="illustration of people working on a website in construction gear"/>
            </Col>
          </Row>
        </Container>
      
      </PortalModal>
    );
  }
}

export default CctComingSoonModal;
