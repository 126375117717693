import React, { Component } from 'react';
import { Box, List, Link, Typography, Tooltip } from '@mui/material/';
import NewsItem from './NewsItem';
import PortalTooltip from '../common/PortalTooltip.js';
import { cleanUpID } from '../common/utils.js';

class UniversityNews extends Component {

  render() {
    return <div id={this.props.id + "__div_universityNewsWrapper"} className="myOdu__myNews_wrapper">
      <Box id={this.props.id + "__box_universityNews"} sx={{width: '100%', textAlign: 'right', p: 0}}>
        <PortalTooltip id={this.props.id + "__tooltip_universityNews"}>
          <Link id={this.props.id + "__link_universityNews"} href="http://www.odu.edu/news" target="_blank"
                underline='hover'>University News
          </Link>
        </PortalTooltip>
      </Box>


      {!this.props.news.includes("You need to enable JavaScript to run this app.") ?
          (this.props.news && this.props.news.length) ? 
            this.props.news.map((newsItem, index) =>
              <List sx={{ py: 0 }} dense={true}>
                <NewsItem data={newsItem} index={index} widgetId={this.props.widgetId} key={"universityNews_" + index} />
              </List>
            ) : <Typography component='p' variant='small'>No news found</Typography>
          :
            <Typography component='p' variant='small'>Error retrieving news.</Typography>
        }
    </div>
  }
}

export default UniversityNews;