import React, {useEffect, useState} from 'react';
import {Typography, Link, Breadcrumbs, Button, Stack} from '@mui/material';

import {BreakpointBoxes} from '../common/BreakpointBoxes';
import {LegalNameSection} from './LegalNameSection';

import {useForm, FormProvider} from 'react-hook-form';

import {PreferredFirstNameSection} from './PreferredFirstNameSection';
import {UniversityCommunicationSection} from './UniversityCommunicationsSection';
import {UniversityAlertsSection} from './UniversityAlertsSection';
import {AddressesSection} from './AddressesSection';
import {CampusServiceSection} from './CampusServiceSection';
import {MilitaryAffiliationSection} from './MilitaryAffiliationSection';
import {RelationshipsSection} from './RelationshipsSection';
import {Container} from 'reactstrap';
import {getData, postData} from '../../DataAccessLayer';
import {myInfo} from '../../DataAccessLayer/services';
import {useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import PortalToast from '../common/PortalToast';
import {ErrorMessage} from '@hookform/error-message';

export const MyInfo = () => {
    const [studentData, setStudentData] = useState({});

    const user = useSelector(state => {
        const impersonation = state.impersonationReducer.impersonation;
        if (!_.isEmpty(impersonation)) {
            return impersonation;
        }

        return state.AWSReducer.user;
    });

    const defaultValues = {
        firstName: '',
        middleName: '',
        lastName: '',
        preferredFirstName: '',
        alternateEmail: '',
        mobilePhone: {voiceOnly: 'false', countryCode: 1, number: ''},
        currentPhone: {voiceOnly: 'false', countryCode: 1, number: ''},
        permanentPhone: {voiceOnly: 'false', countryCode: 1, number: ''},
        emergencyTextAlert: true,
        emergencyAlertPhones: [],
        currentAddress: {countryCode: '157'},
        permanentAddress: {countryCode: '157'},
        accessServiceAt: '100',
        parents: [],
        militaryAffiliated: false
    };

    useEffect(() => {
        const loadStudentData = async () => {
            try {
                const data = await getData(myInfo);
                setStudentData(data);
            } catch (err) {
                console.error('Error loading student data:', err);
            }
        };

        loadStudentData();
    }, []);

    const notifySuccess = content => {
        toast.success(content);
    };

    const notifyError = content => {
        toast.error(content);
    };

    const resetForm = ({onReset}) => {
        onReset();
        notifySuccess('Form reset successfully!');
    };

    const methods = useForm({
        defaultValues,
        mode: 'onTouched', // validate on touched
        values: _.merge(defaultValues, studentData, user)
    });

    const updateStudentData = async data => {
        const {
            accessServiceAt,
            alternateEmail,
            currentAddress,
            currentPhone,
            emergencyAlertPhones,
            emergencyTextAlert,
            midasID,
            militaryAffiliated,
            mobilePhone,
            permanentPhone,
            permanentAddress,
            permanentAddressSame,
            parents,
            pidm,
            preferredFirstName
        } = data;

        // Only get attributes needed for update.
        // This constraints are from ESB endpoint
        const updateData = {
            accessServiceAt,
            agreed: true,
            alternateEmail,
            currentAddress,
            currentPhone: JSON.parse(
                JSON.stringify({
                    ...currentPhone,
                    voiceOnly: undefined
                })
            ),
            emergencyAlertPhones,
            emergencyTextAlert,
            midasID,
            militaryAffiliated,
            mobilePhone: JSON.parse(
                JSON.stringify({
                    ...mobilePhone,
                    voiceOnly: undefined
                })
            ),
            permanentPhone: JSON.parse(
                JSON.stringify({
                    ...permanentPhone,
                    voiceOnly: undefined
                })
            ),
            permanentAddress,
            permanentAddressSame,
            parents: parents.map(parent => ({
                ...parent,
                phone: JSON.parse(
                    JSON.stringify({...parent.phone, voiceOnly: undefined})
                )
            })),
            pidm,
            preferredFirstName
        };

        try {
            await postData(myInfo, updateData);

            notifySuccess(
                <Stack>
                    <Typography component="h3">
                        Submitted Successfully
                    </Typography>
                    <Typography component="p">
                        Updates may take up to 24 hours to process
                    </Typography>
                </Stack>
            );
        } catch (err) {
            notifyError(
                <Stack>
                    <Typography component="h3">Submitted Failed</Typography>
                    <Typography component="p">
                        Your Information Form is not updated. Contact WebAdmin!
                    </Typography>
                </Stack>
            );
            console.error('Error updating student data:', JSON.stringify(err));
        }
    };

    const handleUpdateStudentData = async () => {
        const result = await methods.trigger(); // This will trigger validation for all fields

        const generatePhoneFields = (field, name) => ({
            [`${field}.voiceOnly`]: `${name}/Type`,
            [`${field}.countryCode`]: `${name}/Country Code`,
            [`${field}.number`]: `${name}/Number`
        });

        const generateRelationshipFields = () => {
            const fields = {};

            for (let i = 0; i < 4; i++) {
                const baseKey = `parents.${i}`;
                const relationshipName = `Relationship ${i + 1}`;

                fields[
                    `${baseKey}.relationship`
                ] = `${relationshipName}/Relationship`;
                fields[
                    `${baseKey}.firstName`
                ] = `${relationshipName}/First Name`;
                fields[`${baseKey}.lastName`] = `${relationshipName}/Last Name`;
                Object.assign(
                    fields,
                    generatePhoneFields(
                        `${baseKey}.phone`,
                        `${relationshipName}/Phone`
                    )
                );
            }

            return fields;
        };

        const generateAlertPhoneFields = () => {
            const fields = {};

            for (let i = 0; i < 4; i++) {
                const baseKey = `emergencyAlertPhones.${i}`;
                const alertPhoneName = `Alert Phone ${i + 1}`;

                fields[`${baseKey}.voiceOnly`] = `${alertPhoneName}/Type`;
                fields[
                    `${baseKey}.countryCode`
                ] = `${alertPhoneName}/Country Code`;
                fields[`${baseKey}.number`] = `${alertPhoneName}/Number`;
            }

            return fields;
        };

        const generateAddressFields = name => {
            const title =
                name === 'currentAddress'
                    ? 'Current Address'
                    : 'Permanent Address';

            return {
                [`${name}.addressLine1`]: `${title}/Street Address 1`,
                [`${name}.city`]: `${title}/City`,
                [`${name}.state`]: `${title}/State`,
                [`${name}.zipCode`]: `${title}/Zip Code`
            };
        };

        if (!result) {
            const fieldsMap = {
                preferredFirstName: 'Preferred First Name',
                alternateEmail: 'Non-ODU Email Address',
                ...generatePhoneFields('mobilePhone', 'Mobile Phone'),
                ...generatePhoneFields('currentPhone', 'Current Phone'),
                ...generatePhoneFields('permanentPhone', 'Permanent Phone'),
                ...generateAddressFields('currentAddress'),
                ...generateAddressFields('permanentAddress'),
                ...generateAlertPhoneFields(),
                ...generateRelationshipFields()
            };

            const handleScrollToErrorField = (e, key) => {
                e.preventDefault(); // Prevent default anchor link behavior
                const targetElement = document.getElementById(key);

                if (targetElement) {
                    const headerOffset = 100;
                    const elementPosition =
                        targetElement.getBoundingClientRect().top +
                        window.scrollY;
                    const offsetPosition = elementPosition - headerOffset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }
            };

            notifyError(
                <Stack>
                    <Typography component="h3">Error Found</Typography>
                    <Typography component="p">
                        The following fields have errors which require
                        attention:
                    </Typography>

                    <ul style={{padding: 0, marginRight: 20}}>
                        {Object.entries(fieldsMap).map(([key, value]) => {
                            return (
                                <ErrorMessage
                                    errors={methods.formState.errors}
                                    name={key}
                                    render={({message}) => (
                                        <li>
                                            <Link
                                                href={`#${key}`}
                                                onClick={e =>
                                                    handleScrollToErrorField(
                                                        e,
                                                        key
                                                    )
                                                }
                                                sx={{
                                                    textDecoration: 'underline', // Default state
                                                    '&:hover': {
                                                        textDecoration: 'none' // On hover
                                                    }
                                                }}
                                            >
                                                {value}
                                            </Link>{' '}
                                            - {message}
                                        </li>
                                    )}
                                />
                            );
                        })}
                    </ul>
                </Stack>
            );
        }
    };

    return (
        <>
            <PortalToast severity="error" />
            {/* <BreakpointBoxes /> */}
            <Container className="myOdu__container_maxWidth" style={{marginTop: '2rem'}}>
                <Typography component="h2" variant="h3">
                    My Info
                </Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Dashboard
                    </Link>
                    <Typography color="text.primary">My Info</Typography>
                </Breadcrumbs>
            </Container>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(updateStudentData)}>
                    <Container className="myOdu__container_maxWidth myOdu__box pt-0">
                        <LegalNameSection data={defaultValues} />
                        <PreferredFirstNameSection control={methods.control} />
                        <UniversityCommunicationSection
                            control={methods.control}
                        />
                        <UniversityAlertsSection control={methods.control} />
                        <AddressesSection control={methods.control} />
                        <CampusServiceSection control={methods.control} />
                        <MilitaryAffiliationSection control={methods.control} />
                        <RelationshipsSection control={methods.control} />
                    </Container>

                    <Container sx={{mt: 3}}>
                        <Stack sx={{width: '100%'}} direction={{xs:'col', sm:'row'}} alignItems={'center'} justifyContent={"flex-end"}>
                            <Button
                                id="myInfo__button_update"
                                type="submit"
                                onClick={handleUpdateStudentData}
                                variant="outlined"
                                className="myOdu__button primary myInfoButton"
                            >
                                Update Personal Information
                            </Button>
                            <Button
                                onClick={() =>
                                    resetForm({
                                        onReset: methods.reset
                                    })
                                }
                                id="myInfo__button_reset"
                                variant="outlined"
                                className="myOdu__button secondary myInfoButton"
                            >
                                Cancel
                            </Button>
                        </Stack>
                    </Container>
                   

                </form>
            </FormProvider>
        </>
    );
};
