import React, {Component} from 'react';
import {
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography
} from '@mui/material';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/pro-light-svg-icons';
import PlaceSummary from './PlaceSummary';
import PlaceDetails from './PlaceDetails';
import {connect} from 'react-redux';
import {toggleCategory} from '../PlacesAndHours/actions';

class PlaceCard extends Component {
    toggleExpand = () => {
        this.props.toggleCategory({
            ...this.props.expandedCategories,
            [this.props.data.location.id]:
                !this.props.expandedCategories[this.props.data.location.id]
        });
    };

    render() {
        const {data, widgetId, expandedCategories} = this.props;
        const isExpand = !!expandedCategories[data.location.id];
        const {location} = data;

        return (
            <Accordion
                sx={{border: 0, width: '100%'}}
                expanded={isExpand}
                elevation={0}
                className="myOdu__widget placeCard"
                id={this.props.id}
            >
                <AccordionSummary
                    id={ this.props.id + "_accordionSummary" }
                    aria-controls={ this.props.id + "_accordionDetails" } 
                    sx={{
                        flexDirection: 'row-reverse',
                        flexGrow: 1,
                        pl: 1,
                        pr: 0
                    }}
					expandIcon={
                        <FontAwesomeIcon id={ this.props.id + "_expandIcon" } icon={faChevronDown} size="sm" />
                    }
                    onClick={this.toggleExpand}
                >
                    <PlaceSummary data={{location}} id={this.props.id + "__placeSummary"} />
                </AccordionSummary>
                <AccordionDetails sx={{p: 0, mb: 2}}>
                    <PlaceDetails data={{location}} id={this.props.id + "__placeDetails"} />
                </AccordionDetails>
            </Accordion>
        );
    }
}

const mapStateToProps = state => ({
    expandedCategories: state.placesAndHoursReducer.expandedCategories
});

export default connect(mapStateToProps, {toggleCategory})(PlaceCard);
