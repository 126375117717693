import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Typography, Breadcrumbs, Link} from '@mui/material';
import AlertCategory from './AlertCategory';
import HiddenAlerts from './HiddenAlerts';
import {
    hideStatusAlerts,
    statusAlerts,
    unhideStatusAlerts
} from '../../DataAccessLayer/services';
import {saveStatusAlerts, saveStatusAlertsIsError} from './actions';
import {getData, postData} from '../../DataAccessLayer';
import {
    ACTION_HIDE,
    ACTION_UNHIDE,
    ALERT_KEY_BALANCE,
    ALERT_KEY_FINANCIAL_AID,
    ALERT_KEY_HOLDS,
    ALERT_KEY_UPCOMING_HOLDS
} from '../common/constants';
import {Container} from 'reactstrap';
import Loader from '../common/Loader';
import {toast} from 'react-toastify';
import PortalAlert from '../common/PortalAlert';
import ApiErrorMessage from '../common/ApiErrorMessage';

class StatusAlerts extends Component {
    state = {
        isLoading: false,
        isShowHiddenAlerts: false,
        isHideAlertInProgress: false,
        isUnhideAlertInProgress: false
    };

    componentDidMount() {
        if (!this.props.statusAlerts && !this.props.isError) {
            this.toggleIsLoading(true);
        }
    }

    componentDidUpdate(prevProps) {
        if (
            (prevProps.statusAlerts !== this.props.statusAlerts &&
                this.props.statusAlerts) ||
            (prevProps.isError !== this.props.isError && this.props.isError)
        ) {
            this.toggleIsLoading(false);
        }
    }

    toggleIsLoading = isLoading => {
        this.setState({isLoading: isLoading});
    };

    toggleIsShowHiddenAlerts = () => {
        this.setState({
            isShowHiddenAlerts: !this.state.isShowHiddenAlerts
        });
    };

    toggleIsHideAlertInProgress = isHide => {
        this.setState({
            isHideAlertInProgress: isHide
        });
    };

    toggleIsUnhideAlertInProgress = isUnhide => {
        this.setState({
            isUnhideAlertInProgress: isUnhide
        });
    };

    toggleAlertVisibility = (action, data) => {
        switch (action) {
            case ACTION_UNHIDE:
                this.unhideAlert(data);
                break;
            case ACTION_HIDE:
                this.hideAlert(data);
                break;
        }
    };

    /**
     * Hides the alert
     * @param {Object} alert The alert to be hidden
     */
    hideAlert = async alert => {
        this.toggleIsHideAlertInProgress(true);
        postData(hideStatusAlerts, alert, true)
            .then(result => {
                this.loadStatusAlerts();
                this.toggleIsHideAlertInProgress(false);
                toast.success('Financial Aid alert hidden');
            })
            .catch(err => {
                console.log(err);
                this.toggleIsHideAlertInProgress(false);
                toast.error('Could not hide alert. Try again later.');
            });
    };

    /**
     * Unhides the alert
     * @param {Object} alert The alert to be unhidden
     */
    unhideAlert = async alert => {
        this.toggleIsUnhideAlertInProgress(true);
        postData(unhideStatusAlerts, alert, true)
            .then(result => {
                this.loadStatusAlerts();
                this.toggleIsUnhideAlertInProgress(false);
                toast.success('Financial Aid alert unhidden');
            })
            .catch(err => {
                console.log(err);
                this.toggleIsUnhideAlertInProgress(false);
                toast.error('Could not unhide alert. Try again later.');
            });
    };

    /**
     * Loads the status alerts
     */
    loadStatusAlerts = () => {
        this.toggleIsLoading(true);
        getData(statusAlerts, true)
            .then(statusAlerts => {
                this.props.saveStatusAlerts(statusAlerts); // Also marks Status Alerts isError to false in the redux
            })
            .catch(err => {
                console.log(err);
                this.props.saveStatusAlertsIsError(true);
            })
            .finally(() => this.toggleIsLoading(false));
    };

    render() {
        return (
            <div id={"statusAlerts__div_sectionWrapper"}>
                <Loader
                    id={"statusAlerts__loader"}
                    isOpen={
                        this.state.isHideAlertInProgress ||
                        this.state.isUnhideAlertInProgress
                    }
                />

                {this.state.isShowHiddenAlerts && (
                    <HiddenAlerts
                        id={"statusAlerts__hiddenAlerts"}
                        hiddenAlerts={
                            this.props.statusAlerts?.hiddenFinancialAid
                        }
                        toggleIsShowHiddenAlerts={this.toggleIsShowHiddenAlerts}
                        toggleAlertVisibility={this.toggleAlertVisibility}
                        isLoading={this.state.isLoading}
                    />
                )}
                <Container
                    id={"statusAlerts__container_pageWrapper"}
                    className="myOdu__container_maxWidth"
                    style={{marginTop: '2rem'}}
                >
                    <Typography
                        id={"statusAlerts__text_statusAlertsTitle"}
                        sx={{color: 'primary.main'}}
                        component="h2"
                        variant="h3"
                    >
                        Status Alerts
                    </Typography>
                    <Breadcrumbs id={"statusAlerts__breadCrumbs_dashboard"} aria-label="breadcrumb" sx={{mb: 2}}>
                        <Link
                            id={"statusAlerts__breadCrumbs_linkDashboard"}
                            underline="hover"
                            color="inherit"
                            href="/"
                            sx={{cursor: 'pointer'}}
                        >
                            Dashboard
                        </Link>
                        <Typography id={"statusAlerts__breadCrumbs_statusAlerts"} color="text.primary">
                            Status Alerts
                        </Typography>
                    </Breadcrumbs>

                    {!this.state.isLoading && this.props.isError && (
                        <PortalAlert id={"statusAlerts__portalAlert"} severity="error" type="statusAlert">
                            <ApiErrorMessage
                                id={"statusAlerts__apiErrorMessage"}
                                reload={this.loadStatusAlerts}
                                widgetName="Status Alerts"
                                isPage={true}
                            />
                        </PortalAlert>
                    )}

                    <AlertCategory
                        id={"statusAlerts__currentHolds"}
                        data={this.props.statusAlerts?.holds ?? []}
                        title="Current Holds"
                        alertKey={ALERT_KEY_HOLDS}
                        isLoading={this.state.isLoading}
                        isError={this.props.isError}
                    />

                    <AlertCategory
                        id={"statusAlerts__upcomingHolds"}
                        data={this.props.statusAlerts?.upcomingHolds ?? []}
                        title="Upcoming Holds"
                        alertKey={ALERT_KEY_UPCOMING_HOLDS}
                        isLoading={this.state.isLoading}
                        isError={this.props.isError}
                    />

                    <AlertCategory
                        id={"statusAlerts__accountBalance"}
                        data={this.props.statusAlerts?.balance ?? []}
                        title="Account Balance"
                        alertKey={ALERT_KEY_BALANCE}
                        isLoading={this.state.isLoading}
                        isError={this.props.isError}
                    />

                    <AlertCategory
                        id={"statusAlerts__financialAid"}
                        data={this.props.statusAlerts?.financialAid ?? []}
                        title="Financial Aid"
                        alertKey={ALERT_KEY_FINANCIAL_AID}
                        isLoading={this.state.isLoading}
                        isError={this.props.isError}
                        hiddenFinancialAidLength={
                            this.props.statusAlerts?.hiddenFinancialAid?.length
                        }
                        action={ACTION_HIDE}
                        toggleIsShowHiddenAlerts={this.toggleIsShowHiddenAlerts}
                        toggleAlertVisibility={this.toggleAlertVisibility}
                    />
                </Container>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        statusAlerts: state.myStatusAlertsReducer.statusAlerts,
        isError: state.myStatusAlertsReducer.isError
    };
};

const mapDispatchToProps = dispatch => ({
    saveStatusAlerts: statusAlerts => dispatch(saveStatusAlerts(statusAlerts)),
    saveStatusAlertsIsError: isError =>
        dispatch(saveStatusAlertsIsError(isError))
});

export default connect(mapStateToProps, mapDispatchToProps)(StatusAlerts);
