import React, {useState} from 'react';
import {ListItem, List, Stack, Box} from '@mui/material';
import {FormButton} from '../common/Form/FormButton';

export const AlphabetBar = ({onSelectLetter, availableLetters}) => {
    const [activeLetter, setActiveLetter] = useState(null);

    const letters = Array.from({length: 26}, (_, i) =>
        String.fromCharCode(65 + i)
    );

    const handleButtonClick = letter => {
        setActiveLetter(letter);
        onSelectLetter(letter);
    };

    return (
        <Box className="myOdu__alphabetList">
            <Stack component={List} direction="row">
                {letters.map((letter, index) => (
                    <ListItem
                        key={index}
                        sx={{p: 0}}
                        justifyContent="center"
                        className="text-center"
                    >
                        <FormButton
                            variant="text"
                            label={letter}
                            onClick={() => handleButtonClick(letter)}
                            sx={{
                                p: 0,
                                height: '1rem',
                                backgroundColor:
                                    activeLetter === letter
                                        ? '#003057'
                                        : 'inherit',
                                color:
                                    activeLetter === letter
                                        ? 'white'
                                        : 'inherit'
                            }}
                            disabled={!availableLetters.includes(letter)}
                        />
                    </ListItem>
                ))}

                <ListItem className="showAll">
                    <FormButton
                        sx={{
                            marginLeft: '3rem',
                            width: '6rem'
                        }}
                        key="showAll"
                        onClick={() => handleButtonClick('')}
                        label={'Show All'}
                        variant="text"
                        className="text-center"
                    />
                </ListItem>
            </Stack>
        </Box>
    );
};
