import React, {Component} from 'react';
import {connect} from 'react-redux';
import Body from './Body';
import {toggleCCT} from './actions';
import {saveUser} from '../../../AWS/actions';
import InvalidRoute from '../../Layout/Router/InvalidRoute';
import {getData, postData} from '../../../DataAccessLayer';
import {lti, ltiCourse, myCoursesTeaching } from '../../../DataAccessLayer/services';
import PortalPageLoader from '../../common/PortalPageLoader';
import axios from 'axios';
import PortalToast from '../../common/PortalToast';
import { saveMyCoursesTaking, saveMyCoursesTeaching } from '../actions';
import { saveTerms } from '../Terms/actions';

//This class is specifically for CCT on canvas
class CCTWrapper extends Component {
    state = {isLoading: true, isAuthorized: false};

    componentDidMount() {
        this.initAuth().then(() => this.loadCourse());
    }

    initAuth = async () => {
        // Get the full path from the URL
        const path = window.location.pathname;
        // Split the path into segments
        const segments = path.split('/');
        // Get the base64 string
        const parameter = segments[segments.length - 1];

        try {
            const {data} = await axios.get(
                `${process.env.REACT_APP_API_GATEWAY_BASE_URL}${lti}/validate/${parameter}`
            );

            if (data.accessToken) {
                this.props.saveUser(data);
                this.setState({isAuthorized: true});
            }
        } catch (err) {
            console.log(err);
        }
    };

    loadCourse = async () => {
        try {
            const course = await getData(ltiCourse, true);
            this.loadCourses([{code: course.SSBSECT_TERM_CODE}])
            this.props.toggleCCT(course);
        } catch (err) {
        } finally {
            this.setState({isLoading: false});
        }
    };

    //Θ(1) Loads courses from backend
    loadCourses = terms => {
        return new Promise((resolve, reject) => {
            postData(
                myCoursesTeaching,
                //We will always a single termcode over here. Adding map for future proofing
                terms?.map(term => term.code)
            )
            .then(courses => {
                this.props.saveMyCoursesTaking(courses.taking);
                this.props.saveMyCoursesTeaching(courses.teaching);
                resolve();
            })
            .catch(err => {
                reject(err);
            });
        });
    };

    render() {
        const {isLoading, isAuthorized} = this.state;

        return isLoading ? (
            <PortalPageLoader />
        ) : isAuthorized ? (
            <div>
                <PortalToast />
                <Body />
            </div>
        ) : (
            <InvalidRoute />
        );
    }
}

const mapStateToProps = state => {
    return {
        course: state.CCTReducer.course,
        user: state.AWSReducer.user
    };
};

const mapDispatchToProps = dispatch => ({
    toggleCCT: course => dispatch(toggleCCT(course)),
    saveUser: user => dispatch(saveUser(user)),
    saveMyCoursesTaking: coursesTaking =>
        dispatch(saveMyCoursesTaking(coursesTaking)),
    saveMyCoursesTeaching: coursesTeaching =>
        dispatch(saveMyCoursesTeaching(coursesTeaching)),
    saveTerms: terms => dispatch(saveTerms(terms))
});

export default connect(mapStateToProps, mapDispatchToProps)(CCTWrapper);
