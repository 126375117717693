import {FormControlledCheckbox} from './FormCheckbox';

import {Typography, Grid} from '@mui/material';
import {FieldRequiredMark} from './FieldRequiredMark';

export const FormControlledMultiCheckbox = ({
  label,
  control,
  checkboxes = [],
  error,
  errorText,
  labelGridProps = {
    xs: 0,
    md: 3,
    lg: 4,
    xl: 3
  },
  inputGridProps = {xs: 12, md: 9, lg: 8, xl: 9},
  required
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item {...labelGridProps}></Grid>
      <Grid item {...inputGridProps}>
        <Typography className="myOdu__label">
          {label} {required && <FieldRequiredMark label={label} />}
        </Typography>

        {checkboxes.map(checkbox => {
          return (
            <FormControlledCheckbox
              name={checkbox.name}
              control={control}
              label={checkbox.label}
              rules={checkbox.rules}
              labelGridProps={{
                xs: 0,
                md: 0,
                lg: 0,
                xl: 0
              }}
              inputGridProps={{xs: 12, md: 0, lg: 0, xl: 0}}
              {...checkbox}
            />
          );
        })}

        {error && (
          <Typography
            display="flex"
            justifyContent="flex-start"
            variant="caption"
            className="myOdu__error"
          >
            {errorText}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};
