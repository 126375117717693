import React, {useEffect} from 'react';
import {countryCodesAlpha, stateCodes} from '../common/Form/FormOptions';
import {Typography, Box, Container, Stack} from '@mui/material';
import {FormControlledDropdown} from '../common/Form/FormDropdown';
import {FormControlledTextField} from '../common/Form/FormTextField';
import {useWatch, useFormContext} from 'react-hook-form';
import {FormControlledCheckbox} from '../common/Form/FormCheckbox';

const AddressForm = ({control, name, isSameAddress}) => {
  const {watch, clearErrors} = useFormContext();

  const country = watch(`${name}.countryCode`);

  return (
    <Box sx={{flexGrow: 1, mt: 2, mb: 2}}>
      <Stack direction="column" alignContent="center" gap={1}>
        <FormControlledDropdown
          name={`${name}.countryCode`}
          control={control}
          options={countryCodesAlpha}
          label="Country"
          rules={{required: isSameAddress ? false : 'Country is required'}}
          disabled={isSameAddress}
          required
        />
        <FormControlledTextField
          name={`${name}.addressLine1`}
          control={control}
          label="Street Address 1"
          rules={{
            required: isSameAddress ? false : 'Street Address 1 is required'
          }}
          disabled={isSameAddress}
          required
        />
        <FormControlledTextField
          name={`${name}.addressLine2`}
          control={control}
          label="Street Address 2"
          disabled={isSameAddress}
        />
        <FormControlledTextField
          name={`${name}.addressLine3`}
          control={control}
          label="Street Address 3"
          disabled={isSameAddress}
        />

        <FormControlledTextField
          name={`${name}.city`}
          control={control}
          label="City"
          rules={{required: isSameAddress ? false : 'City is required'}}
          disabled={isSameAddress}
          required
        />

        {/** Render state dropdown for U.S country only */}
        {country === '157' ? (
          <FormControlledDropdown
            name={`${name}.state`}
            control={control}
            options={stateCodes}
            label="State"
            rules={{required: isSameAddress ? false : 'State is required'}}
            disabled={isSameAddress}
            required
          />
        ) : (
          <FormControlledTextField
            name={`${name}.state`}
            control={control}
            label="State/Province"
            rules={{
              required: isSameAddress ? false : 'State/Province is required'
            }}
            disabled={isSameAddress}
            required
          />
        )}

        <FormControlledTextField
          name={`${name}.zipCode`}
          control={control}
          label="ZIP/Postal Code"
          rules={{
            required: isSameAddress ? false : 'ZIP/Postal Code is required',
            pattern: {
              value: /^\d{5}(?:-\d{4})?$/,
              message: 'Invalid ZIP Code'
            }
          }}
          disabled={isSameAddress}
          required
        />
      </Stack>
    </Box>
  );
};

export const AddressesSection = ({control}) => {
  const {setValue, getValues, watch, clearErrors} = useFormContext();

  const isSameAddress = watch('permanentAddressSame');

  const currentAddress = watch('currentAddress');
  const permanentAddress = watch('permanentAddress');

  if (!_.isEqual(currentAddress, permanentAddress) && isSameAddress) {
    setValue(
      'permanentAddress',
      {...currentAddress},
      {
        shouldValidate: false,
        shouldTouch: false
      }
    );
  }

  // Set initial value of permanentAddressSame
  useEffect(() => {
    setValue(
      'permanentAddressSame',
      _.isEqual(currentAddress, permanentAddress)
    );
  }, [currentAddress, permanentAddress, setValue]);

  // Update permanentAddress based on isSameAddress changes
  useEffect(() => {
    if (isSameAddress) {
      setValue(
        'permanentAddress',
        {...currentAddress},
        {
          shouldValidate: false,
          shouldTouch: false
        }
      );

      clearErrors('permanentAddress');
    }
  }, [isSameAddress, currentAddress, setValue]);

  return (
    <Container sx={{mt: 4}}>
      <Typography component="h3" variant="h4">
        Addresses
      </Typography>
      <Typography variant="body1">
        Current address may be a temporary school address. Official
        correspondence, grade reports, billing statements, and refunds are
        issued to the permanent address.
      </Typography>

      <Box sx={{backgroundColor: '#F5F5F5', padding: 1, mt: 2}}>
        <Typography variant="h5">Current Address</Typography>
      </Box>

      <AddressForm control={control} name="currentAddress" />

      <Box sx={{backgroundColor: '#F5F5F5', padding: 1, mt: 2}}>
        <Typography variant="h5">Permanent Address</Typography>
      </Box>

      <Box sx={{mt: 1}}>
        <FormControlledCheckbox
          name={'permanentAddressSame'}
          control={control}
          label={'Same as Current Address'}
        />
      </Box>
      <AddressForm
        control={control}
        name="permanentAddress"
        isSameAddress={isSameAddress}
      />
    </Container>
  );
};
