import { Box, Button, Typography, Snackbar } from "@mui/material";
import React, {Component} from "react";
import Section from "./Section";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { savePreferences } from "../../SetUp/actions";
import { WidgetConfigs, DefaultWidgetOrder } from "../../SetUp/config";
import { putData } from "../../../DataAccessLayer";
import { myPreferences } from "../../../DataAccessLayer/services";
import { SectionParams } from "../../Dashboard/config";
import { MOVE_WIDGET_DOWN, MOVE_WIDGET_UP } from "../../common/constants";
import { toast } from 'react-toastify';

class Widgets extends Component {

    state = {
        selectedSection: 0,
        preferences: this.props.preferences
    }

    moveWidget = (widgetId, sectionId, direction) => {

        let widgets = this.state.preferences?.Widgets ? {...this.state.preferences?.Widgets} : {...WidgetConfigs, WidgetsOrder: DefaultWidgetOrder}
        
        let index = widgets.WidgetsOrder[sectionId-1].indexOf(widgetId);
        if(direction === MOVE_WIDGET_UP && index > 0) {
            let temp = widgets.WidgetsOrder[sectionId-1][index-1]
            widgets.WidgetsOrder[sectionId-1][index-1] = widgets.WidgetsOrder[sectionId-1][index]
            widgets.WidgetsOrder[sectionId-1][index] = temp;
        } else if(direction === MOVE_WIDGET_DOWN && index < widgets.WidgetsOrder[sectionId-1].length -1) {
            let temp = widgets.WidgetsOrder[sectionId-1][index+1]
            widgets.WidgetsOrder[sectionId-1][index+1] = widgets.WidgetsOrder[sectionId-1][index]
            widgets.WidgetsOrder[sectionId-1][index] = temp;
        }
        this.setState({
            preferences: {...this.state.preferences, Widgets: widgets}
        })
    }

    dragStart = (result) => {
        this.setState({
            selectedSection: result.source.droppableId
        })
    }

    dragEnd = (result) => {
        let widgets = this.state.preferences?.Widgets ? {...this.state.preferences?.Widgets} : {...WidgetConfigs, WidgetsOrder: DefaultWidgetOrder}
        this.setState({
            selectedSection: 0
        })
        if(result.destination) {
            let sectionId = result.destination.droppableId;

            const [removedWidgetId] = widgets.WidgetsOrder[sectionId-1].splice(result.source.index, 1)
            widgets.WidgetsOrder[sectionId - 1].splice(result.destination.index, 0, removedWidgetId)
            
            this.setState({
                preferences: {...this.state.preferences, Widgets: widgets}
            })
        }
    }

    onSaveWidgets = () => {
        let preferences = {...this.state.preferences}

        putData(myPreferences, {preferences, midas: this.props.isImpersonating ? this.props.impersonation.midas : this.props.user.midas})
        .then(result => {
            this.props.savePreferences(preferences);
            if(result)
                toast.success('Save was successful')
            else
                toast.error('Could not save at this time.')
        })
        .catch(err => {
            console.log(err)
            toast.error('Could not save at this time.')
        })
    }

    onCancel = () => {
        this.setState({
            preferences: this.props.preferences
        })
    }

    render() {
        const widgetOrder = this.state?.preferences?.Widgets?.WidgetsOrder || DefaultWidgetOrder;
        return <React.Fragment>
            <Typography variant="h4" component='h3'>Widgets - Set Display Order</Typography>
            <Snackbar 
                open = {this.state.selectedSection > 0}
                message = {'Widgets may be dragged and dropped within their section'}
            />
            <Row className="pt-3">
                {
                    Object.values(SectionParams).map((params, idx) => {
                        return <Col {...params}>
                            <Section 
                                sectionNumber = {idx+1}
                                selectedSection = {this.state.selectedSection}
                                isDroppable = {Number(this.state.selectedSection) > 0 && Number(this.state.selectedSection) !== (idx+1)}
                                widgets = {WidgetConfigs.Widgets}
                                widgetsOrder = {widgetOrder[idx]}
                                moveWidget = {this.moveWidget}
                                dragEnd = {this.dragEnd}
                                dragStart = {this.dragStart}
                            />
                        </Col>
                    })
                }
            </Row>
            <Row style={{float: 'right', marginTop: 8}}>
                <Box>                   
                    <Button style={{margin: 2}} variant='outlined' className="myOdu__button primary" onClick={this.onSaveWidgets}>Save Display Order</Button>
                    <Button style={{margin: 2}} variant='outlined' className="myOdu__button secondary" onClick={this.onCancel}>Cancel</Button>
                </Box>
            </Row>
        </React.Fragment>
    }
}

const mapStateToProps = (state) => {
    return {
        preferences: state.preferencesReducer.preferences,
        user: state.AWSReducer.user,
        isImpersonating: state.impersonationReducer.impersonation?.isImpersonating ?? false,
        impersonation: state.impersonationReducer.impersonation
    }
  }
  
const mapDispatchToProps = (dispatch) => ({
    savePreferences: (preferences) => dispatch(savePreferences(preferences))
});

export default connect(mapStateToProps, mapDispatchToProps)(Widgets)